import { FormlyFieldConfig } from '@ngx-formly/core';

export const getRootField = (field: FormlyFieldConfig): FormlyFieldConfig | null => {
  let parentField: FormlyFieldConfig | undefined = field;
  let maxNodes = 10;

  while (parentField?.parent !== undefined) {
    maxNodes--;
    if (maxNodes < 0) {
      break;
    }
    parentField = parentField.parent;
  }

  return parentField || null;
};

export const getFieldByKey = (fieldKey: string, fields: FormlyFieldConfig[], maxLevel = 5): FormlyFieldConfig | null => {
  const findFieldByKeyInFields = (field: FormlyFieldConfig, key: string, level: number): FormlyFieldConfig | null => {
    if (!field || level === 0) {
      return null;
    }

    if (field?.key === key) {
      return field;
    }

    if (field?.fieldGroup && Array.isArray(field.fieldGroup)) {
      for (const nestedField of field.fieldGroup) {
        const foundField = findFieldByKeyInFields(nestedField, key, level - 1);
        if (foundField) {
          return foundField;
        }
      }
    }

    return null;
  };

  if (!fieldKey || !fields || fields.length === 0) return null;

  for (const field of fields) {
    const foundField = findFieldByKeyInFields(field, fieldKey, maxLevel);
    if (foundField) {
      return foundField;
    }
  }

  return null;
};

export const getFields = (field: FormlyFieldConfig, maxLevel = 5): FormlyFieldConfig[] => {
  if (!field) return [];

  const rootField: FormlyFieldConfig | null = getRootField(field);

  if (!rootField?.fieldGroup || rootField?.fieldGroup.length === 0) return [];

  return getFieldGroupFields(rootField?.fieldGroup as FormlyFieldConfig[], maxLevel);
};

const getFieldGroupFields = (fieldGroup: FormlyFieldConfig[], maxLevel: number): FormlyFieldConfig[] => {
  let result: FormlyFieldConfig[] = [];
  if (!fieldGroup || fieldGroup.length === 0 || maxLevel <= 0) return result;

  for (const f of fieldGroup) {
    if (!!f.key && f?.props && f?.type) {
      result.push(f);
    }

    if (f.fieldGroup && Array.isArray(f.fieldGroup) && f.fieldGroup.length > 0) {
      const fieldGroupResult = getFieldGroupFields(f.fieldGroup, maxLevel - 1);
      result = [...result, ...fieldGroupResult];
    }
  }
  return result;
};

export const findFieldByKey = (currentField: FormlyFieldConfig, key: string, maxLevel = 5): FormlyFieldConfig | null => {
  if (!currentField?.key) return null;

  if (currentField.key === key) {
    return currentField;
  }

  const rootField = getRootField(currentField);
  if (!rootField?.fieldGroup || rootField.fieldGroup.length === 0) return null;

  const findField = (field: FormlyFieldConfig, key: string, level: number): FormlyFieldConfig | null => {
    if (!field || level === 0) return null;

    if (field.key === key) {
      return field;
    }

    if (field.fieldGroup && Array.isArray(field.fieldGroup)) {
      for (const nestedField of field.fieldGroup) {
        const foundField = findField(nestedField, key, level - 1);
        if (foundField) {
          return foundField;
        }
      }
    }
    return null;
  };

  return findField(rootField, key, maxLevel);
};
