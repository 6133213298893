import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RcgFieldType, SelectOption } from '@rcg/core';
import { Subscription } from 'rxjs';
import { RcgLocation } from './models/location.model';

@Component({
  selector: 'rcg-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationComponent extends RcgFieldType implements OnInit, OnDestroy {
  private dependentFieldSubscription?: Subscription;

  constructor() {
    super();
  }

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  ngOnInit() {
    this.dependentFieldSubscription?.unsubscribe();

    const listenFieldKey: string | null =
      this.to['settings'] && this.to['settings']['listenFieldKey'] && typeof this.to['settings']['listenFieldKey'] === 'string'
        ? this.to['settings']['listenFieldKey'].trim()
        : null;

    this.value = this.getInitalValue(listenFieldKey);

    // subscribe to dependent field changes
    if (listenFieldKey) {
      const depFieldControl = this.form.get(listenFieldKey);
      if (!depFieldControl) {
        return;
      }

      this.dependentFieldSubscription = depFieldControl!.valueChanges.subscribe((data) => {
        this.value = this.getLocationValue(data);
      });
    }
  }

  override ngOnDestroy() {
    this.dependentFieldSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  override get shouldLabelFloat() {
    return true;
  }

  onFocusIn() {
    if (!this.focused) {
      this.field.focus = true;
      this.stateChanges.next();
    }
  }

  onFocusOut() {
    this.field.focus = false;
    this.stateChanges.next();
  }

  goToLocation() {
    const location = this.value;
    const url = `https://www.google.com/maps/search/${location}`;
    window.open(url, '_blank');
  }

  private getInitalValue(listenFieldKey: string | null) {
    if (listenFieldKey) {
      const data = this.model[listenFieldKey];
      return this.getLocationValue(data);
    }
    return (this.value = this.value ?? null);
  }

  private getLocationValue(data: unknown): string | null {
    if (!data || typeof data !== 'object' || !(data as SelectOption)?.data) return null;

    const location = (data as SelectOption)!.data as RcgLocation;

    return `${location.street ?? ''} ${(location.house_number ?? '').replace(/^0+/, '')} ${location.postal_number ?? ''} ${
      location.postal_name ?? ''
    } ${location.place ?? ''}`.trim();
  }
}
