import { CdkDragDrop, CdkDropListGroup, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { gql } from '@apollo/client/core';
import * as Get from '@npm-libs/ng-getx';
import { RcgFieldType, RcgFormlyFieldProps } from '@rcg/core';
import { GraphqlClientService } from '@rcg/graphql';
import { IntlModule } from '@rcg/intl';
import { getContrastYIQ, normalizeColorToHex } from '@rcg/standalone';
import 'moment/locale/sl';
import { combineLatestWith, firstValueFrom, map, pipe } from 'rxjs';

export interface GroupEditorFieldSettings {
  groupsQuery?: string;
}

interface GroupItemTag {
  text: string;
  color?: string;
  /** Calculated contrast by YIQ if unset */
  textColor?: string;
}

interface GroupItem {
  id: number;
  name: string;
  tags?: GroupItemTag[];
  disabled?: unknown;
}

type EditorKeyOfType<T> = keyof Omit<
  {
    [P in keyof GroupEditorFieldComponent as GroupEditorFieldComponent[P] extends T ? P : never]: unknown;
  },
  'controlType' | 'ngControl' | 'model' | 'formState' | 'ngOnInit' | 'ngOnDestroy'
>;

interface ListDefinition {
  title: string;
  listRKey: EditorKeyOfType<Get.Rx<GroupItem[], GroupItem[]>>;
  searchRKey: EditorKeyOfType<Get.Rx<string, string>>;
  filteredRKey: EditorKeyOfType<Get.Rx<GroupItem[], GroupItem[]>>;
  actions?: {
    icon: string;
    tooltip: string;
    exec: EditorKeyOfType<() => void>;
  }[];
}

function searchWith(searchR: Get.Rx<string, string>) {
  return pipe(
    combineLatestWith<GroupItem[], [string]>(searchR.value$.pipe(map((v) => v.toLowerCase()))),
    map(([e, search]) =>
      e.filter((m) => {
        if (m.name.toLowerCase().includes(search)) return true;

        if (m.tags) {
          for (const tag of m.tags) {
            if (tag.text?.toLowerCase().includes(search)) return true;
          }
        }

        return false;
      }),
    ),
  );
}

@Get.NgAutoDispose
@Component({
  selector: 'rcg-group-editor',
  templateUrl: './group-editor.component.html',
  styleUrls: ['./group-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    MatListModule,
    MatIconModule,
    MatChipsModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    IntlModule,
  ],
  hostDirectives: [CdkDropListGroup],
})
export class GroupEditorFieldComponent
  extends RcgFieldType<unknown[] | undefined, RcgFormlyFieldProps<Record<string, unknown>, GroupEditorFieldSettings>>
  implements OnInit
{
  private readonly gqlClient = inject(GraphqlClientService);
  public readonly changeRef = inject(ChangeDetectorRef);

  public readonly getContrastColor = (color: string) => getContrastYIQ(normalizeColorToHex(color));

  readonly notMemberOfR = new Get.Rx<GroupItem[]>([]);
  readonly memberOfR = new Get.Rx<GroupItem[]>([]);

  readonly searchNotMemberOfR = ''.obs();
  readonly searchMemberOfR = ''.obs();

  readonly filteredNotMemberOfR = this.notMemberOfR.pipe(searchWith(this.searchNotMemberOfR));
  readonly filteredMemberOfR = this.memberOfR.pipe(searchWith(this.searchMemberOfR));

  readonly lists: ListDefinition[] = [
    {
      title: 'not_a_member',
      listRKey: 'notMemberOfR',
      searchRKey: 'searchNotMemberOfR',
      filteredRKey: 'filteredNotMemberOfR',
    },
    {
      title: 'member',
      listRKey: 'memberOfR',
      searchRKey: 'searchMemberOfR',
      filteredRKey: 'filteredMemberOfR',
      actions: [
        {
          icon: 'playlist_remove',
          tooltip: 'remove_all',
          exec: 'removeAll',
        },
      ],
    },
  ];

  error?: unknown;

  async ngOnInit() {
    try {
      if (typeof this.value === 'string') {
        this.value = JSON.parse(this.value);
      }

      if (!Array.isArray(this.value)) {
        console.warn('[Group editor]', 'Invalid initial field value', this.value);
        this.value = [];
      }

      const response = await firstValueFrom(
        this.gqlClient.query<{ data?: GroupItem[] }>({
          query: gql(this.props.settings?.groupsQuery ?? ''),
        }),
      );

      if (!Array.isArray(response.data) || !response.data.length) {
        throw new Error('Na voljo ni nobenih skupin');
      }

      const memberOfIds = (this.value as { id?: unknown }[]).map((i) => i?.id);

      this.notMemberOfR.data = response.data.filter((d) => !memberOfIds.includes(d.id));
      this.memberOfR.data = response.data.filter((d) => memberOfIds.includes(d.id));

      this.sort();
    } catch (error) {
      console.error('[Group editor]', 'Init error', error);
      this.error = error;
    }

    this.changeRef.markForCheck();
  }

  private updateValue() {
    this.value = this.memberOfR.value;
    this.formControl.markAsDirty();
  }

  drop(event: CdkDragDrop<Get.Rx<GroupItem[], GroupItem[]>>) {
    if (event.previousContainer === event.container) {
      const arr = event.container.data.value;
      moveItemInArray(arr, event.previousIndex, event.currentIndex);
      event.container.data.data = arr;
    } else {
      const prevArr = event.previousContainer.data.value;
      const arr = event.container.data.value;
      transferArrayItem(prevArr, arr, event.previousIndex, event.currentIndex);
      event.previousContainer.data.data = prevArr;
      event.container.data.data = arr;
    }

    this.sort();
    this.updateValue();
  }

  private sort() {
    this.notMemberOfR.data = this.notMemberOfR.value.slice().sort((a, b) => a.name.localeCompare(b.name));
    this.memberOfR.data = this.memberOfR.value.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  removeAll() {
    this.notMemberOfR.data = [...this.notMemberOfR.value, ...this.memberOfR.value];
    this.memberOfR.data = [];

    this.sort();
    this.updateValue();
  }
}
