import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { concat, distinctUntilChanged, interval, map, of } from 'rxjs';

export interface HeadingFieldProps extends FormlyFieldProps {
  headingType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'pre' | 'p';
}

export type HeadingFieldConfig = FieldTypeConfig<HeadingFieldProps>;

@Component({
  selector: 'rcg-heading-field',
  templateUrl: './heading-field.component.html',
  styleUrls: ['./heading-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingFieldComponent extends FieldType<HeadingFieldConfig> {
  public readonly val = toSignal(
    concat(of(null), interval(500)).pipe(
      map(() => this.value),
      distinctUntilChanged(),
    ),
  );
}
