import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RcgFieldType, RcgFormlyFieldProps } from '@rcg/core';
import moment, { Moment } from 'moment-timezone';
import 'moment/locale/sl';

interface TimeModel {
  value: string;
  label: string;
}

interface DateTimeInputDoubleSettings {
  defaultDateTime?: () => Date;
  interval: number;
  customTimes: number[];
  fromTimeKey: string;
  toTimeKey: string;
  defaultTimeDifferenceMinutes?: number;
  displaySeconds?: boolean;
  displayWorkingHours?: boolean;
  dateWidth?: string;
  timeWidth?: string;
  labels?: {
    fromLabel?: string;
    toLabel?: string;
    workingHoursLabel?: string;
  };
}

export class DateTimeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return !!(control && control.invalid);
  }
}

@Component({
  selector: 'rcg-datetime-input-double',
  templateUrl: './datetime-input-double.component.html',
  styleUrls: ['./datetime-input-double.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'sl-SL' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DateTimeInputDoubleComponent
  extends RcgFieldType<unknown, RcgFormlyFieldProps<Record<string, unknown>, DateTimeInputDoubleSettings>>
  implements OnInit, AfterViewInit
{
  @ViewChild('container') container!: ElementRef<HTMLElement>;
  @ViewChild('fromInput') fromInput!: ElementRef<HTMLInputElement>;
  @ViewChild('toInput') toInput!: ElementRef<HTMLInputElement>;
  @ViewChild('fromDateInput') fromDateInput!: ElementRef<HTMLInputElement>;
  @ViewChild('toDateInput') toDateInput!: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;
  @ViewChild(MatAutocomplete) autocomplete!: MatAutocomplete;

  defaultWidthDate!: string;
  defaultWidthTime!: string;

  autoOptions!: DateTimeInputDoubleSettings;

  fromFormControl = new UntypedFormControl();
  toFormControl = new UntypedFormControl();
  fromDateFC = new UntypedFormControl();
  toDateFC = new UntypedFormControl();
  workingHoursFC = new UntypedFormControl();

  fromTimeErrMatcher = new DateTimeErrorStateMatcher();
  toTimeErrMatcher = new DateTimeErrorStateMatcher();
  fromDateErrMatcher = new DateTimeErrorStateMatcher();
  toDateErrMatcher = new DateTimeErrorStateMatcher();

  timeDiffSec = 0;
  datesDiff = 0; // in days

  defaultMaxWidth = 500;

  times?: TimeModel[] = [];
  fromOptions?: TimeModel[] = [];
  toOptions?: TimeModel[] = [];

  get formCtrl() {
    return this.formControl;
  }

  get useDefaultValue() {
    const defaultValueSet = !!this.autoOptions?.defaultDateTime && typeof this.autoOptions?.defaultDateTime === 'function';
    const insertMode = !!this.formState.query_variable && !this.model?.[this.formState.query_variable];
    const timeValuesEmpty = !this.model[this.autoOptions.fromTimeKey] && !this.model[this.autoOptions.toTimeKey];
    return defaultValueSet && insertMode && timeValuesEmpty;
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        return control.value.isValid ? null : { invalidDate: control.value };
      } else {
        return null;
      }
    };
  }

  timeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.testTimeString(control.value) ? null : { invalidTime: control.value };
    };
  }

  ngOnInit(): void {
    if (this.props.disabled) {
      this.fromDateFC.disable();
      this.fromFormControl.disable();
      this.toDateFC.disable();
      this.toFormControl.disable();
      this.workingHoursFC.disable();
    }

    this.fromFormControl.addValidators([this.timeValidator()]);
    this.toFormControl.addValidators([this.timeValidator()]);
    this.fromDateFC.addValidators([this.dateValidator()]);
    this.toDateFC.addValidators([this.dateValidator()]);

    this.autoOptions = this.props.settings ?? ({} as DateTimeInputDoubleSettings);

    this.defaultWidthDate = '148px';
    this.defaultWidthTime = this.autoOptions.displaySeconds ? '90px' : '67px';

    if (this.autoOptions.timeWidth) {
      this.defaultWidthTime = this.autoOptions.timeWidth;
    }
    if (this.autoOptions.dateWidth) {
      this.defaultWidthDate = this.autoOptions.dateWidth;
    }

    this.defaultMaxWidth =
      3 * parseInt(this.autoOptions.timeWidth ?? this.defaultWidthTime) + 2 * parseInt(this.autoOptions.dateWidth ?? this.defaultWidthDate);

    this.value = [this.model[this.autoOptions.fromTimeKey], this.model[this.autoOptions.toTimeKey]];

    let fromTimestamp = '';
    let toTimestamp = '';

    let fromDate: Date;
    let toDate: Date;

    if (this.useDefaultValue) {
      fromDate = this.autoOptions.defaultDateTime!();
      toDate = this.autoOptions.defaultDateTime!();

      if (this.autoOptions.defaultTimeDifferenceMinutes) {
        toDate = new Date(toDate.getTime() + this.autoOptions.defaultTimeDifferenceMinutes * 60000);
      }
      fromTimestamp = fromDate.toString();
      toTimestamp = toDate.toString();
    } else {
      if (!this.autoOptions.fromTimeKey || !this.autoOptions.toTimeKey) {
        const timeArray = this.model[this.key as string];
        fromTimestamp = timeArray[0];
        toTimestamp = timeArray[1];
      } else {
        fromTimestamp = this.model[this.autoOptions.fromTimeKey];
        toTimestamp = this.model[this.autoOptions.toTimeKey];
      }

      if (fromTimestamp && toTimestamp) {
        fromDate = new Date(this.model[this.autoOptions.fromTimeKey]);
        toDate = new Date(this.model[this.autoOptions.toTimeKey]);
      } else {
        fromDate = new Date(Date.now());
        toDate = new Date(Date.now());

        if (this.autoOptions.defaultTimeDifferenceMinutes) {
          toDate = new Date(toDate.getTime() + this.autoOptions.defaultTimeDifferenceMinutes * 60000);
        }
      }
    }

    this.fromDateFC.setValue(moment(fromTimestamp));
    this.toDateFC.setValue(moment(toTimestamp));

    const startTime = this.formatTime(fromDate);
    let endTime = this.formatTime(toDate);

    this.datesDiff = this.calculateDayDifference(fromDate, toDate);
    this.timeDiffSec = this.calculateTimeDifference(startTime, endTime);
    if (this.timeDiffSec < 0 && this.datesDiff == 0) {
      this.timeDiffSec = 0;
      endTime = startTime;
    }

    this.fromFormControl.setValue(startTime);
    this.toFormControl.setValue(endTime);

    if (!this.autoOptions.interval) {
      this.autoOptions.interval = 60;
    }

    let t = this.autoOptions.displaySeconds! ? '00:00:00' : '00:00';
    for (let i = 0; i < 1440 / this.autoOptions.interval; i++) {
      this.fromOptions!.push({ value: t, label: t });
      t = this.addToTime(t, this.autoOptions.interval * 60);
    }

    if (this.datesDiff == 0) {
      this.updateToTimes(startTime);
    } else {
      this.toOptions = [...this.fromOptions!];
    }

    this.updateHostFieldValue(false); // dont mark form as dirty on init
  }

  ngAfterViewInit() {
    this.onResizeHandler();

    const observer = new ResizeObserver(() => {
      setTimeout(() => {
        this.onResizeHandler();
      });
    });
    observer.observe(this.container.nativeElement);

    this.autocomplete.opened.subscribe(() => {
      this.scrollToNearestTime();
    });
  }

  onResizeHandler() {
    if (this.container.nativeElement.offsetWidth < this.defaultMaxWidth) {
      if (this.container.nativeElement.classList.contains('flex-row')) {
        this.container.nativeElement.classList.remove('flex-row');
        this.container.nativeElement.classList.add('flex-column');
      }
    } else if (this.container.nativeElement.offsetWidth >= 500) {
      if (this.container.nativeElement.classList.contains('flex-column')) {
        this.container.nativeElement.classList.remove('flex-column');
        this.container.nativeElement.classList.add('flex-row');
      }
    }
  }

  formatTwoDigits(n: number) {
    return n < 10 ? '0' + n : n;
  }

  formatTime(d: Date) {
    return this.autoOptions.displaySeconds
      ? `${this.formatTwoDigits(d.getHours())}:${this.formatTwoDigits(d.getMinutes())}:${this.formatTwoDigits(d.getSeconds())}`
      : `${this.formatTwoDigits(d.getHours())}:${this.formatTwoDigits(d.getMinutes())}`;
  }

  getWorkingHours() {
    const hh = this.datesDiff * 24 + Math.floor(this.timeDiffSec / 3600);
    const mm = Math.floor(this.timeDiffSec / 60) % 60;
    const ss = this.timeDiffSec % 60;
    return this.autoOptions.displaySeconds
      ? `${this.formatTwoDigits(hh)}:${this.formatTwoDigits(mm)}:${this.formatTwoDigits(ss)}`
      : `${this.formatTwoDigits(hh)}:${this.formatTwoDigits(mm)}`;
  }

  updateHostFieldValue(markAsDirty = true) {
    const fromDate = moment(this.fromDateFC.value);
    const fromTime = this.fromFormControl.value.split(':');
    fromDate.hours(fromTime[0]);
    fromDate.minutes(fromTime[1]);
    if (this.autoOptions.displaySeconds) {
      fromDate.seconds(fromTime[2]);
    }

    const toDate = moment(this.toDateFC.value);
    const toTime = this.toFormControl.value.split(':');
    toDate.hours(toTime[0]);
    toDate.minutes(toTime[1]);
    if (this.autoOptions.displaySeconds) {
      toDate.seconds(toTime[2]);
    }

    this.value = [fromDate.format(), toDate.format()];
    this.model['working_hours'] = (toDate.toDate().getTime() - fromDate.toDate().getTime()) / 1000;
    this.form.get('working_hours')?.setValue((toDate.toDate().getTime() - fromDate.toDate().getTime()) / 1000);

    if (this.autoOptions.fromTimeKey && this.autoOptions.toTimeKey) {
      this.form.get(this.autoOptions.fromTimeKey)?.setValue(fromDate.format());
      this.form.get(this.autoOptions.toTimeKey)?.setValue(toDate.format());
    }

    if (markAsDirty && !(this.props.disabled === true || this.props.readonly === true)) {
      this.formCtrl.markAsDirty();
    }
  }

  onFromKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event?.preventDefault();
      this.fromInput.nativeElement.blur();
    }

    const input = this.fromInput.nativeElement.value;
    if (this.testTimeString(input)) {
      this.updateFields(input);
    }
    this.updateHostFieldValue();
  }

  onToKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event?.preventDefault();
      this.toInput.nativeElement.blur();
    }

    if (!this.checkAndUpdateTimeDiffInput(this.toInput!.nativeElement.value)) {
      if (this.testTimeString(this.toInput!.nativeElement.value)) {
        const diff = this.calculateTimeDifference(this.fromFormControl.value, this.toFormControl.value);
        if (diff < 0 && this.datesDiff == 0) {
          this.fromFormControl.setValue(this.toFormControl.value);
          this.timeDiffSec = 0;
          this.updateToTimes(this.fromFormControl.value);
        } else {
          this.timeDiffSec = diff;
        }
        this.updateHostFieldValue();
      }
    }
  }

  onFromSelectChange(event: MatAutocompleteSelectedEvent) {
    this.updateFields(event.option.value);
    this.updateHostFieldValue();
  }

  onToSelectChange() {
    this.timeDiffSec = this.calculateTimeDifference(this.fromFormControl.value, this.toFormControl.value);
    this.updateHostFieldValue();
  }

  onFromDateChange() {
    if (this.fromDateFC.value && this.fromDateFC.value.isValid()) {
      const newTo: Moment = this.fromDateFC.value;
      newTo.add(this.datesDiff, 'days');
      this.toDateFC.setValue(newTo);
    }
    this.updateHostFieldValue();
  }

  onToDateChange() {
    const diff = this.calculateDayDifference(moment(this.fromDateFC.value).toDate(), moment(this.toDateFC.value).toDate());
    if (diff > 0) {
      this.datesDiff = diff;
      this.toOptions = [...this.fromOptions!];
    } else {
      this.datesDiff = 0;
      this.fromDateFC.setValue(this.toDateFC.value);
      if (this.calculateTimeDifference(this.fromFormControl.value, this.toFormControl.value) < 0) {
        this.toFormControl.setValue(this.fromFormControl.value);
      }
      this.updateToTimes(this.fromFormControl.value);
    }
    this.updateHostFieldValue();
  }

  onFromDateKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event?.preventDefault();
      this.fromDateInput.nativeElement.blur();
    }
    this.updateHostFieldValue();
  }

  onToDateKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event?.preventDefault();
      this.toDateInput.nativeElement.blur();
    }
    this.updateHostFieldValue();
  }

  onTimeInputFocus(event: MouseEvent) {
    (event.target as HTMLInputElement).select();
  }

  updateFields(newFromValue: string) {
    const newTo = this.addToTime(newFromValue, this.timeDiffSec);
    this.timeDiffSec = this.calculateTimeDifference(newFromValue, newTo);
    this.toFormControl.setValue(newTo);
    if (this.datesDiff == 0) {
      this.updateToTimes(newFromValue);
    } else {
      this.toOptions = [...this.fromOptions!];
    }
  }

  testTimeString(time: string): boolean {
    if (!time) {
      return false;
    }
    if (this.autoOptions.displaySeconds!) {
      if (time.length != 8) {
        return false;
      }
      const reg = new RegExp('(?:[01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]');
      return reg.test(time);
    } else {
      if (time.length != 5) {
        return false;
      }
      const reg = new RegExp('(?:[01][0-9]|2[0-3]):[0-5][0-9]');
      return reg.test(time);
    }
  }

  checkAndUpdateTimeDiffInput(input: string): boolean {
    let diffSec = 0;
    input = input.replace(',', '.');
    if (input.slice(-1) === 'm') {
      const t = input.slice(0, -1);
      try {
        const diffMin = this.autoOptions.displaySeconds ? parseFloat(t) : parseInt(t);
        diffSec = Math.floor(diffMin * 60);
      } catch {
        return false;
      }
    } else if (input.slice(-3) === 'min') {
      const t = input.slice(0, -3);
      try {
        const diffMin = this.autoOptions.displaySeconds ? parseFloat(t) : parseInt(t);
        diffSec = Math.floor(diffMin * 60);
      } catch {
        return false;
      }
    } else if (input.slice(-1) === 'h') {
      const t = input.slice(0, -1);
      try {
        const diffH = parseFloat(t);
        const diffMin = this.autoOptions.displaySeconds ? diffH * 60 : Math.floor(diffH * 60);
        diffSec = Math.floor(diffMin * 60);
      } catch {
        return false;
      }
    } else if (this.autoOptions.displaySeconds && input.slice(-1) === 's') {
      const t = input.slice(0, -1);
      try {
        diffSec = parseInt(t);
      } catch {
        return false;
      }
    }

    if (diffSec > 0) {
      this.timeDiffSec = diffSec;
      const format = this.autoOptions.displaySeconds ? 'HH:mm:ss' : 'HH:mm';
      const currentToTime = moment((this.value as Array<string>)[1]).format(format);
      const newFrom = this.addToTime(currentToTime, -diffSec);
      this.fromFormControl.setValue(newFrom);
      this.toFormControl.setValue(currentToTime);
      this.updateHostFieldValue();

      return true;
    }
    return false;
  }

  // calculates difference between two hh:mm format times in seconds
  calculateTimeDifference(time1: string, time2: string) {
    if (!(this.testTimeString(time1) && this.testTimeString(time2))) {
      return 0;
    }
    const t1 = time1.split(':');
    const t2 = time2.split(':');
    if (this.autoOptions.displaySeconds!) {
      return (
        parseInt(t2[0]) * 3600 + parseInt(t2[1]) * 60 + parseInt(t2[2]) - (parseInt(t1[0]) * 3600 + parseInt(t1[1]) * 60 + parseInt(t1[2]))
      );
    } else {
      return parseInt(t2[0]) * 3600 + parseInt(t2[1]) * 60 - (parseInt(t1[0]) * 3600 + parseInt(t1[1]) * 60);
    }
  }

  calculateDayDifference(date1: Date, date2: Date): number {
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setMilliseconds(0);
    date2.setHours(0);
    date2.setMinutes(0);
    date2.setSeconds(0);
    date2.setMilliseconds(0);
    const diffTime = date2.getTime() - date1.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  // adds minutes to passed hh:mm format time
  addToTime(time: string, seconds: number) {
    const t = time.split(':');
    const newTimeSec = parseInt(t[0]) * 3600 + parseInt(t[1]) * 60 + (this.autoOptions.displaySeconds! ? parseInt(t[2]) : 0) + seconds;
    const hh = Math.floor(newTimeSec / 3600);
    const mm = Math.floor(newTimeSec / 60) % 60;
    const ss = newTimeSec % 60;

    let timeString = `${this.formatTwoDigits(hh)}:${this.formatTwoDigits(mm)}`;
    if (this.autoOptions.displaySeconds!) {
      timeString += `:${this.formatTwoDigits(ss)}`;
    }
    if (hh < 0) {
      return this.autoOptions.displaySeconds! ? '00:00:00' : '00:00';
    } else if (hh >= 24) {
      return this.autoOptions.displaySeconds! ? '23:59:59' : '23:59';
    } else {
      return timeString;
    }
  }

  // updates autocomplete options for second autocomplete (with correct time differences and labels)
  updateToTimes(initialTime: string) {
    const newOptions: TimeModel[] = [];
    newOptions.push({ value: initialTime, label: initialTime + ' (0 min)' });
    if (this.autoOptions.customTimes) {
      for (let i = 0; i < this.autoOptions.customTimes.length; i++) {
        const t = this.addToTime(initialTime, this.autoOptions.customTimes[i] * 60);
        const l =
          this.autoOptions.customTimes[i] < 60
            ? this.autoOptions.customTimes[i] + ' min'
            : (this.autoOptions.customTimes[i] / 60).toFixed(1) + ' h';
        newOptions.push({ value: t, label: `${t} (${l})` });
      }
    }
    let t = newOptions[newOptions.length - 1].value;
    let timeDiff = this.autoOptions.customTimes ? this.autoOptions.customTimes[this.autoOptions.customTimes.length - 1] : 0;
    const n = Math.floor(
      this.calculateTimeDifference(t, this.autoOptions.displaySeconds! ? '23:59:59' : '23:59') / 60 / this.autoOptions.interval,
    );
    for (let i = 0; i < n; i++) {
      t = this.addToTime(t, this.autoOptions.interval * 60);
      timeDiff += this.autoOptions.interval;
      const l = timeDiff < 60 ? timeDiff + ' min' : (timeDiff / 60).toFixed(1) + ' h';
      newOptions.push({ value: t, label: `${t} (${l})` });
    }
    this.toOptions = newOptions;
  }

  async scrollToNearestTime() {
    if (!this.autocompleteTrigger.panelOpen) return;

    const overlayRef = this.autocompleteTrigger['_overlayRef'];
    if (!overlayRef) return console.warn('[DateTimeInputDouble.scrollToNearestTime] Could not find overlay ref');

    const overlayElement = overlayRef.overlayElement;
    if (!overlayElement) return console.warn('[DateTimeInputDouble.scrollToNearestTime] Could not find overlay element');

    for (let tries = 10; tries > 0; tries--) {
      const panel = overlayElement.querySelector('.mat-mdc-autocomplete-panel');

      if (!panel) {
        await new Promise((res) => setTimeout(res, 100 * (10 - tries)));
        continue;
      }

      setTimeout(() => this.scrollToSelectedOrCurrentTime(panel));
      return;
    }

    console.warn('[DateTimeInputDouble.scrollToNearestTime] Could not find autocomplete panel');
  }

  private scrollToSelectedOrCurrentTime(panel: HTMLElement) {
    const format = this.autoOptions.displaySeconds ? 'HH:mm:ss' : 'HH:mm';
    const currentMoment = moment();

    const options = Array.from(panel.querySelectorAll<HTMLElement>('mat-option'));
    let targetOption: HTMLElement | undefined;

    const selectedOption = options.find((option) => option.classList.contains('mdc-list-item--selected'));

    if (selectedOption) {
      targetOption = selectedOption;
    } else {
      let closestOption: HTMLElement | undefined;
      let smallestDifference = Infinity;

      for (const option of options) {
        const optionTimeText = option.querySelector<HTMLElement>('.mdc-list-item__primary-text')?.innerText?.trim();

        const optionTime = moment(optionTimeText, format);
        if (!optionTime.isValid()) continue;

        const diff = Math.abs(currentMoment.diff(optionTime));

        if (diff < smallestDifference) {
          smallestDifference = diff;
          closestOption = option;
        }
      }

      if (closestOption) targetOption = closestOption;
      else console.warn('[DateTimeInputDouble.scrollToSelectedOrCurrentTime] Could not find closestOption');
    }

    if (!targetOption) return;
    targetOption.scrollIntoView({ behavior: 'instant', block: 'center' });
  }
}
