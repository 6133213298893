<div class="input">
  <input
    matInput
    [id]="id"
    [formlyAttributes]="field"
    [formControl]="formCtrl"
    type="text"
    [required]="props.required ?? false"
    [placeholder]="props.placeholder ?? ''"
    [tabIndex]="props.tabindex"
    [readonly]="props.readonly"
    [matAutocomplete]="auto"
  />
  @for(action of actions | visibleFieldActions: value:field;track $index){
  <mat-icon [matTooltip]="(action.tooltip | tooltip | async) ?? ''" class="field-icon-btn" (click)="executeAction(action)">{{
    action.icon
  }}</mat-icon>
  }

  <ng-container
    *ngTemplateOutlet="props?.readonly === true || props?.disabled === true || !formControl.value ? noIconTemplate : valueIconTemplate"
  ></ng-container>
</div>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="dataDisplayFn">
  <ng-container *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
  </ng-container>

  <mat-option disabled *ngIf="showNoData">{{ 'no_data' | intl }} </mat-option>

  <ng-container *ngIf="!showNoData">
    <ng-container *ngTemplateOutlet="tree; context: { $implicit: optionsData, level: 0 }"></ng-container>

    <ng-template #tree let-items let-level="level">
      <ul class="tree-list">
        <li *ngFor="let item of items; let i = index">
          <mat-option
            [ngClass]="{
              'parent-item': item?.children && item.children.length > 0
            }"
            [value]="{ value: item.value, label: item.label, data: item.data }"
            [disabled]="item.disabled === true || (!selectableGroup && item?.children && item.children.length > 0)"
          >
            <span [style.color]="item.color?.textColor">{{ item.label }}</span>
            <ng-container *ngIf="item.description">
              <br />
              @if (isString(item.description)) {
              <span [style.color]="item.color?.textColor ?? 'gray'" style="font-size: 12px">{{ item.description }}</span>
              } @else if (isArray(item.description)) {
              <ng-container *ngFor="let item of descriptionsArray(item.description)">
                <span [ngStyle]="item.style ?? { color: 'gray' }" style="font-size: 12px">{{ item.label }}</span>
              </ng-container>
              }
            </ng-container>
          </mat-option>
          <ng-container *ngIf="item?.children && item.children.length > 0">
            <ng-container
              *ngTemplateOutlet="tree; context: { $implicit: item.children, level: level + 1 }"
              class="child-node"
            ></ng-container>
          </ng-container>
        </li>
      </ul>
    </ng-template>
  </ng-container>
</mat-autocomplete>

<ng-container *ngIf="errorMessage">
  <span style="color: red; font-size: 10px">{{ 'error' | intl }}: {{ errorMessage }}</span>
</ng-container>

<ng-template #valueIconTemplate>
  <mat-icon class="field-icon-btn" (click)="clear()">close</mat-icon>
</ng-template>

<ng-template #noIconTemplate>
  <div style="height: 24px"></div>
</ng-template>
