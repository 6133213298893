import { Pipe, PipeTransform } from '@angular/core';
import { Attachment, EmlAttachment } from '@rcg/core';

@Pipe({
  name: 'attachmentsFilter',
})
export class AttachmentsFilterPipe<T extends Attachment | EmlAttachment> implements PipeTransform {
  transform(attachments: T[], displayInline: boolean): T[] {
    return displayInline ? attachments : attachments.filter((att) => !att.inline);
  }
}
