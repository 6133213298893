import { Injectable } from '@angular/core';
import { RcgTenant } from '@rcg/auth';
import { RcgListItem, TenantId } from '@rcg/core';
import { IAction } from '../../models/actions';

export type RcgActionName =
  | 'add-helpdesk-item'
  | 'link-phone-call'
  | 'link-active-phone-call'
  | 'add-project'
  | 'add-sla'
  | 'add-purchase-order'
  | 'add-knowledgebase'
  | 'add-problem'
  | 'reset-user-password'
  | 'delete-user'
  | 'impersonate-user'
  | 'delete-module'
  | 'delete-contact'
  | 'enable-user'
  | 'disable-user'
  | 'impersonate-user'
  | 'add-installed-equipment'
  | 'add-warehouse';

@Injectable({
  providedIn: 'root',
})
export class FabActions {
  readonly actions = [
    {
      id: 1,
      name: 'link-phone-call' as RcgActionName,
      icon: 'link',
      tooltip: 'connect_phone_call',
      description: 'connect_phone_call',
      enabled: true,
      visible: true,
    },
    {
      id: 2,
      name: 'link-active-phone-call' as RcgActionName,
      icon: 'wifi_calling_3',
      tooltip: 'connect_active_phone_call',
      description: 'connect_active_phone_call',
      color: 'green',
      enabled: true,
      visible: true,
    },
    {
      id: 3,
      name: 'add-helpdesk-item' as RcgActionName,
      icon: 'add',
      tooltip: 'add_event',
      description: 'add_event',
      enabled: true,
      visible: true,
    },
    {
      id: 3,
      name: 'add-project' as RcgActionName,
      icon: 'add',
      tooltip: 'add_project',
      description: 'add_project',
      enabled: true,
      visible: true,
    },
    {
      id: 4,
      name: 'add-sla' as RcgActionName,
      icon: 'add',
      description: 'add_sla_contract',
      tooltip: 'add_sla_contract',
      enabled: true,
      visible: true,
    },
    {
      id: 5,
      name: 'add-purchase-order' as RcgActionName,
      icon: 'add',
      tooltip: 'add_purchase_order',
      description: 'add_purchase_order',
      enabled: true,
      visible: true,
    },
    {
      id: 6,
      name: 'add-knowledgebase' as RcgActionName,
      icon: 'add',
      tooltip: 'add_knowledge_base',
      description: 'add_knowledge_base',
      enabled: true,
      visible: true,
    },
    {
      id: 7,
      name: 'add-problem' as RcgActionName,
      icon: 'add',
      tooltip: 'add_problem',
      description: 'add_problem',
      enabled: true,
      visible: true,
    },
    {
      id: 8,
      name: 'add-installed-equipment' as RcgActionName,
      icon: 'add',
      tooltip: 'installed_equipment',
      description: 'installed_equipment',
      enabled: true,
      visible: true,
    },
    {
      id: 9,
      name: 'add-warehouse' as RcgActionName,
      icon: 'add',
      tooltip: 'warehouse',
      description: 'warehouse',
      enabled: true,
      visible: true,
    },
  ];

  getFabActions(
    tenant: RcgTenant,
    category: string,
    selectedHelpdeskItem: RcgListItem | null,
    isEndUser: boolean,
    moduleId: number,
  ): {
    alwaysExpanded: boolean;
    actions: IAction[];
  } {
    const actions = () => {
      const isKnowledgeBaseEndUser = isEndUser && category === 'knowledge_base';
      if ((moduleId === 317 && isKnowledgeBaseEndUser) || (category === 'warehouse' && tenant.id !== TenantId.NcrAlbania)) {
        return [];
      }

      if (moduleId === 317 && tenant.id === TenantId.NcrAlbania && category === 'warehouse') {
        return this.getActions(['add-warehouse']);
      }

      if (category === 'telephony') {
        return selectedHelpdeskItem ? this.getActions(['link-phone-call']) : [];
      }

      if (tenant.id === TenantId.Projektip && moduleId === 323) {
        if (category === 'sla-active' || category === 'sla-all') {
          return this.getActions(['add-sla']);
        }
        if (category === 'purchase-order-active' || category === 'purchase-order-all') {
          return this.getActions(['add-purchase-order']);
        }
        return [];
      }

      if (category === 'project' || category === 'project_active' || category === 'project_all') {
        if (tenant.isAdmin || tenant.modules?.find((x) => x.id == 323 && x.settings?.['allow_mutation'] === true) !== undefined) {
          return this.getActions(['add-project']);
        }
        return [];
      }
      if (category === 'knowledge_base') {
        return this.getActions(['add-knowledgebase']);
      }

      if (category === 'problems') {
        return this.getActions(['add-problem']);
      }

      if (category === 'installed-equipment') {
        return this.getActions(['add-installed-equipment']);
      }

      return this.getActions(['add-helpdesk-item']);
    };

    return {
      alwaysExpanded: [TenantId.GeneraliInv].includes(tenant.id),
      actions: actions(),
    };
  }

  private getActions(names: RcgActionName[]): IAction[] {
    if (!names || names.length === 0) {
      return [];
    }
    return this.actions.filter((action) => names.includes(action.name));
  }
}
