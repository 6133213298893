import { ChangeDetectionStrategy, Component, Inject, InjectionToken, OnInit, Optional, inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import * as Get from '@npm-libs/ng-getx';
import { AuthService, RcgTenant } from '@rcg/auth';
import { FormConfig, GqlInput, InfinityListService, RcgContact, RcgListItem } from '@rcg/core';
import { GraphqlClientService } from '@rcg/graphql';
import { tr } from '@rcg/intl';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  of,
  pipe,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FormDialogService } from '../../services';
import { SupportedForm } from '../../utils';
import { countDebtorQuery } from '../accounts-receivable/gql';
import {
  InstalledEquipmentQuery,
  countInstalledEquipmentQuery,
  countOrganizationChildren,
  organizationChildren,
  organizationContactsSubscription,
  organizationContactsSubscriptionVariables,
} from './gql';

export const infinityListServiceNames = ['installedEquipmentList', 'orgChildrensList'] as const;
export const infinityListServiceTokens = infinityListServiceNames.map((name) => ({
  name,
  token: new InjectionToken<InfinityListService>(`${name}ListService`),
}));

@Get.NgAutoDispose
@Component({
  selector: 'rcg-organizations-detail',
  templateUrl: './organizations-detail.component.html',
  styleUrls: ['./organizations-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...infinityListServiceTokens.map(({ token }) => ({ provide: token, useClass: InfinityListService }))],
})
export class OrganizationsDetailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private graphClient: GraphqlClientService,
    private formDialogService: FormDialogService,
    @Inject(MAT_DIALOG_DATA) @Optional() private dialogData: { id: number } | null,
  ) {}

  selectedTab = signal<number | undefined>(undefined);
  selectedTab$ = toObservable(this.selectedTab);

  private infinityListServices: { [key: string]: InfinityListService } = infinityListServiceTokens
    .map(({ name, token }) => ({ [name]: inject(token) }))
    .reduce((a, b) => ({ ...a, ...b }));

  get installedEquipmentList(): InfinityListService {
    return this.infinityListServices['installedEquipmentList'];
  }
  get orgChildrensList(): InfinityListService {
    return this.infinityListServices['orgChildrensList'];
  }

  readonly id$ = this.route.paramMap.pipe(map((params) => this.dialogData?.id ?? +params.get('id')!));

  readonly accountReceivableParams = toSignal(
    this.id$.pipe(
      distinctUntilChanged(),
      map((id) => ({ cutomerId: id, organizationId: this.auth.tenant()?.organization?.id })),
    ),
  );

  readonly organizationFormConfig$ = this.id$.pipe(
    map((id) => {
      const formConfig: FormConfig = {
        formMode: 'update',
        formId: SupportedForm.admin_organization,
        formrecordId: id,
      };

      return formConfig;
    }),
  );

  private readonly orgChildrenInput$ = combineLatest([this.id$, this.auth.tenant$, this.selectedTab$]).pipe(
    filter(([, tenant]) => !!tenant),
    map(([id, tenant, selectedTab]) => ({
      id: id,
      tenant: tenant!,
      selectedTab: selectedTab,
    })),
  );

  ngOnInit(): void {
    this.getOrganizationChildren(this.orgChildrenInput$);
    this.getInstalledEquipment(this.orgChildrenInput$);
  }

  private readonly getOrganizationChildren = rxMethod<{
    id: number;
    tenant: RcgTenant;
    selectedTab: number | undefined;
  }>(
    pipe(
      tap((params) => {
        if (params.selectedTab !== 2) {
          return;
        }
        const gql: GqlInput<RcgListItem> = {
          searchable: true,
          type: 'query',
          query: organizationChildren,
          variables: {
            org_share_type: params.tenant.organizationShareType,
            org_id: params.tenant.organization.id,
            tenant_id: params.tenant.id,
            id: params.id,
            offset: 0,
            limit: 15,
            search: '',
          },
        };
        this.orgChildrensList.loadData(gql, undefined, undefined);
      }),
    ),
  );

  readonly counInstalledEquipment$ = combineLatest([this.id$, this.auth.tenant$]).pipe(
    filter(([, tenant]) => !!tenant),
    map(([id, tenant]) => ({
      id: id,
      tenant: tenant!,
    })),
    switchMap(({ id, tenant }) => {
      return this.graphClient
        .subscribe<{
          data: {
            aggregate: {
              count: number;
            };
          };
        }>({
          query: countInstalledEquipmentQuery,
          variables: {
            tenant_id: tenant.id,
            orgId: id,
          },
        })
        .pipe(
          map(({ data }) => data?.aggregate?.count ?? 0),
          catchError(() => {
            return of(0);
          }),
        );
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly accountsReceivableCount$ = this.id$.pipe(
    filter((id) => !!id),
    switchMap((id) => {
      const organizationId = this.auth.tenant()?.organization?.id;
      if (!organizationId) {
        return of(0);
      }
      return this.graphClient
        .query<{
          data: {
            aggregate: {
              count: number;
            };
          };
        }>({
          query: countDebtorQuery,
          variables: {
            search: '',
            organizationId: organizationId,
            customerId: id,
          },
        })
        .pipe(
          map(({ data }) => data?.aggregate?.count ?? 0),
          catchError((e) => {
            console.error('Error fetching accounts receivable count', e);
            return of(0);
          }),
        );
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  private readonly getInstalledEquipment = rxMethod<{
    id: number;
    tenant: RcgTenant;
    selectedTab: number | undefined;
  }>(
    pipe(
      tap((params) => {
        if (params.selectedTab !== 3) {
          return;
        }

        const gql: GqlInput<RcgListItem> = {
          searchable: false,
          type: 'query',
          query: InstalledEquipmentQuery,
          variables: {
            tenant_id: params.tenant.id,
            orgId: params.id,
            offset: 0,
            limit: 15,
          },
        };
        this.installedEquipmentList.loadData(gql, undefined, undefined);
      }),
    ),
  );

  readonly countOrganizationChildren$ = combineLatest([this.id$, this.auth.tenant$]).pipe(
    filter(([, tenant]) => !!tenant),
    map(([id, tenant]) => ({
      id: id,
      tenant: tenant!,
    })),
    switchMap(({ id, tenant }) => {
      return this.graphClient
        .subscribe<{
          data: {
            aggregate: {
              count: number;
            };
          };
        }>({
          query: countOrganizationChildren,
          variables: {
            org_share_type: tenant.organizationShareType,
            org_id: tenant.organization.id,
            tenant_id: tenant.id,
            id: id,
            search: '',
          },
        })
        .pipe(
          map(({ data }) => data?.aggregate?.count ?? 0),
          catchError(() => {
            return of(0);
          }),
        );
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly searchContactsR = ''.obs();

  readonly contacts$ = combineLatest([this.id$, this.auth.tenant$, this.searchContactsR.value$.pipe(debounceTime(200))]).pipe(
    filter(([, tenant]) => !!tenant),
    map(([orgId, tenant, search]) => [orgId, tenant!, search] as const),
    switchMap(([orgId, tenant, search]) => {
      return this.graphClient
        .subscribe<{ data: RcgContact[] }>({
          query: organizationContactsSubscription[tenant.organizationShareType],
          variables: organizationContactsSubscriptionVariables(orgId, tenant, search),
        })
        .pipe(startWith({ data: [] }));
    }),
    map(({ data }) => data),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  async openContact(contact: RcgContact) {
    const title = await firstValueFrom(tr('contact'));
    this.formDialogService.openForm({
      formMode: 'update',
      dialogTitle: `${title}`,
      formId: SupportedForm.contact,
      formrecordId: contact.id,
    });
  }

  async openOrganization(data: Record<string, unknown>) {
    const title = await firstValueFrom(tr('organization'));
    const orgName = data['short_name'] ? ` - ${data['short_name']}` : '';
    this.formDialogService.openForm({
      formMode: 'update',
      dialogTitle: `${title} ${orgName}`,
      formId: SupportedForm.admin_organization,
      formrecordId: data.id as number,
    });
  }

  async openDevice(data: Record<string, unknown>) {
    const title = await firstValueFrom(tr('installed_equipment'));
    const productId = data['product_id'] ? ` - ${data['product_id']}` : '';
    this.formDialogService.openForm({
      formMode: 'update',
      dialogTitle: `${title} ${productId}`,
      formId: (data.form_id as number) ?? SupportedForm.admin_device,
      formrecordId: data.id as number,
    });
  }

  searchOrgChildren(search: string) {
    this.orgChildrensList.search(search);
  }

  get visible() {
    return window.innerWidth / window.innerHeight <= 1;
  }
}
