import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  ViewChild,
  computed,
  effect,
  signal,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SmsOTPMfaEnrollProps, UnInitMfaEnrollResponse } from '@npm-libs/auth-manager';
import { AuthService } from '@rcg/auth';
import { tr } from '@rcg/intl';
import { MessageService, OtpPinInputComponent } from '@rcg/standalone';
import { firstValueFrom } from 'rxjs';
import { AddMfaDialogComponent } from '../../add-mfa-dialog.component';
import { propsFormFields } from './props-form-config';

const authType = 'OTP/SMS';

@Component({
  selector: 'rcg-sms-mfa-adder[dialogRef]',
  templateUrl: './sms-adder.component.html',
  styleUrls: ['./sms-adder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmsMfaAdderComponent implements OnInit, OnDestroy {
  @Input() public dialogRef!: MatDialogRef<AddMfaDialogComponent, void>;

  @Output() afterAdded = new EventEmitter<void>();

  @ViewChild('pinInput') readonly pinInput!: OtpPinInputComponent;

  public readonly enrollResponse = signal<UnInitMfaEnrollResponse<typeof authType> | null>(null);
  public readonly enteredCode = signal<string | null>(null);

  public readonly loading = computed(() => this.enteredCode() !== null);

  public readonly propsFormFields = propsFormFields;
  public readonly setProps = signal<Partial<SmsOTPMfaEnrollProps>>({});
  public readonly propsFormModel = computed<Partial<SmsOTPMfaEnrollProps> & { loading: boolean }>(() => ({
    ...(this.enrollResponse()?.props ?? {}),
    loading: this.loading(),
  }));

  public readonly hasDigits: Signal<boolean> = computed(() => !!this.enrollResponse()?.props?.digits);
  public readonly numDigits: Signal<number> = computed(() => this.enrollResponse()?.props?.digits ?? 8);
  public readonly digitsDisabled: Signal<boolean> = computed(() => this.loading() || !this.hasDigits());

  public readonly enrollId: Signal<number | undefined> = computed(() => this.enrollResponse()?.id);

  private readonly refreshResend = signal(0);
  private readonly lastSentAt = signal(0);

  public readonly allowResendInSeconds = computed(() => {
    this.refreshResend();

    const now = Date.now();

    const period = this.enrollResponse()?.props?.period ?? 60;
    const diff = (now - this.lastSentAt()) / 1000;

    return Math.round(Math.max(0, period - diff));
  });

  private refreshInterval?: number;

  constructor(private readonly authService: AuthService, private readonly msg: MessageService) {
    effect(
      async () => {
        const code = this.enteredCode();
        if (!code) return;

        try {
          await this.authService.initMfaEnroll(this.enrollId()!, {
            type: authType,
            code,
          });

          this.msg.showInfoSnackbar(await firstValueFrom(tr('mfa_sms_enroll_success_msg')));
          this.afterAdded.emit();
          this.dialogRef.close();
        } catch (error) {
          this.msg.showErrorSnackbar(await firstValueFrom(tr('error')), error);
          this.enteredCode.set(null);
          this.pinInput.clear();
        }
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    this.refreshInterval = window.setInterval(() => this.refreshResend.set(this.refreshResend() + 1), 1000);
  }

  ngOnDestroy(): void {
    window.clearInterval(this.refreshInterval);
  }

  public async enroll() {
    const props = this.setProps() as { phoneNumber?: string[] };
    this.enrollResponse.set(null);

    const phoneNumber = props.phoneNumber?.[0];
    if (!phoneNumber?.length) {
      this.msg.showErrorSnackbar(await firstValueFrom(tr('mfa_sms_enroll_phone_number_error')));
      return;
    }

    try {
      const res = await this.authService.mfaEnroll({
        type: authType,
        phoneNumber: phoneNumber,
      });

      this.lastSentAt.set(Date.now());
      this.enrollResponse.set(res);
    } catch (error) {
      this.msg.showErrorSnackbar('Error', error);
    }
  }
}
