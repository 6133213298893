import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as Get from '@npm-libs/ng-getx';
import {
  Attachment,
  AttachmentBlobUrls,
  AttachmentsDialogConfig,
  AttachmentsService,
  EmlAttachment,
  EmlEmail,
  EmlService,
  HTMLAttachmentResolverService,
  attachmentContentType,
  attachmentFilename,
  attachmentIdentifier,
  isEmlAttachment,
} from '@rcg/core';
import { FrameDOMParser, fullscreenDialog, normalizeAndCleanHTML, sanitizeHtmlEntities } from '@rcg/standalone';
import { formatBytes } from '@rcg/utils';

import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import xml from 'highlight.js/lib/languages/xml';

hljs.registerLanguage('json', json);
hljs.registerLanguage('xml', xml);

import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  catchError,
  combineLatest,
  distinctUntilChanged,
  from,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

const docMimeTypes = [
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation',
];

const attachmentBaseUrl = 'https://cdn.assist.si/attachment/';
const docEmbedBaseUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${attachmentBaseUrl}`;

const frameDomParser = new FrameDOMParser();

const emlAdditionalHeadElementsList = [
  ...new DOMParser().parseFromString(
    `
<html>
  <head>
    <base target="_blank">
    <style>
      img {
        max-width: 100%;
        height: auto !important;
      }
    </style>
  </head>
</html>
`,
    'text/html',
  ).head.children,
];

function emlAdditionalHeadElements() {
  return emlAdditionalHeadElementsList.map((e) => e.cloneNode(true));
}

@Get.NgAutoDispose
@Component({
  selector: 'rcg-attachments-viewer-dialog',
  templateUrl: './attachments-viewer-dialog.component.html',
  styleUrls: ['./attachments-viewer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsViewerDialogComponent<T extends Attachment | EmlAttachment> implements OnInit, AfterViewInit, OnDestroy {
  readonly attachmentFilename = attachmentFilename;
  readonly attachmentContentType = attachmentContentType;

  @ViewChild('dialog', { read: ElementRef }) dialog!: ElementRef<HTMLElement>;

  @Output() closeDialog = new EventEmitter<void>();

  attachments: T[] = [];
  thumbnailBlobUrls: AttachmentBlobUrls | undefined;
  selectedAttachment: T | null = null;
  selectedBlob: Blob | null = null;
  selectedBlobUri: string | null = null;
  selectedViewToken: string | null = null;
  loading = false;

  downloadAttachmentSubject = new BehaviorSubject<T | null>(null);
  downloadAttachmentSubjectSubscription?: Subscription;
  errorMessage: string | null = null;

  emlEmail: Promise<EmlEmail> | null = null;

  private readonly emlHtmlR = new Get.Rx<Observable<string> | null, string | null>(null, [switchMap((o) => o ?? of(null))]);
  private readonly emlHtmlProgressR = new Get.Rx<Observable<number> | null, number | null>(null, [switchMap((o) => o ?? of(null))]);

  public readonly emlHtml$ = this.emlHtmlR.value$;
  public readonly emlHtmlProgress$ = this.emlHtmlProgressR.value$;

  public emlHeadersExpanded = false;

  /** [translation, type, builder][] */
  public readonly shownEmlHeaders: [string, 'html' | 'plain' | 'date', (email: EmlEmail) => string | null | undefined][] = [
    ['email_header_from_name', 'html', ({ headers }) => headers.from?.html],
    ['email_header_to_name', 'html', ({ headers }) => headers.to?.html],
    ['email_header_cc_name', 'html', ({ headers }) => headers.cc?.html],
    ['email_header_bcc_name', 'html', ({ headers }) => headers.bcc?.html],
    ['email_header_sender_name', 'html', ({ headers }) => headers.sender?.html],
    ['email_header_reply_to_name', 'html', ({ headers }) => headers['reply-to']?.html],
    ['email_header_delivered_to_name', 'html', ({ headers }) => headers['delivered-to']?.html],
    ['email_header_return_path_name', 'html', ({ headers }) => headers['return-path']?.html],
    ['email_header_date_name', 'date', ({ headers }) => headers.date],
    ['email_header_subject_name', 'plain', ({ headers }) => headers.subject],
    [
      'attachments',
      'plain',
      ({ attachments }) =>
        attachments
          .filter((a) => a.contentDisposition !== 'inline')
          .map((a) => `${a.filename}${a.size ? ` (${formatBytes(a.size)})` : ''}`)
          .join(', '),
    ],
  ];

  private _checkSelectedMime(prefix: string) {
    return [attachmentContentType(this.selectedAttachment), this.selectedBlob?.type].findIndex((s) => s?.startsWith(prefix)) !== -1;
  }

  get isImage(): boolean {
    return this._checkSelectedMime('image/');
  }

  get isVideo(): boolean {
    return this._checkSelectedMime('video/');
  }

  get isHtml(): boolean {
    return this._checkSelectedMime('text/html');
  }

  get isJson(): boolean {
    return this._checkSelectedMime('application/json');
  }

  get isXml(): boolean {
    return this._checkSelectedMime('text/xml') || this._checkSelectedMime('application/xml');
  }

  get isText(): boolean {
    return this._checkSelectedMime('text/');
  }

  get isDoc(): boolean {
    return docMimeTypes.findIndex((mime) => this._checkSelectedMime(mime)) !== -1;
  }

  get isPdf(): boolean {
    return this._checkSelectedMime('application/pdf');
  }

  get isEml(): boolean {
    return this._checkSelectedMime('message/rfc822');
  }

  get isMsg(): boolean {
    return (
      this._checkSelectedMime('application/vnd.ms-outlook') ||
      (this._checkSelectedMime('application/x-ole-storage') && !!attachmentFilename(this.selectedAttachment)?.endsWith('.msg'))
    );
  }

  get docEmbedUrl(): string {
    return `${docEmbedBaseUrl}${attachmentIdentifier(this.selectedAttachment!)}?token=${this.selectedViewToken}`;
  }

  get pdfEmbedUrl(): string {
    return `${attachmentBaseUrl}${attachmentIdentifier(this.selectedAttachment!)}?token=${this.selectedViewToken}`;
  }

  private _cachedRawText?: Promise<string | null>;
  private _cachedHtmlText?: Promise<string | null>;
  private _cachedSanitizedText?: Promise<string | null>;
  private _cachedTextLineNumbers?: Promise<string | null>;

  get cleanText(): Promise<string | null> {
    if (this._cachedRawText) return this._cachedRawText;

    const uri = this.selectedBlobUri;
    if (!uri) return Promise.resolve(null);

    return (this._cachedRawText = fetch(uri).then((r) => r.text()));
  }

  get htmlText(): Promise<string | null> {
    if (this._cachedHtmlText) return this._cachedHtmlText;

    return (this._cachedHtmlText = this.cleanText.then((html) => (html ? normalizeAndCleanHTML(html) : html)));
  }

  get sanitizedText(): Promise<string | null> {
    if (this._cachedSanitizedText) return this._cachedSanitizedText;

    return (this._cachedSanitizedText = this.cleanText.then(sanitizeHtmlEntities));
  }

  get textLineNumbers(): Promise<string | null> {
    if (this._cachedTextLineNumbers) return this._cachedTextLineNumbers;

    return (this._cachedTextLineNumbers = this.cleanText.then(
      (t) =>
        t
          ?.split('\n')
          .map((_, i) => i + 1)
          .join('\n') ?? null,
    ));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogConfig: AttachmentsDialogConfig<T>,
    public dialogRef: MatDialogRef<AttachmentsViewerDialogComponent<T>>,
    private attachmentsService: AttachmentsService,
    private emlService: EmlService,
    private htmlAttachmentResolver: HTMLAttachmentResolverService,
    private changeRef: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    if (
      !this.dialogConfig ||
      Object.keys(this.dialogConfig).length === 0 ||
      this.dialogConfig.attachments.length === 0 ||
      !this.dialogConfig.selectedAttachment
    ) {
      this.errorMessage = 'Napačne nastavitve za dialog';
      return;
    }

    this.attachments = this.dialogConfig.attachments ?? [];
    if (this.dialogConfig.thumbnailBlobUrls) {
      this.thumbnailBlobUrls = this.dialogConfig.thumbnailBlobUrls;
    } else {
      this.thumbnailBlobUrls = this.attachmentsService.getAttachmentsBlobUrls(this.attachments);
    }

    if (!this.thumbnailBlobUrls) {
      this.thumbnailBlobUrls = {};
    }

    this.addSelectedAttachment(this.dialogConfig.selectedAttachment);
    this._subscribeToDownloadAttachment();
  }

  ngAfterViewInit(): void {
    if (this.dialogConfig.openFullscreen) {
      fullscreenDialog(this.dialog.nativeElement);
    }
  }

  async highlightCode(p: Promise<unknown>, el: HTMLElement) {
    await p;
    setTimeout(() => hljs.highlightElement(el));
  }

  ngOnDestroy(): void {
    this.downloadAttachmentSubjectSubscription?.unsubscribe();
  }

  private _error(error: unknown) {
    let err: unknown = error;

    if (typeof err === 'object' && err) {
      let e: object = err;

      for (;;) {
        let found = false;

        for (const prop of ['error', 'message'] as const) {
          if (prop in e) {
            const p = (e as Record<typeof prop, unknown>)[prop];

            if (typeof p === 'string') {
              err = p;
              found = true;
              break;
            }

            if (typeof p === 'object' && p) {
              e = p;
              continue;
            }
          }
        }

        if (!found) err = e;
        break;
      }
    }

    try {
      this.errorMessage = typeof err === 'string' ? err : JSON.stringify(err);
    } catch (error) {
      this.errorMessage = `${err}`;
    }

    this.emlEmail = null;
    this.emlHtmlR.data = null;
    this.emlHtmlProgressR.data = null;

    this.loading = false;
    this.changeRef.markForCheck();
  }

  private _resolveEml(attachment: T | null) {
    const nope = () => {
      this.emlEmail = null;
      this.emlHtmlR.data = null;
      this.emlHtmlProgressR.data = null;
    };

    if (!attachment) return nope();

    const isEml = this.isEml;
    if (!isEml && !this.isMsg) return nope();

    const attachId = isEmlAttachment(attachment) ? `${attachment.emlId}/n/${attachment.partId}` : '' + attachment.id;
    this.emlEmail = isEml ? this.emlService.getEmlEmail(attachId) : this.emlService.getMsgEmail(attachId);

    const emlResolvedHtml$ = from(this.emlEmail.then((ee) => this._getEmlResolvedHtml(attachId, ee))).pipe(
      catchError((error) => {
        this._error(error);
        return EMPTY;
      }),
    );

    this.emlHtmlR.data = emlResolvedHtml$.pipe(switchMap((erh) => erh[0]));
    this.emlHtmlProgressR.data = emlResolvedHtml$.pipe(switchMap((erh) => erh[1]));
  }

  private _subscribeToDownloadAttachment() {
    this.downloadAttachmentSubjectSubscription = this.downloadAttachmentSubject
      .pipe(
        startWith(this.downloadAttachmentSubject.value),
        distinctUntilChanged(),
        tap((attachment) => {
          this.loading = true;
          this.errorMessage = null;
          this.selectedAttachment = attachment;
          this.changeRef.markForCheck();
        }),
        switchMap((attachment) => {
          if (!attachment) {
            return of([null, [null, null]] as const);
          }

          const blob = isEmlAttachment(attachment)
            ? this.emlService.downloadEmlAttachmentBlob(attachment)
            : this.attachmentsService.getAttachmentBlobById(attachment.id, false);

          return combineLatest([
            of(attachment),
            combineLatest([
              from(blob),
              this.isDoc || this.isPdf
                ? isEmlAttachment(attachment)
                  ? this.attachmentsService.getAttachmentViewToken(attachment.emlId, attachment.partId)
                  : this.attachmentsService.getAttachmentViewToken(attachment.id)
                : of(null),
            ]).pipe(
              catchError((err) => {
                this.errorMessage = err?.message ?? err.toString();
                return of([null, null] as const);
              }),
            ),
          ]);
        }),
      )
      .subscribe(([attachment, data]) => {
        try {
          [this.selectedBlob, this.selectedViewToken] = data;
          this.selectedBlobUri = this.selectedBlob ? this.attachmentsService.getBlobUri(this.selectedBlob) : null;

          this.emlHeadersExpanded = false;

          this._resolveEml(attachment);
        } catch (error) {
          this._error(error);
        }

        this._cachedRawText = undefined;
        this._cachedHtmlText = undefined;
        this._cachedSanitizedText = undefined;
        this._cachedTextLineNumbers = undefined;
        this.loading = false;
        this.changeRef.markForCheck();
      });
  }

  onThumbnailClick(attachment: T) {
    if (isEmlAttachment(attachment)) {
      if (!this.selectedAttachment || !isEmlAttachment(this.selectedAttachment)) {
        this.addSelectedAttachment(attachment);
        return;
      }

      if (attachment.emlId === this.selectedAttachment.emlId && attachment.partId === this.selectedAttachment.partId) return;

      this.addSelectedAttachment(attachment);
      return;
    }

    if (attachment.id === (this.selectedAttachment as { id?: number })?.id) return;
    this.addSelectedAttachment(attachment);
  }

  canDeleteSelectedAttachment() {
    if (!this.dialogConfig.canDelete) return false;

    const selectedAttachment = this.selectedAttachment;

    if (!selectedAttachment || !this.dialogConfig.deleteAttachment) {
      return false;
    }

    if (isEmlAttachment(selectedAttachment)) {
      return !!this.attachments.find(
        (a) => !a.inline && isEmlAttachment(a) && a.emlId === selectedAttachment.emlId && a.partId === selectedAttachment.partId,
      );
    } else {
      return !!this.attachments.find((a) => !a.inline && !isEmlAttachment(a) && a.id === selectedAttachment.id);
    }
  }

  deleteSelectedAttachment() {
    const selectedAttachment = this.selectedAttachment;

    if (!selectedAttachment || !this.dialogConfig.deleteAttachment) {
      return;
    }
    if (this.dialogConfig.deleteAttachment) {
      this.dialogConfig.deleteAttachment(selectedAttachment); // calbback to parent
    }

    if (isEmlAttachment(selectedAttachment)) {
      this.attachments = [
        ...this.attachments.filter(
          (a) => !isEmlAttachment(a) || (a.emlId !== selectedAttachment.emlId && a.partId !== selectedAttachment.partId),
        ),
      ];
    } else {
      this.attachments = [...this.attachments.filter((a) => isEmlAttachment(a) || a.id !== selectedAttachment.id)];

      if (this.thumbnailBlobUrls?.[selectedAttachment.id]) {
        delete this.thumbnailBlobUrls[selectedAttachment.id];
      }
    }

    this.addSelectedAttachment(null);
  }

  downloadSelectedAttachmentToDisk() {
    if (!this.selectedAttachment || !this.selectedBlobUri) {
      return;
    }
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = this.selectedBlobUri!;
    a.download = attachmentFilename(this.selectedAttachment) ?? 'priponka';
    a.click();
  }

  onCloseDialog() {
    this.closeDialog.emit();
    this.dialogRef.close();
  }

  addSelectedAttachment(attachment: T | null) {
    this.downloadAttachmentSubject.next(attachment);
  }

  private _getEmlResolvedHtml(emlId: string, emlEmail: EmlEmail) {
    const bodyHtml = this.emlService.getEmlBodyHtml(emlEmail);
    const [html$, progress$] = this.htmlAttachmentResolver.resolve(bodyHtml, [], [emlId, emlEmail]);

    const modifiedHtml$ = html$.pipe(
      switchMap(async (html) => {
        const htmlDom = await frameDomParser.parseFromString(html);
        htmlDom.head.append(...emlAdditionalHeadElements());
        return htmlDom.documentElement.outerHTML;
      }),
    );

    return [modifiedHtml$, progress$] as const;
  }

  public toggleEmlHeaders() {
    this.emlHeadersExpanded = !this.emlHeadersExpanded;
    this.changeRef.markForCheck();
  }
}
