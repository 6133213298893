import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@rcg/auth';
import { GraphqlClientService } from '@rcg/graphql';
import { tr } from '@rcg/intl';
import { MessageService } from '@rcg/standalone';
import { Observable, firstValueFrom, map } from 'rxjs';
import { initializedUserMfaSubscription } from './gql/user_mfa.subscription.gql';

interface UserMfa {
  id: number;
  type: string;
  created_at: string;
  description: string;
}

@Component({
  selector: 'rcg-modify-mfa-dialog',
  templateUrl: './modify-mfa-dialog.component.html',
  styleUrls: ['./modify-mfa-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModifyMfaDialogComponent {
  public readonly mfaIconMap: Record<string, string | undefined> = {
    'OTP/RFC6238': 'update',
    'OTP/SMS': 'textsms',
    'recovery/code': 'sync_problem',
  };

  public readonly mfaNameMap: Record<string, Observable<string> | undefined> = {
    'OTP/RFC6238': tr('mfa_type_totp'),
    'OTP/SMS': tr('mfa_type_sms'),
    'recovery/code': tr('mfa_type_recovery_code_plural'),
  };

  public readonly userMfa = toSignal(
    this.gqlService
      .subscribe<{ data: UserMfa[] }>({
        query: initializedUserMfaSubscription,
      })
      .pipe(map((res) => res.data)),
  );

  constructor(
    public readonly dialogRef: MatDialogRef<ModifyMfaDialogComponent, void>,
    private readonly gqlService: GraphqlClientService,
    private readonly auth: AuthService,
    private readonly msg: MessageService,
  ) {}

  private async _deleteUserMfa(mfa: UserMfa) {
    try {
      await this.auth.mfaRemove(mfa.id);

      this.msg.showInfoSnackbar(await firstValueFrom(tr('user_mfa_delete_success_msg', { ...mfa })));
    } catch (error) {
      this.msg.showErrorSnackbar('User MFA delete', error);
    }
  }

  async deleteUserMfa(mfa: UserMfa) {
    const confirmed = await this.msg.confirmDialogAsync({
      icon: 'delete_forever',
      title: await firstValueFrom(tr('delete')),
      message: await firstValueFrom(tr('user_mfa_delete_confirm_msg', { ...mfa })),
      confirmText: await firstValueFrom(tr('delete')),
      cancelText: await firstValueFrom(tr('cancel')),
    });

    if (!confirmed) return;

    return this._deleteUserMfa(mfa);
  }
}
