import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditorDialogConfig } from '@rcg/core';
import { fullscreenDialog } from '@rcg/standalone';

@Component({
  selector: 'rcg-editor-dialog',
  templateUrl: './editor-dialog.component.html',
  styleUrls: ['./editor-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorDialogComponent implements AfterViewInit {
  @ViewChild('dialog', { read: ElementRef }) dialog!: ElementRef<HTMLElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogConfig: EditorDialogConfig, public dialogRef: MatDialogRef<EditorDialogComponent>) {}

  ngAfterViewInit(): void {
    if (this.dialogConfig.openFullscreen) {
      fullscreenDialog(this.dialog.nativeElement);
    }
  }
}
