import { ComponentRef, EventEmitter } from '@angular/core';
import { EditorView, InputRule, NodeSpec } from '@progress/kendo-angular-editor';
import OrderedMap from 'orderedmap';
import { Fragment, Node, TagParseRule } from 'prosemirror-model';

const nodeName = 'suggestion';

export interface SuggestorComponent {
  suggestion?: string;
  identifier?: string;
  identifierChange: EventEmitter<string | undefined>;
}

function findNodePos(node: Node, parentNode: Node | Fragment, lvl = 1): number | undefined {
  let pos: number | undefined;

  parentNode.forEach((childNode, p) => {
    if (pos !== undefined) return;
    if (childNode.attrs._nodeId === node.attrs._nodeId) pos = p;
    if (pos === undefined) pos = findNodePos(node, childNode, lvl + 1);
  });

  return pos;
}

const nodeIdGenerator = (function* () {
  let i = 0;

  while (true) {
    yield i++;
  }

  return i++;
})();

const nextNodeId = () => nodeIdGenerator.next().value;

export function suggestionNodeSpec<T extends SuggestorComponent>(
  tags: string[],
  getDoc: () => Document,
  createComponent: (tag: string) => ComponentRef<T>,
  getView: () => EditorView | undefined,
): NodeSpec {
  return {
    attrs: {
      _nodeId: {},
      tag: {},
      attributes: {},
    },
    group: 'inline',
    content: 'text*',
    atom: false,
    inline: true,
    draggable: false,
    toDOM: (node) => {
      const doc = getDoc();
      const el = doc.createElement(node.attrs.tag) as HTMLElement;

      if (node.attrs.attributes.identifier) {
        el.setAttribute('identifier', node.attrs.attributes.identifier);
      }

      const componentRef = createComponent(node.attrs.tag);
      const component = componentRef.instance;

      componentRef.setInput('suggestion', node.attrs.attributes.suggestion);
      componentRef.setInput('identifier', node.attrs.attributes.identifier);

      setTimeout(() => componentRef.changeDetectorRef.detectChanges());

      component.identifierChange.subscribe((identifier) => {
        const view = getView();
        if (!view) {
          console.error('[suggestionNodeSpec]', 'View not found');
          return;
        }
        const state = view.state;

        const foundPos = findNodePos(node, state.doc);

        if (foundPos === undefined) {
          console.error('[suggestionNodeSpec]', 'Node pos not found', { view, state, foundPos, node });
          return;
        }

        const pos = foundPos + 1;

        const transaction = state?.tr.setNodeMarkup(pos, undefined, {
          ...node.attrs,
          attributes: {
            ...node.attrs.attributes,
            identifier,
          },
        });

        view.dispatch(transaction);
      });

      const componentEl: HTMLElement = componentRef.location.nativeElement;
      componentEl.style.display = 'none';

      let inDom = false;

      const observer = new MutationObserver(() => {
        if (doc.contains(el)) {
          inDom = true;
          el.appendChild(componentEl);
          componentEl.style.display = '';
          observer.disconnect();
        }
      });

      observer.observe(doc, { attributes: false, childList: true, characterData: false, subtree: true });

      setTimeout(() => {
        if (!inDom) componentRef.destroy();
        observer.disconnect();
      }, 1000);

      return el;
    },
    parseDOM: tags.map<TagParseRule>((tag) => ({
      tag,
      getAttrs: (element) => {
        if (typeof element === 'string') return {};

        return {
          _nodeId: nextNodeId(),
          tag,
          attributes: element.getAttributeNames().reduce((acc, name) => {
            return { ...acc, [name]: element.getAttribute(name) };
          }, {}),
        };
      },
    })),
  };
}

export function addSuggestionNodes<T extends SuggestorComponent>(
  nodes: OrderedMap<NodeSpec>,
  tags: string[],
  getDoc: () => Document,
  createComponent: (tag: string) => ComponentRef<T>,
  getView: () => EditorView | undefined,
) {
  return nodes.prepend({
    [nodeName]: suggestionNodeSpec(tags, getDoc, createComponent, getView),
  });
}

export const suggestionInputRule = (matcher: RegExp, tag: string) =>
  new InputRule(matcher, (state, match, start, end) => {
    const { schema } = state;
    const [, suggestion] = match;

    return state.tr.replaceWith(
      start,
      end,
      schema.node(nodeName, {
        _nodeId: nextNodeId(),
        tag,
        attributes: suggestion === undefined ? {} : { suggestion },
      }),
    );
  });
