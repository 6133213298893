import { Component, OnInit } from '@angular/core';
import { RcgFieldType, RcgFormlyFieldProps } from '@rcg/core';

interface CurrencyProps extends RcgFormlyFieldProps {
  maxDecimalPlaces?: number;
  minDecimalPlaces?: number;
}

@Component({
  selector: 'rcg-currency-field',
  template: ` <div style="width: 100%" (focusin)="onFocusIn()" (focusout)="onFocusOut()">
    <rcg-currency-form-field
      [formControl]="formControl"
      [placeholder]="to.placeholder"
      [maxDecimalPlaces]="props.maxDecimalPlaces ?? 2"
      [minDecimalPlaces]="props.minDecimalPlaces ?? 2"
      [disabled]="to.disabled ?? false"
      [readonly]="to.readonly ?? false"
    >
    </rcg-currency-form-field>
  </div>`,
})
export class CurrencyFieldComponent extends RcgFieldType<number | null, CurrencyProps> implements OnInit {
  ngOnInit(): void {
    // set default value if exists
    if (
      this.formState.query_variable &&
      !this.model?.[this.formState.query_variable] &&
      (this.field.defaultValue || this.field.defaultValue === 0)
    ) {
      const defaultValue = typeof this.field.defaultValue === 'function' ? this.field.defaultValue(this.model) : this.field.defaultValue;

      if (typeof defaultValue !== 'number') {
        console.error('Default value is not a number:', defaultValue);
        return;
      }
      this.formControl.setValue(defaultValue);
    }
  }

  onFocusIn() {
    if (!this.focused) {
      this.field.focus = true;
      this.stateChanges.next();
    }
  }

  onFocusOut() {
    this.field.focus = false;
    this.stateChanges.next();
  }
}
