import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewChild,
  WritableSignal,
  computed,
  effect,
  signal,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OTPMfaDigest, Rfc6238OTPMfaEnrollProps, UnInitMfaEnrollResponse } from '@npm-libs/auth-manager';
import { AuthService } from '@rcg/auth';
import { tr } from '@rcg/intl';
import { MessageService, OtpPinInputComponent } from '@rcg/standalone';
import * as QRCode from 'qrcode';
import { firstValueFrom } from 'rxjs';
import { AddMfaDialogComponent } from '../../add-mfa-dialog.component';
import { propsFormFields } from './props-form-config';

type TotpAlgorithm = 'SHA1' | 'SHA256' | 'SHA512';

const totpDigestAlgorithmMap: Record<OTPMfaDigest, TotpAlgorithm> = {
  'SHA-1': 'SHA1',
  'SHA-256': 'SHA256',
  'SHA-512': 'SHA512',
};

interface TotpQrSettings {
  type: 'totp';
  issuer: string;
  accountName: Promise<string | undefined> | string | undefined;
  secret: string;
  digits: number;
  algorithm?: TotpAlgorithm;
  period?: number;
}

const authType = 'OTP/RFC6238';

@Component({
  selector: 'rcg-totp-mfa-adder[dialogRef]',
  templateUrl: './totp-adder.component.html',
  styleUrls: ['./totp-adder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotpMfaAdderComponent implements AfterViewInit {
  @Input() public dialogRef!: MatDialogRef<AddMfaDialogComponent, void>;

  @Output() afterAdded = new EventEmitter<void>();

  @ViewChild('qrCode', { read: ElementRef }) private qrCode!: ElementRef<HTMLCanvasElement>;
  @ViewChild('bottom', { read: ElementRef }) private bottom!: ElementRef<HTMLElement>;
  @ViewChild('pinInput') readonly pinInput!: OtpPinInputComponent;

  private readonly _viewInit = signal(false);

  private readonly hostRect = signal<DOMRectReadOnly | null>(null);
  private readonly bottomRect = signal<DOMRectReadOnly | null>(null);

  public readonly qrSize = signal(200);
  public readonly enrollResponse = signal<UnInitMfaEnrollResponse<typeof authType> | null>(null);
  public readonly enteredCode = signal<string | null>(null);

  public readonly qrSettings = computed<TotpQrSettings | null>(() => {
    const res = this.enrollResponse();
    if (!res) return null;

    const { secret, digits, digest, period } = res.props as Rfc6238OTPMfaEnrollProps;

    return {
      type: 'totp',
      issuer: 'RcG',
      accountName: this.authService.user()?.email,
      secret,
      digits,
      algorithm: totpDigestAlgorithmMap[digest],
      period,
    };
  });

  public readonly hasQrSettings = computed(() => this.qrSettings() !== null);
  public readonly loading = computed(() => !this.hasQrSettings() || this.enteredCode() !== null);

  public readonly propsFormFields = propsFormFields;
  public readonly setProps = signal<Partial<Rfc6238OTPMfaEnrollProps>>({});
  public readonly propsFormModel = computed<Partial<Rfc6238OTPMfaEnrollProps> & { loading: boolean }>(() => ({
    ...(this.enrollResponse()?.props ?? {}),
    loading: this.loading(),
  }));

  public readonly numDigits: Signal<number> = computed(() => this.enrollResponse()?.props?.digits ?? 6);
  public readonly enrollId: Signal<number | undefined> = computed(() => this.enrollResponse()?.id);

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly authService: AuthService,
    private readonly msg: MessageService,
  ) {
    effect(() => {
      this.qrSize();
      const viewInit = this._viewInit();
      const settings = this.qrSettings();

      if (!viewInit || !settings) return;

      setTimeout(() => this._showQr(settings));
    });

    effect(
      () => {
        const hostRect = this.hostRect();
        const bottomRect = this.bottomRect();

        if (!hostRect || !bottomRect) return;

        this._scaleQr(hostRect, bottomRect);
      },
      { allowSignalWrites: true },
    );

    let resTimeout: number | undefined;

    effect(
      () => {
        const props = this.setProps();

        window.clearTimeout(resTimeout);
        this.enrollResponse.set(null);

        resTimeout = window.setTimeout(async () => {
          //TODO: error checks

          const res = await this.authService.mfaEnroll({
            type: authType,
            digits: props.digits,
            digest: props.digest,
            period: props.period,
          });

          this.enrollResponse.set(res);
        }, 300);
      },
      { allowSignalWrites: true },
    );

    effect(
      async () => {
        const code = this.enteredCode();
        if (!code) return;

        try {
          await this.authService.initMfaEnroll(this.enrollId()!, {
            type: authType,
            code,
          });

          this.msg.showInfoSnackbar(await firstValueFrom(tr('mfa_totp_enroll_success_msg')));
          this.afterAdded.emit();
          this.dialogRef.close();
        } catch (error) {
          this.msg.showErrorSnackbar(await firstValueFrom(tr('error')), error);
          this.enteredCode.set(null);
          this.pinInput.clear();
        }
      },
      { allowSignalWrites: true },
    );
  }

  private _readRect(element: HTMLElement, signal: WritableSignal<DOMRectReadOnly | null>) {
    const resizeObserver = new ResizeObserver((e) => setTimeout(() => signal.set(e[0].contentRect)));
    resizeObserver.observe(element);
  }

  ngAfterViewInit(): void {
    this._viewInit.set(true);

    this._scaleQr(this.elementRef.nativeElement.getBoundingClientRect(), this.bottom.nativeElement.getBoundingClientRect());

    this._readRect(this.elementRef.nativeElement, this.hostRect);
    this._readRect(this.bottom.nativeElement, this.bottomRect);
  }

  private _scaleQr(rect: DOMRectReadOnly, bottomRect: DOMRectReadOnly) {
    const bottomHeight = bottomRect.height;

    this.qrSize.set(Math.min(rect.width, rect.height - bottomHeight - 16));
  }

  private async _showQr(settings: TotpQrSettings) {
    const issuer = encodeURIComponent(settings.issuer);

    const accName = await settings.accountName;
    const accountName = accName ? encodeURIComponent(accName) : undefined;

    const params = {
      issuer,
      secret: settings.secret,
      digits: settings.digits,
      algorithm: settings.algorithm,
      period: settings.period,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(params)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => [key, value!.toString()]),
    );

    const label = accountName ? `${issuer}:${accountName}` : issuer;
    const strParams = new URLSearchParams(filteredParams).toString();
    const uri = `otpauth://${settings.type}/${label}?${strParams}`;

    try {
      await QRCode.toCanvas(this.qrCode.nativeElement, uri, {
        width: this.qrSize(),
      });
    } catch (error) {
      console.error('QR code render error', error);
      //TODO: show error in GUI
    }
  }
}
