import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormMode, RcgFieldType, SelectOption } from '@rcg/core';
import { MessageService } from '@rcg/standalone';
import * as dot from 'dot-object';
import { Subscription, catchError, debounceTime, distinctUntilChanged, of, startWith, switchMap, tap } from 'rxjs';
import { FieldAction } from '../../../models';
import { FieldsActionsService } from '../../../services';
import { FormDialogService } from '../../../services/form-dialog.service';
import { SupportedForm } from '../../../utils/supported-forms-utils';
import { ACSettings } from '../models/ac-settings';
import { AutocompleteService } from '../services/ac.service';

type BadgeAction = {
  formId: SupportedForm | undefined;
  formRecordId: number | undefined;
  title: string | undefined;
  formmode: FormMode | undefined;
};
export class MultiSelectSettings extends ACSettings {
  badgeAction: BadgeAction | undefined;

  constructor(formState: unknown, formFieldKey: string, settings: Record<string, unknown> | undefined) {
    super(formState, formFieldKey, settings);

    this.badgeAction = settings?.badgeAction as BadgeAction | undefined;
  }
}

// TODO:  handle loading, error for initial values, error for autocomplete
@Component({
  selector: 'rcg-autocomplete-multiselect-field',
  templateUrl: './autocomplete-multiselect-field.component.html',
  styleUrls: ['./autocomplete-multiselect-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AutocompleteService],
})
export class AutocompleteMultiselectFieldComponent extends RcgFieldType implements OnInit, OnDestroy {
  @ViewChild('input', { static: false }) input!: ElementRef<HTMLInputElement>;

  selectedOptions: SelectOption[] = [];
  autocompleteOptions: SelectOption[] = [];
  errorMessage: string | null = null;
  isLoading = false;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [13, 188]; // enter,comma
  addOnBlur = true;
  inputFormControl = new UntypedFormControl();
  acSettings!: MultiSelectSettings;

  private inputSubscription?: Subscription;
  private formControlSubscription?: Subscription;

  private get isDisabledOrReadOnly() {
    return this.props.disabled === true || this.props.readonly === true;
  }

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  get allowHintValues() {
    return this.acSettings?.allowHintValues === true;
  }

  validHintName = ' Uporabi ta naslov';

  validatorRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  actions: FieldAction[] = [];

  ignoreValueChangesOnSelected = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private formDialogService: FormDialogService,
    private acService: AutocompleteService,
    private fieldsActionsService: FieldsActionsService,
    private messageService: MessageService,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  ngOnInit(): void {
    try {
      this.acSettings = new MultiSelectSettings(this.formState, this.field.key as string, this.props?.['settings']);
    } catch (error) {
      this.errorMessage = 'Wrong autocomplete multiselect field settings: ' + (error as Error | undefined)?.message ?? '';
      this.changeDetector.markForCheck();
      return;
    }

    this.actions = this.acSettings.actions;

    // set inital values
    this.selectedOptions = this.getInitialValues(this.acSettings);
    this.setFieldValue(this.selectedOptions, false);

    this.subscribeToInputValuChanges();
    if (this.acSettings.clearChipsOnEmptyFormFieldValue === true) {
      this.clearChipsOnEmptyFormFieldValue();
    }

    this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((val) => {
      if (!this.ignoreValueChangesOnSelected) {
        const values = val as unknown[];
        this.selectedOptions = values as SelectOption[];
        this.changeDetector.markForCheck();
      } else {
        this.ignoreValueChangesOnSelected = false;
      }
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.inputSubscription?.unsubscribe();
    this.formControlSubscription?.unsubscribe();
  }

  remove(item: SelectOption) {
    if (this.isDisabledOrReadOnly) return;

    this.selectedOptions = this.selectedOptions.filter((o) => o.value !== item.value);
    this.setFieldValue(this.selectedOptions);
  }

  add(event: MatChipInputEvent): void {
    const value = (event?.value || '').trim();
    if (!value) {
      return;
    }

    if (this.allowHintValues && !this.alreadySelected(value) && this.validatorRegex.test(value)) {
      const newOption: SelectOption = {
        value: value,
        label: value,
        id: null,
      };

      this.selectedOptions = [...this.selectedOptions, newOption];
      this.setFieldValue(this.selectedOptions);
    }

    event.chipInput!.clear();
    this.clearInput();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.isDisabledOrReadOnly) return;
    const selected = event?.option?.value as SelectOption;

    // if empty or already selected clear input
    if (!selected || !selected?.value || this.alreadySelected(selected.value)) {
      this.clearInput();
      return;
    }

    // ok - add to selectedOptions values and clear input
    this.selectedOptions = [...this.selectedOptions, this.getValueForField(selected)];

    this.ignoreValueChangesOnSelected = true;

    this.setFieldValue(this.selectedOptions, false);
    this.clearInput();
    this.formCtrl.markAsDirty();
  }

  getValueForField(selected: SelectOption): SelectOption {
    return {
      id: selected.id,
      value: selected.value,
      label: this.acSettings.selectedLabel && selected.selectedValueName ? selected.selectedValueName : selected.label,
      data: selected.data,
    } as SelectOption;
  }

  async addNewContact(): Promise<void> {
    if (this.isDisabledOrReadOnly) return;

    this.formDialogService.openForm({
      formId: SupportedForm.contact,
      formMode: 'insert',
      dialogTitle: 'Dodaj kontakt',
      onSubmitSuccessAction: ({ data }) => {
        try {
          const id = data?.id;
          if (!(typeof id === 'number')) return;
          if (!this.acSettings.valueName || !this.acSettings?.selectedLabel) return;

          const value = data[this.acSettings.valueName!];
          const label = this.acSettings.selectedLabel ? data[this.acSettings.selectedLabel!] : data?.long_name ?? data?.email;
          if (!value || !label) return;

          if (!this.alreadySelected(value)) {
            const select = {
              value: value,
              label: label,
              id: data.id,
            } as SelectOption;
            this.selectedOptions = [...this.selectedOptions, select];
            this.setFieldValue(this.selectedOptions);
            this.changeDetector.markForCheck();
          }
        } catch (error) {
          console.error('Set participants error', error ?? error);
        }
      },
    });
  }

  badgeActionClick(item: SelectOption) {
    if (this.acSettings.preventOpenDetailDialog === true) return;

    const action = this.acSettings.badgeAction;

    const recordId = action?.formRecordId ?? item?.id;
    const formId = action?.formId ?? SupportedForm.contact; // default is contact
    const formMode = action?.formmode ?? 'readOnly';
    const title = action?.title ?? 'Podrobnosti kontakta';

    if (recordId && formId) {
      {
        this.formDialogService.openForm({
          formId: formId,
          formrecordId: recordId,
          formMode: formMode,
          dialogTitle: title,
        });
      }
    }
  }

  private getInitialValues(settings: ACSettings): SelectOption[] {
    if (settings.initalValueFromModel) {
      if (!this.acSettings.fieldName) {
        throw new Error(`Field ${this.acSettings.fieldName} not exists in settings`);
      }
      const values: SelectOption[] =
        (this.model[this.acSettings.fieldName] as Record<string, unknown>[])?.map((m) => ({
          value: dot.pick(settings.initalValueFromModel.value as string, m),
          label: dot.pick(settings.initalValueFromModel.label as string, m),
          id: dot.pick(settings.initalValueFromModel.value as string, m),
          data: (settings.initalValueFromModel.data as string | undefined) ? dot.pick(settings.initalValueFromModel.data!, m) : null,
        })) ?? [];

      return values.filter((d) => !!d?.value);
    }

    const fieldData: SelectOption[] =
      ((settings.fieldName
        ? this.model[settings.fieldName] || this.model[this.field.key as string]
        : this.model[this.field.key as string]) as SelectOption[] | undefined) ?? [];

    if (!fieldData || fieldData.length === 0) {
      return [];
    }

    return fieldData.filter((d) => !!d?.value);
  }

  private subscribeToInputValuChanges() {
    if (this.isDisabledOrReadOnly) return;

    this.inputSubscription = this.inputFormControl.valueChanges
      .pipe(
        startWith(null),
        debounceTime(this.acSettings.searchSettings.searchDebounceTime),
        distinctUntilChanged(),
        tap(() => {
          this.errorMessage = '';
          this.isLoading = true;
          this.changeDetector.markForCheck();
        }),
        switchMap((val) => {
          if (typeof val !== 'string') return of([]);
          const search = val.trim();

          if (search === '' && this.acSettings.ignoreEmptySearch) return of([]);

          return this.acService.getAutoCompleteData(this.acSettings, this.model, search).pipe(
            catchError((err) => {
              this.errorMessage = err?.message ?? err.toString() ?? 'Napaka';
              this.autocompleteOptions = [];
              this.isLoading = false;
              this.changeDetector.markForCheck();
              return of([]);
            }),
          );
        }),
      )
      .subscribe({
        next: (data) => {
          this.autocompleteOptions = data;
          this.isLoading = false;
          this.changeDetector.markForCheck();
        },
      });
  }

  private clearInput(): void {
    this.input.nativeElement.value = '';
    this.inputFormControl.setValue(null);
  }

  private alreadySelected(value: string | number): boolean {
    return this.selectedOptions.findIndex((s) => s.value === value) !== -1;
  }

  private clearChipsOnEmptyFormFieldValue() {
    this.formControlSubscription = this.formCtrl.valueChanges.pipe(startWith(this.value), distinctUntilChanged()).subscribe({
      next: (value) => {
        if (!value || (Array.isArray(value) && value.length === 0)) {
          this.selectedOptions = [];
          this.changeDetector.markForCheck();
        }
      },
    });
  }

  private setFieldValue(values: SelectOption[] | null | undefined, markAsDirty = true): void {
    if (!values || values.length === 0) {
      this.value = [];
      if (markAsDirty) {
        this.formCtrl.markAsDirty();
      }
      return;
    }

    const fieldValues = values.map(
      (v) =>
        ({
          id: v.id,
          value: v.value,
          label: v.label,
          data: v.data ?? null,
        } as SelectOption),
    );
    this.value = fieldValues;

    if (markAsDirty) {
      this.formCtrl.markAsDirty();
    }
  }

  async executeAction(action: FieldAction) {
    await this.fieldsActionsService.exececuteFieldAction(action, this.model, this.field, this.formCtrl.value, this.form, (input) => {
      try {
        action.afterExecuteAction(input);
        this.formCtrl.markAsDirty();
        this.changeDetector.markForCheck();
      } catch (error) {
        this.messageService.showErrorSnackbar(
          'Error form field action after submit',
          (error as { message: string | undefined })?.message ?? 'Unknown error',
        );
      }
    });
  }
}
