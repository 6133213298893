import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { GraphQLClient, GraphQLQuery } from '@npm-libs/ng-templater';
import { AuthService, OrganizationShareType, RcgTenant, RcgUser } from '@rcg/auth';
import { GraphqlClientService } from '@rcg/graphql';
import { tr } from '@rcg/intl';
import { gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';

export interface FormGlobalVarsServiceOptions {
  locale$?: Observable<string>;
}

export const FORM_GLOBAL_VARS_SERVICE_OPTIONS = new InjectionToken<FormGlobalVarsServiceOptions>('FormGlobalVarsServiceOptions');

@Injectable({
  providedIn: 'root',
})
export class FormGlobalVarsService {
  selectedTenantId: number | undefined;

  constructor(
    @Inject(FORM_GLOBAL_VARS_SERVICE_OPTIONS) @Optional() private options: FormGlobalVarsServiceOptions | null,
    private authService: AuthService,
    private gqlClient: GraphqlClientService,
  ) {}

  get globalVars(): Promise<Record<string, unknown>> {
    return (async () => ({
      user: this.getUser(),
      tenant: this.authService.tenant(),
      OrganizationShareType,
      gqlClient: {
        query: (query: GraphQLQuery) =>
          this.gqlClient.query({
            variables: query.variables,
            query: gql(query.query),
          }),
        mutate: (query: GraphQLQuery) =>
          this.gqlClient.mutate({
            variables: query.variables,
            mutation: gql(query.query),
          }),
        subscribe: (query: GraphQLQuery) =>
          this.gqlClient.subscribe({
            variables: query.variables,
            query: gql(query.query),
          }),
      } as GraphQLClient,
      tr: tr,
      locale$: this.options?.locale$ ?? of('sl_SI'),
    }))();
  }

  private getUser() {
    const as = this.authService.authState();
    const user = as.hasUser() ? as.user : null;

    if (!user?.id) {
      throw new Error('Link user to form error. No app user');
    }

    const tenant = as.hasTenant() ? as.tenant : null;

    if (!tenant?.id) {
      throw new Error('Link user to form error. No selected tenant');
    }
    if (!tenant?.organization?.id) {
      throw new Error('Link user to form error. No organization');
    }

    //FIXME: update forms user model to the correct one
    const partialUser: Partial<RcgUser & { selectedTenant: RcgTenant; hasuraUserId: number }> = {
      id: user.id,
      selectedTenant: tenant,
      fullName: user.fullName,
      firstName: user.firstName,
      lastName: user.lastName,
      hasuraUserId: user.id,
      email: user.email,
    };
    return partialUser;
  }

  async hasTenantChanged(): Promise<boolean> {
    const tenant = this.authService.tenant();
    if (!tenant?.id) {
      throw new Error('Check tenant changed - No selected tenant id');
    }

    if (this.selectedTenantId === undefined) {
      this.selectedTenantId = tenant.id;
      return false;
    }

    if (this.selectedTenantId !== tenant.id) {
      this.selectedTenantId = tenant.id;
      return true;
    }

    return false;
  }
}
