<div class="sticky-header" *ngIf="balancesSumData() as balances">
  <div style="display: flex; flex-direction: column">
    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
      <span style="flex-grow: 1; margin-right: 4px">{{ 'all_accounts_receivables' | intl }}</span>
      <div style="display: flex; align-items: center; white-space: nowrap">
        <mat-icon style="font-size: 14px; height: 14px; width: 14px; margin-right: 2px">functions</mat-icon>
        <span style="font-weight: 500">{{ balances?.allBalances ?? 0 | currency : 'EUR' }}</span>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; color: red">
      <span style="flex-grow: 1; margin-right: 4px">{{ 'overdue_accounts_receivable' | intl }}</span>
      <div style="display: flex; align-items: center; white-space: nowrap">
        <mat-icon style="font-size: 14px; height: 14px; width: 14px; margin-right: 2px">functions</mat-icon>
        <span style="font-weight: 500">{{ balances?.dueBalances ?? 0 | currency : 'EUR' }}</span>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="infinityListService.listStateR.value$ | async as state">
  <rcg-virtual-list
    *ngIf="state.data?.length; else noDataTemplate"
    [loading]="state.loading"
    [error]="state.error"
    [paging]="state.paging!"
    [itemSize]="100"
    [data]="state.data"
    [itemTemplate]="devices"
    [wrapInCard]="false"
    (loadMore)="infinityListService.loadMore($event)"
    [route]="'./detail'"
  >
  </rcg-virtual-list>
  <ng-template #devices let-data="data">
    <mat-card *ngIf="data" appearance="outlined" style="display: flex; height: 100%">
      <div style="display: flex; justify-content: space-between">
        <div>{{ data?.document_no ?? '' }}</div>
        <div>{{ data?.document_date | date : 'dd. MM. yy' }}</div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <div>{{ data?.document_due | date : 'dd. MM. yy' }}</div>
        <div
          [ngStyle]="{
            color: (data?.document_due | isDueDate) ? 'red' : 'black',
            'font-weight': '500'
          }"
          style="font-weight: 500"
        >
          {{ data?.balance | currency : 'EUR' }}
        </div>
      </div>
    </mat-card>
  </ng-template>
</ng-container>
<ng-template #noDataTemplate>
  <div class="no-data">
    <mat-icon>payments</mat-icon>
    <span>{{ 'no_data' | intl }}</span>
  </div>
</ng-template>
