import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { TypeOption } from '@ngx-formly/core/lib/models';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { ContainerViewComponent } from '@npm-libs/ng-templater';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { EditorModule } from '@progress/kendo-angular-editor';
import { LabelModule } from '@progress/kendo-angular-label';
import { NotificationModule, RcgPipesModule } from '@rcg/core';
import { IntlModule } from '@rcg/intl';
import {
  DynamicDialogComponent,
  ModuleSearchComponent,
  OtpPinInputComponent,
  RcgDialogComponent,
  RcgDirectivesModule,
  RcgVirtualListComponent,
} from '@rcg/standalone';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { SignatureModule } from '@syncfusion/ej2-angular-inputs';
import { AccountsReceivablesComponent } from './components/accounts-receivable/accounts-receivables.component';
import { ContactEditDialogComponent } from './components/contact-edit-dialog/contact-edit-dialog.component';
import { FormlyDebugComponent } from './components/formly-debug/formly-debug.component';
import { OrganizationsDetailDialogComponent } from './components/organizations-detail-dialog/organizations-detail-dialog.component';
import { OrganizationsDetailComponent } from './components/organizations-detail/organizations-detail.component';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import { ThumbnailsContainerComponent } from './components/thumbnails-container/thumbnails-container.component';
import { AttachmentsViewerDialogComponent } from './containers/attachments-viewer-dialog/attachments-viewer-dialog.component';
import { EditorDialogComponent } from './containers/editor-dialog/editor-dialog.component';
import { FormlyCustomFormDialogComponent } from './containers/formly-custom-form-dialog/formly-custom-form-dialog.component';
import { FormlyCustomFormComponent } from './containers/formly-custom-form/formly-custom-form.component';
import { FormlyFormDialogComponent } from './containers/formly-form-dialog/formly-form-dialog.component';
import { FormlyFormComponent } from './containers/formly-form/formly-form.component';
import { AttachmentsFieldComponent } from './fields/attachments-field/attachments-field.component';
import { AutocompleteFieldComponent } from './fields/autocomplete/autocomplete-field/autocomplete-field.component';
import { AutocompleteMultiselectFieldComponent } from './fields/autocomplete/autocomplete-multiselect-field/autocomplete-multiselect-field.component';
import { AutocompleteTreeFieldComponent } from './fields/autocomplete/autocomplete-tree-field/autocomplete-tree-field.component';
import { HintFieldComponent } from './fields/autocomplete/hint-field/hint-field.component';
import { ChipsComponent } from './fields/chips/chips.component';
import { CurrencyFieldComponent } from './fields/currency/currency-field.component';
import { CurrencyFormFieldComponent } from './fields/currency/currency-form-field';
import { DatePickerFieldComponent } from './fields/date-picker/date-picker.component';
import { DateTimeInputDoubleComponent } from './fields/datetime-input-double/datetime-input-double.component';
import { DatetimePickerFieldComponent } from './fields/datetime-picker-field/datetime-picker-field.component';
import { GroupEditorFieldComponent } from './fields/group-editor/group-editor.component';
import { HeadingFieldComponent } from './fields/heading-field/heading-field.component';
import { ContactsSuggestorComponent } from './fields/html-editor-field/contacts-suggestor/contacts-suggestor.component';
import { HtmlEditorFieldComponent } from './fields/html-editor-field/html-editor-field.component';
import { HtmlEditorIntlComponent } from './fields/html-editor-field/html-editor-intl/html-editor-intl.component';
import { HtmlEditorComponent } from './fields/html-editor-field/html-editor/html-editor.component';
import { KnowledgebaseSuggestorComponent } from './fields/html-editor-field/knowledgebase-suggestor/knowledgebase-suggestor.component';
import { IconPickerFieldComponent } from './fields/icon-picker-field/icon-picker-field.component';
import { LocationComponent } from './fields/location/location.component';
import { AddMfaDialogComponent } from './fields/mfa-configurator/dialogs/add-mfa/add-mfa-dialog.component';
import { RecoveryCodeMfaAdderComponent } from './fields/mfa-configurator/dialogs/add-mfa/adders/recovery-code/recovery-code-adder.component';
import { SmsMfaAdderComponent } from './fields/mfa-configurator/dialogs/add-mfa/adders/sms/sms-adder.component';
import { TotpMfaAdderComponent } from './fields/mfa-configurator/dialogs/add-mfa/adders/totp/totp-adder.component';
import { ModifyMfaDialogComponent } from './fields/mfa-configurator/dialogs/modify-mfa/modify-mfa-dialog.component';
import { MFAConfiguratorComponent } from './fields/mfa-configurator/mfa-configurator.component';
import { MultiselectFieldComponent } from './fields/multiselect/multiselect-field.component';
import { PasswordChangeInputComponent } from './fields/password-change-input/password-change-input.component';
import { PhoneComponent } from './fields/phone/phone.component';
import { RatingFieldComponent } from './fields/rating-field/rating-field.component';
import { RatingComponent } from './fields/rating-field/rating/rating.component';
import { RecurrenceEditorFieldComponent } from './fields/recurrence-editor/recurrence-editor.component';
import { RcgSelectFieldComponent } from './fields/select/select.component';
import { SignatureFieldComponent } from './fields/signature-field/signature-field.component';
import { SortFieldComponent } from './fields/sort-field/sort-field.component';
import { StatusFieldComponent } from './fields/status-field/status-field.component';
import { TemplaterTemplateComponent } from './fields/templater-template/templater-template.component';
import { TextAreaFieldComponent } from './fields/text-area-field/text-area-field.component';
import { TimeFieldComponent } from './fields/time-field/time-field.component';
import { TimeInputDoubleComponent } from './fields/time-input-double/time-input-double.component';
import { TimeSpentFieldComponent } from './fields/time-spent-field/time-spent-field.component';
import { TimeSpentComponent } from './fields/time-spent-field/time-spent/time-spent.component';
import { FieldActionsWrapperComponent } from './form-layouts/field-actions-wrapper.component';
import { RcgFieldsWrapperComponent } from './form-layouts/fields-wrapper/fields-wrapper.component';
import { FormContainerWrapperComponent } from './form-layouts/form-container-wrapper/form-container-wrapper.component';
import { RcgFormTabsComponent } from './form-layouts/form-tabs/form-tabs.component';
import { RcgSingleFormComponent } from './form-layouts/single-form/single-form.component';
import { RcgFormStepperComponent } from './form-layouts/stepper/stepper.component';
import { OpenFormActionRegistrationService } from './open-form-templater-action';
import { AttachmentsFilterPipe } from './pipes/attachments-filter.pipe';
import { ChipsTooltipPipe } from './pipes/chips-tooltip';
import { EditorContentPipe } from './pipes/editor-content.pipe';
import { VisibleFieldActionsPipe } from './pipes/field-actions.pipe';
import { ShowNoDataPipe, ValidInputValuePipe } from './pipes/multiselect.pipe';
import { TooltipPipe } from './pipes/tooltip.pipe';
import { FormDialogService } from './services/form-dialog.service';
import {
  RcgValidationMessages,
  currencyValidatorMessage,
  emailValidatorMessage,
  maxAllowedAttachmentsValidatorMessage,
  maxLengthValidationMessage,
  minLengthValidationMessage,
} from './validators/validation.messages';
import {
  contactFirstNameLastNameValidator as contactNameValidator,
  currencyValidator,
  emailValidator,
  emailValidatorAllowEmpty,
  invalidAcObjectValidator,
  maxAllowedAttachmentsValidator,
  minTimeSeconds,
  requiredFieldValidator,
  workingHoursDateRangeValidator,
} from './validators/validators';
import { MultiLanguageFieldWrapperComponent } from './wrappers/multi-language/multi-language.component';

@NgModule({
  declarations: [
    AutocompleteFieldComponent,
    AutocompleteTreeFieldComponent,
    FormlyFormComponent,
    FormlyFormDialogComponent,
    FormlyDebugComponent,
    AttachmentsFieldComponent,
    ChipsComponent,
    TimeSpentFieldComponent,
    ThumbnailComponent,
    ThumbnailsContainerComponent,
    AttachmentsViewerDialogComponent,
    StatusFieldComponent,
    AutocompleteMultiselectFieldComponent,
    HtmlEditorComponent,
    HtmlEditorIntlComponent,
    KnowledgebaseSuggestorComponent,
    ContactsSuggestorComponent,
    EditorDialogComponent,
    HtmlEditorFieldComponent,
    TextAreaFieldComponent,
    EditorContentPipe,
    FormlyCustomFormComponent,
    FormlyCustomFormDialogComponent,
    RcgSingleFormComponent,
    RcgFormTabsComponent,
    RcgFieldsWrapperComponent,
    RcgFormStepperComponent,
    DatetimePickerFieldComponent,
    SignatureFieldComponent,
    HintFieldComponent,
    RatingFieldComponent,
    DatePickerFieldComponent,
    LocationComponent,
    PhoneComponent,
    TimeSpentComponent,
    TimeFieldComponent,
    DateTimeInputDoubleComponent,
    TimeInputDoubleComponent,
    HeadingFieldComponent,
    ValidInputValuePipe,
    ShowNoDataPipe,
    PasswordChangeInputComponent,
    MFAConfiguratorComponent,
    AddMfaDialogComponent,
    TotpMfaAdderComponent,
    SmsMfaAdderComponent,
    RecoveryCodeMfaAdderComponent,
    ModifyMfaDialogComponent,
    SortFieldComponent,
    OrganizationsDetailComponent,
    OrganizationsDetailDialogComponent,
    ContactEditDialogComponent,
    ChipsTooltipPipe,
    VisibleFieldActionsPipe,
    IconPickerFieldComponent,
    RcgSelectFieldComponent,
    MultiselectFieldComponent,
    FieldActionsWrapperComponent,
    AttachmentsFilterPipe,
    CurrencyFieldComponent,
    TooltipPipe,
    CurrencyFormFieldComponent,
  ],
  imports: [
    CommonModule,
    RcgDirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatListModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSelectModule,
    MatChipsModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    MatTabsModule,
    NgSelectModule,
    RcgPipesModule,
    MatMenuModule,
    EditorModule,
    MatStepperModule,
    DateTimePickerModule,
    RatingComponent,
    SignatureModule,
    RcgDialogComponent,
    NotificationModule,
    FormlyModule.forChild(),
    RecurrenceEditorFieldComponent,
    ContainerViewComponent,
    ScrollingModule,
    GroupEditorFieldComponent,
    IntlModule,
    DateInputsModule,
    LabelModule,
    RcgVirtualListComponent,
    ModuleSearchComponent,
    DynamicDialogComponent,
    MatSelectModule,
    MultiLanguageFieldWrapperComponent,
    OtpPinInputComponent,
    AccountsReceivablesComponent,
  ],
  exports: [
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyFormComponent,
    FormlyFormDialogComponent,
    FormlyCustomFormComponent,
    OrganizationsDetailComponent,
    OrganizationsDetailDialogComponent,
    ContactEditDialogComponent,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    FormDialogService,
    OpenFormActionRegistrationService,
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [OpenFormActionRegistrationService],
      multi: true,
    },
  ],
})
export class RcgFormsModule {
  public static forFormlyRoot(options?: { extraTypes?: TypeOption[] }): ModuleWithProviders<FormlyModule> {
    return FormlyModule.forRoot({
      extras: {
        lazyRender: true,
        immutable: true, // must be enabled (modelChange) on formly-form component if imutable true
        resetFieldOnHide: false, // if this is true (default) fields with a default model value will lose their value when hidden
      },
      wrappers: [
        { name: 'multi-language', component: MultiLanguageFieldWrapperComponent },
        { name: 'actions', component: FieldActionsWrapperComponent },
        { name: 'form-container', component: FormContainerWrapperComponent },
      ],
      types: [
        { name: 'fields', component: RcgFieldsWrapperComponent },
        { name: 'singleForm', component: RcgSingleFormComponent },
        { name: 'tabs', component: RcgFormTabsComponent },
        { name: 'templaterTemplate', component: TemplaterTemplateComponent },
        { name: 'stepper', component: RcgFormStepperComponent },
        { name: 'heading', component: HeadingFieldComponent },
        {
          name: 'timespent',
          component: TimeSpentFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'date-picker',
          component: DatePickerFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'datetimepicker',
          component: DatetimePickerFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'status',
          component: StatusFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'attachment',
          component: AttachmentsFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'autocomplete',
          component: AutocompleteFieldComponent,
          wrappers: ['form-field'],
          defaultOptions: { validators: { validation: ['invalidAcObject'] } },
        },
        {
          name: 'autocompletemultiselect',
          component: AutocompleteMultiselectFieldComponent,
          wrappers: ['form-field'],
          defaultOptions: { validators: { validation: ['invalidAcObject'] } },
        },
        {
          name: 'autocompleteTree',
          component: AutocompleteTreeFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'chips',
          component: ChipsComponent,
        },
        {
          name: 'plainText',
          component: TextAreaFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'htmleditor',
          component: HtmlEditorFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'signature',
          component: SignatureFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'hint',
          component: HintFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'rating',
          component: RatingFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'location',
          component: LocationComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'phone',
          component: PhoneComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'dateTimeInputDouble',
          component: DateTimeInputDoubleComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'timeInputDouble',
          component: TimeInputDoubleComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'rcgTime',
          component: TimeFieldComponent,
        },
        {
          name: 'passwordChangeInput',
          component: PasswordChangeInputComponent,
        },
        {
          name: 'mfaConfigurator',
          component: MFAConfiguratorComponent,
        },
        {
          name: 'sort',
          component: SortFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'recurrenceeditor',
          component: RecurrenceEditorFieldComponent,
        },
        {
          name: 'groupEditor',
          component: GroupEditorFieldComponent,
        },
        {
          name: 'iconPicker',
          component: IconPickerFieldComponent,
        },
        {
          name: 'rcgSelect',
          component: RcgSelectFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'multiselect',
          component: MultiselectFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'currency',
          component: CurrencyFieldComponent,
          wrappers: ['form-field'],
        },

        ...(options?.extraTypes ?? []),
      ],
      validators: [
        { name: 'invalidAcObject', validation: invalidAcObjectValidator },
        { name: 'requiredField', validation: requiredFieldValidator },
        { name: 'email', validation: emailValidator },
        { name: 'emailAllowEmpty', validation: emailValidatorAllowEmpty },
        {
          name: 'workingHoursDateRange',
          validation: workingHoursDateRangeValidator,
        },
        { name: 'min-time', validation: minTimeSeconds },
        { name: 'contact-name', validation: contactNameValidator },
        { name: 'currency', validation: currencyValidator },
        { name: 'maxAllowedAttachments', validation: maxAllowedAttachmentsValidator },
      ],
      validationMessages: [
        {
          name: 'invalidAcObject',
          message: RcgValidationMessages.invalidAcObject,
        },
        { name: 'requiredField', message: RcgValidationMessages.requiredField },
        { name: 'minLength', message: minLengthValidationMessage },
        { name: 'maxLength', message: maxLengthValidationMessage },
        { name: 'email', message: emailValidatorMessage },
        { name: 'emailAllowEmpty', message: emailValidatorMessage },
        { name: 'currency', message: currencyValidatorMessage },
        { name: 'maxAllowedAttachments', message: maxAllowedAttachmentsValidatorMessage },
      ],
      extensions: [],
    });
  }
}
