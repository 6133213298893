import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'isDueDate',
  standalone: true,
})
export class IsDueDatePipe implements PipeTransform {
  transform(date: string): boolean {
    return this.isDateOverdue(date) ?? false;
  }

  private isDateOverdue(date: string | undefined): boolean | null {
    try {
      const dt = this.tryParseDate(date);
      if (!dt) {
        return null;
      }

      const today = DateTime.now().startOf('day');
      return dt < today;
    } catch (e) {
      return null;
    }
  }

  private tryParseDate(date: string | undefined): DateTime | null {
    if (!date) return null;
    const parsedDate = DateTime.fromISO(date);
    return parsedDate.isValid ? parsedDate.startOf('day') : null;
  }
}
