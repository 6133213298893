import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MfaAuthType } from '@npm-libs/auth-manager';
import { RcgFieldType, RcgFormlyFieldProps } from '@rcg/core';
import { AddMfaDialogComponent, AddMfaDialogConfig } from './dialogs/add-mfa/add-mfa-dialog.component';
import { ModifyMfaDialogComponent } from './dialogs/modify-mfa/modify-mfa-dialog.component';

export interface MFAConfiguratorProps {
  hiddenButtons?: ('add' | 'modify')[];
  afterAdded?: () => void;
}

@Component({
  selector: 'rcg-mfa-configurator',
  templateUrl: './mfa-configurator.component.html',
  styleUrls: ['./mfa-configurator.component.scss'],
})
export class MFAConfiguratorComponent extends RcgFieldType<unknown, RcgFormlyFieldProps<MFAConfiguratorProps>> {
  private readonly dialog = inject(MatDialog);

  private _addMfa(width: string, height: string, type: MfaAuthType) {
    this.dialog.open(AddMfaDialogComponent, {
      width,
      height,
      data: {
        type,
        afterAdded: this.props.afterAdded?.bind(this),
      } as AddMfaDialogConfig,
    });
  }

  addTotpMfa() {
    this._addMfa('40%', '70%', 'OTP/RFC6238');
  }

  addSmsMfa() {
    this._addMfa('40%', '30%', 'OTP/SMS');
  }

  addRecoveryCodeMfa() {
    this._addMfa('500px', '350px', 'recovery/code');
  }

  modifyMfa() {
    this.dialog.open(ModifyMfaDialogComponent, {
      width: '40%',
      height: '70%',
    });
  }
}
