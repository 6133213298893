import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { OperationVariables, TypedDocumentNode, gql } from '@apollo/client/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AuthService } from '@rcg/auth';
import { FormConfig, FormDefinition, FormFieldsDefinition, formlyFieldsToObject } from '@rcg/core';
import { GraphqlClientService } from '@rcg/graphql';
import { Observable, firstValueFrom, from, map, of, switchMap, throwError } from 'rxjs';
import { getFieldValueFromPath } from '../utils/field-path-utils';
import { SupportedForm, getFormFields } from '../utils/supported-forms-utils';
import { FormGlobalVarsService } from './form-global-vars.service';
import { MapModelInitalValuesFunc, SubmitFormFunc } from './form-hooks';
import { FormValueConverterService } from './form-value-converter.service';

@Injectable({
  providedIn: 'root',
})
export class FormlyService {
  constructor(
    private graphQlClient: GraphqlClientService,
    private formValueConverter: FormValueConverterService,
    private formGlobalVarsService: FormGlobalVarsService,
    private auth: AuthService,
  ) {}

  getFormDefinitionWithData(formConfig: FormConfig): Observable<FormDefinition> {
    const { formId: form, formFields, formOptions } = formConfig;

    if ((!formFields || formFields.length === 0) && !formOptions && !form) {
      return throwError(() => 'No form id or form fields are set. Set either form id or form fields and form options.');
    }

    return from(this._getFieldsDefinition(formConfig)).pipe(switchMap((def) => this._getFormDefinitionWithData(formConfig, def)));
  }

  async insertForm(
    formModel: Record<string, unknown>,
    fields: FormlyFieldConfig[],
    formOptions: FormlyFormOptions,
    form: UntypedFormGroup,
    parentFormId?: number | null,
    formId?: SupportedForm | string,
  ) {
    const submitFields = this._excludeFieldsForSubmit(formOptions, [...fields], form, 'insert');

    // custom insert variables function and submit form
    if (formOptions.formState?.insertVariables) {
      if (typeof formOptions.formState!.insertVariables !== 'function') {
        throw new Error('Setting insertVariables must be function');
      }
      const vars = await formOptions.formState!.insertVariables(formModel, form, parentFormId);

      const result = await firstValueFrom(
        this.graphQlClient.mutate({
          mutation: gql(formOptions.formState!.insert_form),
          variables: vars,
        }),
      );

      // check insert result
      if (formOptions.formState?.insertAfterSubmit) {
        if (typeof formOptions.formState!.insertAfterSubmit !== 'function') {
          throw new Error('Setting insertAfterSubmit must be function');
        }
        formOptions.formState!.insertAfterSubmit(result);
      }
      return result;
    }

    // sync model from form values
    if (formOptions.formState?.syncModelFromFormOnInsert === true) {
      for (const f of submitFields) {
        // eslint-disable-next-line no-prototype-builtins
        if (!formModel.hasOwnProperty(f.key as string) && form.contains(f.key as string)) {
          formModel[f.key as string] = form.get(f.key as string)?.value;
        }
      }
    }

    const fieldsObject = formlyFieldsToObject(submitFields);

    let variables = this.formValueConverter.getFormValuesForInsert(formOptions, formModel, fieldsObject, parentFormId);

    // handle custom  submit form in formly form libs
    if (formOptions?.formState?.submitForm && typeof formOptions.formState.submitForm === 'function') {
      const formSubmit: SubmitFormFunc = formOptions.formState.submitForm;
      return await formSubmit('insert', formModel, form, fields, variables);
    }

    if (formOptions.formState?.mapSubmitVariables) {
      if (typeof formOptions.formState!.mapSubmitVariables !== 'function') {
        throw new Error('The mapSubmitVariables setting must be a function if it is specified');
      }

      variables = formOptions.formState!.mapSubmitVariables(variables, {
        formMode: 'insert',
        formModel,
        fields,
        formOptions,
        form,
        parentFormId,
        formId,
      });
    }

    const insertFormMutation = formOptions?.formState?.insert_form;
    if (!insertFormMutation) {
      throw new Error('Insert form mutation ni nastavljen v form settings');
    }

    // Insert with hasura api
    if (formOptions.formState?.hasura_api_insert === true) {
      const tenant = this.auth.tenant();
      const hasuraApiVars = {
        form_id: formId,
        form: { ...variables },
        id: 0,
        user_tenant_id: tenant?.id,
        user_organization_id: tenant?.organization.id,
      };

      const gqlResponse = await firstValueFrom(
        this.graphQlClient.mutate({
          mutation: gql(insertFormMutation),
          variables: {
            updateData: hasuraApiVars,
          },
        }),
      );
      return gqlResponse;
    }
    const gqlResponse = await firstValueFrom(
      this.graphQlClient.mutate({
        mutation: gql(insertFormMutation),
        variables: variables,
      }),
    );

    return gqlResponse;
  }

  async updateForm(
    formRecordId: number,
    formModel: Record<string, unknown>,
    fields: FormlyFieldConfig[],
    formOptions: FormlyFormOptions,
    form: UntypedFormGroup,
    formId: SupportedForm | string,
  ) {
    const submitFields = this._excludeFieldsForSubmit(formOptions, [...fields], form, 'update');

    const updateFormMutation = formOptions?.formState?.update_form;
    if (!updateFormMutation) {
      throw new Error('Update form mutation ni nastavljen v form settings');
    }

    // custom update variables function and submit form
    if (!!formOptions.formState?.updateVariables && typeof formOptions.formState!.updateVariables === 'function') {
      const vars = await formOptions.formState!.updateVariables(formModel, form, formRecordId);

      const result = await firstValueFrom(
        this.graphQlClient.mutate({
          mutation: gql(updateFormMutation),
          variables: vars,
        }),
      );

      // check update result
      if (formOptions.formState?.updateAfterSubmit) {
        if (typeof formOptions.formState!.updateAfterSubmit !== 'function') {
          throw new Error('Setting updateAfterSubmit must be function');
        }
        formOptions.formState!.updateAfterSubmit(result);
      }
      return result;
    }

    // additional form update variables from settings
    const varsSetting = formOptions.formState?.updateVariables;
    const modelVars: Record<string, unknown> = {};
    if (varsSetting && Object.keys(varsSetting).length > 0) {
      for (const [key, value] of Object.entries(varsSetting)) {
        modelVars[key] = getFieldValueFromPath(formModel, value as string);
      }
    }
    const additionalUpdateVars = modelVars && Object.keys(modelVars).length > 0 ? modelVars : {};

    const formFields = formlyFieldsToObject(submitFields);

    const updateValues = this.formValueConverter.getFormValuesForUpdate(formOptions, formModel, formFields, formRecordId);

    let gqlVars = {
      ...updateValues.singleRelationValues,
      ...additionalUpdateVars,
    };

    // handle custom  submit form in formly form libs
    if (formOptions?.formState?.submitForm && typeof formOptions.formState.submitForm === 'function') {
      const formSubmit: SubmitFormFunc = formOptions.formState.submitForm;
      return await formSubmit('update', formModel, form, fields, gqlVars);
    }

    if (formOptions.formState?.mapSubmitVariables) {
      if (typeof formOptions.formState!.mapSubmitVariables !== 'function') {
        throw new Error('The mapSubmitVariables setting must be a function if it is specified');
      }

      gqlVars = formOptions.formState!.mapSubmitVariables(gqlVars, {
        formMode: 'update',
        formModel,
        fields,
        formOptions,
        form,
        formId,
      });
    }

    // Update with hasura api
    if (formOptions.formState?.hasura_api_update === true) {
      const tenant = this.auth.tenant();

      const attachmentsVars =
        updateValues.attachments && updateValues.attachments.length > 0
          ? {
              attachments: updateValues.attachments.map((a) => {
                return { values: a.values, field: a.field.key };
              }),
            }
          : {};

      const hasuraApiVars = {
        form_id: formId,
        form: { ...gqlVars, ...attachmentsVars },
        id: formRecordId,
        user_tenant_id: tenant?.id,
        user_organization_id: tenant?.organization.id,
      };

      await firstValueFrom(
        this.graphQlClient.mutate({
          mutation: gql(updateFormMutation),
          variables: {
            updateData: hasuraApiVars,
          },
        }),
      );
      return;
    }

    // single relation update
    const gqlResponse = await firstValueFrom(
      this.graphQlClient.mutate<Record<string, unknown>>({
        mutation: gql(updateFormMutation),
        variables: gqlVars,
      }),
    );

    // update attachments fields to attachments tables
    const attachments = updateValues.attachments;

    if (attachments.length === 0) {
      return gqlResponse;
    }

    const attachmentsGqlResponses: Record<string, unknown> = {};

    const allAttachmentsIds = attachments
      .filter((a) => a.values && (a.values as unknown[]).length > 0)
      .map((a) => (a.values as { attachment_id: number }[]).map((v) => v['attachment_id']))
      .flat(1);

    // attachments fields with shared attachments table on hasura
    // currently supported only one shared attachment table per form
    const sharedAttachments = attachments.filter((a) => this.formValueConverter.hasFormSharedAttachments(a.field));

    if (sharedAttachments.length > 0) {
      // form can have only one shared attachments table on hasura
      let hasFormMultipeSharedAttachments = false;
      const firstName = sharedAttachments[0].field.props?.settings?.sharedAttachmentsFieldName.trim();
      for (const att of sharedAttachments) {
        if (att.field.props?.settings?.sharedAttachmentsFieldName.trim() !== firstName) {
          hasFormMultipeSharedAttachments = true;
          break;
        }
      }

      if (hasFormMultipeSharedAttachments) {
        throw new Error(
          'Form settings vsebuje  več različnih nastavitev shared attchments hasura tabel. Trenutno je podprta samo ena shared attachments tabela na form',
        );
      }
      // all shared attachments fields should have same mutation, get it from:
      // formOptions.formState.updateSharedAttachmentsMutation or first updateMutation from field settings
      const mutation =
        formOptions?.formState?.updateSharedAttachmentsMutation ??
        sharedAttachments[0]?.field.props?.['settings']?.relations?.update?.updateMutation;

      if (!mutation) {
        throw new Error('Attachments mutation setting ni nastavljen v props.settings.relations.update.updateMutation');
      }

      const updateData = sharedAttachments.map((u) => u.values).flat(1);

      const gqlAttachmentsResponse = await firstValueFrom(
        this.graphQlClient.mutate({
          mutation: gql(mutation),
          variables: {
            id: formRecordId,
            updateData: updateData,
            attachments: allAttachmentsIds,
          },
        }),
      );
      attachmentsGqlResponses['shared_attachments'] = gqlAttachmentsResponse;
    }

    // attachments fields with single attachment table on hasura
    const singleAttachments = attachments.filter((a) => !this.formValueConverter.hasFormSharedAttachments(a.field));

    if (singleAttachments.length > 0) {
      for (const attachment of singleAttachments) {
        const attachmentMutation = attachment?.field.props?.['settings']?.relations?.update?.updateMutation;

        if (!attachmentMutation) {
          throw new Error('Attachments mutation setting ni nastavljen v props.settings.relations.update.updateMutation');
        }

        // TODO: deleteOnlyOldAttachments on attachments settings -  temporary for legacy attachment form field schema
        const deleteOnlyOldAttachments = attachment.field?.props?.['settings']?.deleteOnlyOldAttachments === true ? true : false;

        if (deleteOnlyOldAttachments) {
          const gqlAttachmentsResponse = await firstValueFrom(
            this.graphQlClient.mutate({
              mutation: gql(attachmentMutation),
              variables: {
                id: formRecordId,
                updateData: attachment.values,
                attachments: allAttachmentsIds,
              },
            }),
          );
          attachmentsGqlResponses[attachment.field.key as string] = gqlAttachmentsResponse;
        } else {
          // delete all before update attachments
          const gqlAttachmentsResponse = await firstValueFrom(
            this.graphQlClient.mutate({
              mutation: gql(attachmentMutation),
              variables: {
                id: formRecordId,
                updateData: attachment.values,
              },
            }),
          );
          attachmentsGqlResponses[attachment.field.key as string] = gqlAttachmentsResponse;
        }
      }
    }

    return { ...gqlResponse, ...attachmentsGqlResponses };
  }

  async deleteFormRecord(mutation: TypedDocumentNode, variables?: OperationVariables) {
    if (!mutation) {
      throw new Error('Delete mutation argument is empty');
    }

    const result = await firstValueFrom(
      this.graphQlClient.mutate<{ data?: unknown }>({
        mutation: mutation,
        variables: variables,
      }),
    );
    return result?.data;
  }

  private async _getFieldsDefinition({ formId: form, formFields, formOptions }: FormConfig): Promise<FormFieldsDefinition> {
    let formFieldsDefinition: FormFieldsDefinition | null = null;

    if (formFields && formFields.length > 0 && formOptions) {
      formFieldsDefinition = {
        fields: formFields,
        options: formOptions,
      };
    } else {
      formFieldsDefinition = {
        ...(await getFormFields(form!, this.formGlobalVarsService)),
      };

      if (!formFieldsDefinition?.fields || formFieldsDefinition.fields.length === 0) {
        throw 'Form fields definition not set';
      }
      if (!formFieldsDefinition?.options) {
        throw 'Form options definition not set';
      }
    }

    return formFieldsDefinition;
  }

  // changes fields  template options with recursion
  private _changeFieldsprops(fields: FormlyFieldConfig[], optionValue: { [key: string]: unknown }, level = 5): FormlyFieldConfig[] {
    if (level < 0) {
      throw new Error('Maximum level exceeded for fieldGroup.');
    }

    if (!fields) {
      return fields;
    }

    return fields.map((field) => {
      const newField = { ...field };
      if (!newField) {
        return newField;
      }

      if (newField.fieldGroup && Array.isArray(newField.fieldGroup)) {
        return {
          ...newField,
          fieldGroup: this._changeFieldsprops(newField.fieldGroup, optionValue, level - 1),
        };
      }

      if (newField.props) {
        return {
          ...newField,
          props: {
            ...newField.props,
            ...optionValue,
          },
        };
      }

      return newField;
    });
  }

  private _getFormDefinitionWithData(
    { formMode, allowNullishQueryData, formrecordId, prefillData, prefilledGqlVariables }: FormConfig,
    formFieldsDefinition: FormFieldsDefinition,
  ): Observable<FormDefinition> {
    if (prefilledGqlVariables && Object.keys(prefilledGqlVariables).length > 0) {
      formFieldsDefinition.options!.formState!['prefilledGqlVariables'] = prefilledGqlVariables;
    }

    const mapModelInitalValues: MapModelInitalValuesFunc =
      formFieldsDefinition!.options?.formState?.mapModelInitalValues &&
      typeof formFieldsDefinition!.options.formState.mapModelInitalValues === 'function'
        ? formFieldsDefinition!.options?.formState?.mapModelInitalValues
        : undefined;

    // insert mode
    if (formMode === 'insert') {
      const model = prefillData ? prefillData : {};
      return of({
        formFieldsDefinition: formFieldsDefinition,
        model: mapModelInitalValues
          ? mapModelInitalValues({
              formMode: formMode,
              model: model,
              fields: formFieldsDefinition.fields,
              options: formFieldsDefinition.options,
              valueMapper: this.formValueConverter.getModelInitalValues,
            })
          : this.formValueConverter.getModelInitalValues(formFieldsDefinition!.options, model, formFieldsDefinition.fields),
      });
    }

    // update mode
    const query = formFieldsDefinition.options.formState?.data_query as string;
    const varsSetting = formFieldsDefinition.options.formState?.queryVariables;
    const additionalVars = varsSetting && Object.keys(varsSetting).length > 0 ? varsSetting : {};
    const variables = { id: formrecordId!, ...additionalVars };

    return this._getFormData(query, variables, allowNullishQueryData ?? false).pipe(
      map((data) => (!data && allowNullishQueryData ? {} : data)),
      map((data) => {
        if (!data) {
          throw new Error('Server response for form data model is empty');
        }

        const model = prefillData ? { ...data, ...prefillData } : data;
        return {
          formFieldsDefinition: formFieldsDefinition!,
          model: mapModelInitalValues
            ? mapModelInitalValues({
                formMode: formMode,
                model: model,
                fields: formFieldsDefinition.fields,
                options: formFieldsDefinition.options,
                valueMapper: this.formValueConverter.getModelInitalValues,
              })
            : this.formValueConverter.getModelInitalValues(formFieldsDefinition!.options, model, formFieldsDefinition.fields),
        };
      }),
    );
  }

  private _getFormData(query: string, variables: OperationVariables, allowNullishQueryData: boolean) {
    return this.graphQlClient
      .watchQuery<{ data?: Record<string, unknown> | Record<string, unknown>[] }>({
        query: gql(query),
        variables: variables,
      })
      .pipe(
        map((result) => {
          if (!result?.data) {
            if (allowNullishQueryData) return {};
            throw new Error('Form record not found');
          }
          if (Array.isArray(result.data)) {
            if (result.data.length === 0) {
              if (allowNullishQueryData) return {};
              throw new Error('Form record not found');
            }
            return result.data[0];
          }
          return result.data;
        }),
      );
  }

  private _excludeFieldsForSubmit(
    formOptions: FormlyFormOptions | null | undefined,
    fields: FormlyFieldConfig[],
    form: UntypedFormGroup,
    type: 'insert' | 'update',
  ): FormlyFieldConfig[] {
    let submitFields = [...fields];

    let len = 0;

    while (len < submitFields.length) {
      len = submitFields.length;

      for (const field of submitFields) {
        if (field.fieldGroup) {
          submitFields.push(...field.fieldGroup);
        }
      }

      submitFields = [...new Set(submitFields)];
    }

    const submitFieldsResult: FormlyFieldConfig[] = [];

    const excludeFields = formOptions?.formState?.[`${type}_exclude_fields`];
    const hasExcludedFields = excludeFields && excludeFields.length > 0;

    for (const field of submitFields) {
      const isExcluded = hasExcludedFields && excludeFields.includes(field.key as string);

      const preventSubmit = field.props?.ignoreSubmit === true;

      const preventSubmitOnHide =
        // eslint-disable-next-line no-prototype-builtins
        field.props?.ignoreSubmitOnHide === true && form.controls?.hasOwnProperty(field.key as string) === false;

      if (isExcluded || preventSubmit || preventSubmitOnHide) {
        continue;
      }

      submitFieldsResult.push(field);
    }

    return submitFieldsResult;
  }
}
