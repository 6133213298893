import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormConfig } from '@rcg/core';

export interface ContactEditDialogData {
  id?: number;
  prefillData?: Record<string, unknown>;
  closeOnSubmitSuccess?: boolean;
}

@Component({
  selector: 'rcg-contact-edit-dialog',
  templateUrl: './contact-edit-dialog.component.html',
  styleUrls: ['./contact-edit-dialog.component.scss'],
})
export class ContactEditDialogComponent {
  formConfig: FormConfig;

  constructor(public dialogRef: MatDialogRef<ContactEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ContactEditDialogData) {
    this.formConfig = {
      formId: 'contact',
      formMode: data.id ? 'update' : 'insert',
      formrecordId: data.id,
      prefillData: data.prefillData,
    };
  }

  submitSuccess(result: unknown) {
    if (this.data.closeOnSubmitSuccess) this.dialogRef.close(result);
  }
}
