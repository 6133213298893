import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { RcgFieldType } from '@rcg/core';

@Component({
  selector: 'rcg-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RcgFormStepperComponent extends RcgFieldType {
  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field.formControl!.valid;
    }

    return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : true;
  }
}
