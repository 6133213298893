/* 
  Utils function for resolving form and checking supported form 
*/

import { RcgTenant } from '@rcg/auth';
import { FormFieldsDefinition, TenantId } from '@rcg/core';
import {
  DynamicImport,
  getFormsVersion,
  loadDynamicJS,
  replaceImport,
  replaceVariableExports,
  transformDefaultExportToReturn,
} from '@rcg/utils';
import * as luxon from 'luxon';
import * as rxjs from 'rxjs';
import { FormGlobalVarsService } from '../services/form-global-vars.service';

export enum SupportedForm {
  // helpdesk
  rcg_helpdesk_agent = 159,
  rcg_reply_agent = 170,
  rcg_reply_end_user = 171,
  rcg_product_items = 275,
  rcg_project = 441,
  rcg_knowledgebase = 670,
  medilab_helpdesk_agent = 714,
  medilab_reply_agent = 715,
  medilab_reply_end_user = 716,
  medilab_project = 717,
  medilab_knowledgebase = 718,
  ncr_helpdesk_agent = 276,
  ncr_reply_agent = 356,
  ncr_product_items = 357,
  ncr_device = 833,
  bent_helpdesk_agent = 347,
  bent_reply_agent = 348,
  bent_product_items = 349,
  halcom_helpdesk_end_user = 437,
  halcom_reply_end_user = 436,
  halcom_knowledgebase = 634,
  halcom_ba_helpdesk = 795,
  halcom_ba_reply_agent = 793,
  halcom_ba_reply_end_user = 827,
  halcom_ba_knowledgebase = 796,
  sava_mkt_helpdesk_agent = 361,
  sava_mkt_reply_agent = 362,
  lancom_tehnika_helpdesk_agent = 363,
  lancom_tehnika_reply_agent = 364,
  lancom_tehnika_product_items = 365,
  lancom_tehnika_project = 442,
  lancom_infrastructure_helpdesk_agent = 564,
  lancom_infrastructure_reply_agent = 565,
  lancom_infrastructure_product_items = 567,
  lancom_infrastructure_project = 566,
  lancom_poslovna_informatika_helpdesk_agent = 710,
  lancom_poslovna_informatika_reply_agent = 711,
  lancom_poslovna_informatika_reply_end_user = 713,
  lancom_poslovna_informatika_project = 712,
  lancom_dms_helpdesk_agent = 719,
  lancom_dms_reply_agent = 720,
  lancom_dms_reply_end_user = 722,
  lancom_dms_project = 721,
  lancom_knowledgebase = 791,
  projektip_helpdesk_agent = 400,
  projektip_reply_agent = 401,
  projektip_reply_end_user = 529,
  projektip_product_items = 402,
  projektip_project = 443,
  projektip_sla = 454,
  projektip_close_ticket = 487,
  projektip_purchase_order = 453,
  projektip_take_ownership = 528,
  projektip_knowledgebase = 880,
  generaliInv_helpdesk_agent = 404,
  generaliInv_reply_agent = 403,
  generaliInv_reply_enduser = 439,
  interexport_redirect_event = 563,
  interexport_local_it_helpdesk_agent = 444,
  interexport_local_it_reply_agent = 445,
  interexport_local_it_reply_enduser = 446,
  interexport_local_it_project = 452,
  interexport_medicina_helpdesk_agent = 488,
  interexport_medicina_reply_agent = 489,
  interexport_medicina_reply_enduser = 491,
  interexport_medicina_project = 492,
  interexport_radiologija_helpdesk_agent = 874,
  interexport_radiologija_reply_agent = 873,
  interexport_radiologija_reply_enduser = 875,
  interexport_radiologija_project = 876,
  interexport_km_helpdesk_agent = 723,
  interexport_km_reply_agent = 724,
  interexport_km_product_items = 725,
  default_knowledge_base = 493,
  interexport_knowledge_base_localIT = 494,
  btc_helpdesk_agent = 447,
  btc_reply_agent = 448,
  btc_reply_enduser = 449,
  problems_default = 701,
  lookup_table_sla = 872,
  lookup_table_sla_detail = 877,
  // actions
  redirect_event = 353,
  take_ownership = 354,
  edit_cron = 355,
  move_communication_event = 570,
  move_helpdesk_between_tenants = 846,
  phone_call = 360,
  link_phone_call_to_existing_ticket = 635,
  bent_redirect_event = 636,
  contact = 530,
  admin_organization = 486,
  admin_device = 758,
  admin_tag = 792,
  admin_tenant_group = 883,
  signature = 637,
  kontron_helpdesk_agent = 671,
  kontron_reply_agent = 672,
  kontron_reply_end_user = 673,
  kontron_product_items = 675,
  kontron_project = 674,
  kontron_knowledgebase = 676,
  zzi_internalIT = 677,
  zzi_reply_agent = 678,
  admin_azure_app_settings = 490,
  exchange_oauth_email = 837,
  exchange_basic_credentials_email = 838,
  ad_settings = 835,
  pop3_email = 836,
  user = 885,
  // sales
  interexport_sales_opportunity = 830,
  interexport_sales_notes = 832,
  interexport_sales_product = 884,
  interexport_sales_lease_agreement = 886,
  warehouse = 831,
  // shared helpdesk
  phone_communication = 834,
  presence = 839,
  incoming_mail = 878,
  outgoing_mail = 879,
  // reports
  reports_cron = 882,
}

const formPaths: { [x in SupportedForm]?: string } = {
  // helpdesk
  [SupportedForm.rcg_helpdesk_agent]: 'servicedesk/rcg/helpdesk-agent',
  [SupportedForm.rcg_reply_agent]: 'servicedesk/rcg/reply-agent',
  [SupportedForm.rcg_reply_end_user]: 'servicedesk/rcg/reply-end-user',
  [SupportedForm.rcg_product_items]: 'servicedesk/rcg/product-items',
  [SupportedForm.rcg_project]: 'servicedesk/rcg/project',
  [SupportedForm.rcg_knowledgebase]: 'servicedesk/rcg/knowledgebase/rcg_knowledgebase',
  [SupportedForm.medilab_helpdesk_agent]: 'servicedesk/medilab/helpdesk-agent',
  [SupportedForm.medilab_reply_agent]: 'servicedesk/medilab/reply-agent',
  [SupportedForm.medilab_reply_end_user]: 'servicedesk/medilab/reply-end-user',
  [SupportedForm.medilab_project]: 'servicedesk/medilab/project',
  [SupportedForm.medilab_knowledgebase]: 'servicedesk/medilab/knowledgebase/medilab_knowledgebase',
  [SupportedForm.ncr_helpdesk_agent]: 'servicedesk/ncr/helpdesk-agent',
  [SupportedForm.ncr_reply_agent]: 'servicedesk/ncr/reply-agent',
  [SupportedForm.ncr_product_items]: 'servicedesk/ncr/product-items',
  [SupportedForm.ncr_device]: 'servicedesk/ncr/device',
  [SupportedForm.bent_helpdesk_agent]: 'servicedesk/bent/helpdesk-agent',
  [SupportedForm.bent_reply_agent]: 'servicedesk/bent/reply-agent',
  [SupportedForm.bent_product_items]: 'servicedesk/bent/product-items',
  [SupportedForm.halcom_helpdesk_end_user]: 'servicedesk/halcom/helpdesk-end-user',
  [SupportedForm.halcom_reply_end_user]: 'servicedesk/halcom/reply-end-user',
  [SupportedForm.halcom_knowledgebase]: 'servicedesk/halcom/halcom_knowledgebase',
  [SupportedForm.halcom_ba_helpdesk]: 'servicedesk/halcom/ba/helpdesk',
  [SupportedForm.halcom_ba_reply_agent]: 'servicedesk/halcom/ba/reply-agent',
  [SupportedForm.halcom_ba_reply_end_user]: 'servicedesk/halcom/ba/reply-end-user',
  [SupportedForm.halcom_ba_knowledgebase]: 'servicedesk/halcom/ba/halcom_knowledgebase',
  [SupportedForm.sava_mkt_helpdesk_agent]: 'servicedesk/sava-mkt/helpdesk-agent',
  [SupportedForm.sava_mkt_reply_agent]: 'servicedesk/sava-mkt/reply-agent',
  [SupportedForm.lancom_tehnika_helpdesk_agent]: 'servicedesk/lancom/tehnika/helpdesk-agent',
  [SupportedForm.lancom_tehnika_reply_agent]: 'servicedesk/lancom/tehnika/reply-agent',
  [SupportedForm.lancom_tehnika_product_items]: 'servicedesk/lancom/tehnika/product-items',
  [SupportedForm.lancom_tehnika_project]: 'servicedesk/lancom/tehnika/project',
  [SupportedForm.lancom_poslovna_informatika_helpdesk_agent]: 'servicedesk/lancom/poslovna_informatika/helpdesk-agent',
  [SupportedForm.lancom_poslovna_informatika_reply_agent]: 'servicedesk/lancom/poslovna_informatika/reply-agent',
  [SupportedForm.lancom_poslovna_informatika_reply_end_user]: 'servicedesk/lancom/poslovna_informatika/reply-end-user',
  [SupportedForm.lancom_poslovna_informatika_project]: 'servicedesk/lancom/poslovna_informatika/project',
  [SupportedForm.lancom_dms_helpdesk_agent]: 'servicedesk/lancom/dms/helpdesk-agent',
  [SupportedForm.lancom_dms_reply_agent]: 'servicedesk/lancom/dms/reply-agent',
  [SupportedForm.lancom_dms_reply_end_user]: 'servicedesk/lancom/dms/reply-end-user',
  [SupportedForm.lancom_dms_project]: 'servicedesk/lancom/dms/project',
  [SupportedForm.lancom_knowledgebase]: 'servicedesk/lancom/knowledgebase/lancom_knowledgebase',
  [SupportedForm.lancom_infrastructure_helpdesk_agent]: 'servicedesk/lancom/infrastructure/helpdesk-agent',
  [SupportedForm.lancom_infrastructure_reply_agent]: 'servicedesk/lancom/infrastructure/reply-agent',
  [SupportedForm.lancom_infrastructure_product_items]: 'servicedesk/lancom/infrastructure/product-items',
  [SupportedForm.lancom_infrastructure_project]: 'servicedesk/lancom/infrastructure/project',
  [SupportedForm.projektip_helpdesk_agent]: 'servicedesk/projektip/helpdesk-agent',
  [SupportedForm.projektip_reply_agent]: 'servicedesk/projektip/reply-agent',
  [SupportedForm.projektip_reply_end_user]: 'servicedesk/projektip/reply-end-user',
  [SupportedForm.projektip_product_items]: 'servicedesk/projektip/product-items',
  [SupportedForm.projektip_project]: 'servicedesk/projektip/project',
  [SupportedForm.projektip_sla]: 'servicedesk/projektip/sla',
  [SupportedForm.projektip_purchase_order]: 'servicedesk/projektip/purchase_order',
  [SupportedForm.projektip_take_ownership]: 'servicedesk/projektip/take-ownership',
  [SupportedForm.projektip_close_ticket]: 'servicedesk/projektip/close-ticket',
  [SupportedForm.projektip_knowledgebase]: 'servicedesk/projektip/knowledgebase',
  [SupportedForm.generaliInv_reply_agent]: 'servicedesk/generaliInv/generaliInv_reply_agent',
  [SupportedForm.generaliInv_reply_enduser]: 'servicedesk/generaliInv/generaliInv_reply_enduser',

  [SupportedForm.interexport_redirect_event]: 'servicedesk/interexport/redirect_event',
  [SupportedForm.interexport_local_it_helpdesk_agent]: 'servicedesk/interexport/local_it/interexport_helpdesk_agent',
  [SupportedForm.interexport_local_it_reply_agent]: 'servicedesk/interexport/local_it/interexport_reply_agent',
  [SupportedForm.interexport_local_it_reply_enduser]: 'servicedesk/interexport/local_it/interexport_reply_enduser',
  [SupportedForm.interexport_local_it_project]: 'servicedesk/interexport/local_it/interexport_project',

  [SupportedForm.interexport_medicina_helpdesk_agent]: 'servicedesk/interexport/medicina/interexport_helpdesk_agent',
  [SupportedForm.interexport_medicina_reply_agent]: 'servicedesk/interexport/medicina/interexport_reply_agent',
  [SupportedForm.interexport_medicina_reply_enduser]: 'servicedesk/interexport/medicina/interexport_reply_enduser',
  [SupportedForm.interexport_medicina_project]: 'servicedesk/interexport/medicina/interexport_project',

  [SupportedForm.interexport_radiologija_helpdesk_agent]: 'servicedesk/interexport/radiologija/interexport_helpdesk_agent',
  [SupportedForm.interexport_radiologija_reply_agent]: 'servicedesk/interexport/radiologija/interexport_reply_agent',
  [SupportedForm.interexport_radiologija_reply_enduser]: 'servicedesk/interexport/radiologija/interexport_reply_enduser',
  [SupportedForm.interexport_radiologija_project]: 'servicedesk/interexport/radiologija/interexport_project',

  [SupportedForm.interexport_km_helpdesk_agent]: 'servicedesk/interexport/KM/helpdesk-agent',
  [SupportedForm.interexport_km_reply_agent]: 'servicedesk/interexport/KM/reply-agent',
  [SupportedForm.interexport_km_product_items]: 'servicedesk/interexport/KM/product-items',

  [SupportedForm.default_knowledge_base]: 'servicedesk/knowledgebase/default_knowledgebase',
  [SupportedForm.interexport_knowledge_base_localIT]: 'servicedesk/interexport/knowledgebase/interexport_knowledgebase_localIT',

  [SupportedForm.btc_helpdesk_agent]: 'servicedesk/btc/helpdesk_agent',
  [SupportedForm.btc_reply_agent]: 'servicedesk/btc/reply_agent',
  [SupportedForm.btc_reply_enduser]: 'servicedesk/btc/reply_enduser',

  [SupportedForm.generaliInv_helpdesk_agent]: 'servicedesk/generaliInv/generaliInv_helpdesk_agent',
  [SupportedForm.contact]: 'contact',

  [SupportedForm.problems_default]: 'servicedesk/problems/default',

  // shared helpdesk
  [SupportedForm.phone_communication]: 'servicedesk/shared/phone-communication',

  // actions
  [SupportedForm.redirect_event]: 'actions/redirect-event',
  [SupportedForm.take_ownership]: 'actions/take-ownership',
  [SupportedForm.edit_cron]: 'actions/edit-cron',
  [SupportedForm.move_communication_event]: 'actions/move-communication-event',
  [SupportedForm.move_helpdesk_between_tenants]: 'actions/move-helpdesk-between-tenants',
  [SupportedForm.phone_call]: 'actions/phone-call',
  [SupportedForm.link_phone_call_to_existing_ticket]: 'actions/link-phone-call-to-existing-ticket',
  [SupportedForm.bent_redirect_event]: 'servicedesk/bent/redirect-event',
  [SupportedForm.admin_organization]: 'administration/organization',
  [SupportedForm.admin_device]: 'administration/device',
  [SupportedForm.admin_tag]: 'administration/tag',
  [SupportedForm.admin_tenant_group]: 'administration/tenant-group',
  [SupportedForm.signature]: 'actions/signature',
  [SupportedForm.kontron_helpdesk_agent]: 'servicedesk/kontron/helpdesk-agent',
  [SupportedForm.kontron_reply_agent]: 'servicedesk/kontron/reply-agent',
  [SupportedForm.kontron_reply_end_user]: 'servicedesk/kontron/reply-end-user',
  [SupportedForm.kontron_product_items]: 'servicedesk/kontron/product-items',
  [SupportedForm.kontron_project]: 'servicedesk/kontron/project',
  [SupportedForm.kontron_knowledgebase]: 'servicedesk/kontron/knowledgebase/kontron_knowledgebase',

  [SupportedForm.zzi_internalIT]: 'servicedesk/zzi/internalIT',
  [SupportedForm.zzi_reply_agent]: 'servicedesk/zzi/reply-agent',

  [SupportedForm.admin_azure_app_settings]: 'administration/azure-app-settings',
  [SupportedForm.exchange_oauth_email]: 'administration/exchange-oauth-email',
  [SupportedForm.exchange_basic_credentials_email]: 'administration/exchange-basic-credentials-email',
  [SupportedForm.ad_settings]: 'administration/ad-settings',
  [SupportedForm.pop3_email]: 'administration/pop3-mail',

  [SupportedForm.interexport_sales_opportunity]: 'sales/interexport/opportunity',
  [SupportedForm.interexport_sales_notes]: 'sales/interexport/notes',
  [SupportedForm.interexport_sales_product]: 'sales/interexport/product',
  [SupportedForm.interexport_sales_lease_agreement]: 'sales/interexport/lease_agreement',
  [SupportedForm.warehouse]: 'warehouse/warehouse',
  [SupportedForm.presence]: 'presence/presence',

  [SupportedForm.lookup_table_sla]: 'lookup-tables/sla',
  [SupportedForm.lookup_table_sla_detail]: 'lookup-tables/sla-detail',
  [SupportedForm.incoming_mail]: 'administration/incoming-mail',
  [SupportedForm.outgoing_mail]: 'administration/outgoing-mail',
  [SupportedForm.user]: 'administration/user',

  [SupportedForm.reports_cron]: 'reports/reports_cron',
};

async function tryFormHost(host: string, fallback: () => Promise<string> | string) {
  try {
    const result = await fetch(`${host}base.js`);
    if (result.ok) return host;
  } catch (e) {
    // ignore
  }

  return fallback();
}

const baseBaseUrl = `https://forms.assist.si/`;

async function versionFormHost() {
  const formsVersion = await getFormsVersion();

  return tryFormHost(`${baseBaseUrl}${formsVersion}/`, () => {
    throw new Error('Failed to detect valid forms host');
  });
}

function retryGetFormsBaseUrl(production: boolean) {
  for (let tries = 0; tries < 5; tries++) {
    return production ? versionFormHost() : tryFormHost('http://localhost:8090/', () => versionFormHost());
  }

  throw new Error('Failed to get valid forms url');
}

let formsBaseUrl: Promise<string>;

export function initForms(production: boolean) {
  formsBaseUrl = retryGetFormsBaseUrl(production);
}

const formCache: { [path: string]: FormFieldsDefinition } = {};

export function invalidateFormCache() {
  for (const path of Object.keys(formCache)) {
    delete formCache[path];
  }
}

/**
 * [importName, import][]
 */
const formImports: DynamicImport[] = (
  [
    ['rxjs', rxjs],
    ['luxon', luxon],
  ] as [string, object][]
).map(
  ([importName, importObject], i) =>
    ({
      importName,
      importObject,
      varName: `ಠ${i}_${importName.replace(/[^a-z0-9]/gi, '_')}`,
    } as DynamicImport),
);

const formImportVars = Object.fromEntries(formImports.map(({ varName, importObject }) => [varName, importObject]));

async function replaceRelativeImports(fnText: string, path: string, globalVarsService: FormGlobalVarsService) {
  const r = /import\s*([\s{].+?[\s}])\s*from\s*(["'])(.+?)\2/g;

  const matches: string[] = [];
  let match: RegExpExecArray | null;

  while ((match = r.exec(fnText))) {
    matches.push(match[3]);
  }

  const relFormImports: DynamicImport[] = await Promise.all(
    matches.map(async (importName, i) => ({
      importName,
      importObject: await getForm(`${path}/../${importName}`, globalVarsService),
      varName: `ೞ${i}_${importName.replace(/[^a-z0-9]/gi, '_')}`,
    })),
  );

  return relFormImports;
}

function passthroughTemplateTag(s: TemplateStringsArray, ...args: string[]): string {
  return s.join('') + args.join('');
}

async function getForm(path: string, globalVarsService: FormGlobalVarsService): Promise<FormFieldsDefinition> {
  let normalizedPath = path.replaceAll(/\/\.\//g, '/');
  const upRegex = /(?:\/|^)[a-z-]+\/\.\.(\/|$)/g;
  while (upRegex.test(normalizedPath)) normalizedPath = normalizedPath.replaceAll(upRegex, '$1');

  const tenantChanged: boolean = await globalVarsService.hasTenantChanged();
  if (tenantChanged) {
    invalidateFormCache();
  }

  if (formCache[normalizedPath] && !tenantChanged) {
    return formCache[normalizedPath];
  }

  const response = await fetch(`${await formsBaseUrl}${normalizedPath}.js`);
  if (!response.ok) throw response.statusText;

  const responseText = await response.text();
  const variableExportReplaced = replaceVariableExports(responseText);
  const exportedFnText = transformDefaultExportToReturn(variableExportReplaced);

  const formImportedFnText = formImports.reduce(replaceImport, exportedFnText);
  const relFormImports = await replaceRelativeImports(formImportedFnText, normalizedPath, globalVarsService);

  const fnText = relFormImports.reduce(replaceImport, formImportedFnText);

  const relFormImportVars = Object.fromEntries(relFormImports.map(({ varName, importObject }) => [varName, importObject]));
  const globalVars = await globalVarsService.globalVars;

  return (formCache[normalizedPath] = loadDynamicJS(fnText, {
    gql: passthroughTemplateTag,
    ...formImportVars,
    ...relFormImportVars,
    ...globalVars,
  }));
}

export const getFormFields = async (
  form: SupportedForm | string,
  globalVarsService: FormGlobalVarsService,
): Promise<FormFieldsDefinition> => {
  if (typeof form === 'string') return getForm(form, globalVarsService);

  if (formPaths[form]) {
    return getForm(formPaths[form]!, globalVarsService);
  }

  throw new Error(`Unsupported form: ${form}`);
};

export const getHelpdeskFormId = (tenantId: number, isEndUser: boolean): number => {
  switch (tenantId) {
    case TenantId.Kontron:
      return SupportedForm.kontron_helpdesk_agent;
    case TenantId.Bent:
      return SupportedForm.bent_helpdesk_agent;
    case TenantId.HalcomRS:
    case TenantId.HalcomBA:
      return SupportedForm.halcom_ba_helpdesk;
    case TenantId.Halcom:
      return SupportedForm.halcom_helpdesk_end_user;
    case TenantId.NcrAlbania:
    case TenantId.Ncr:
      return SupportedForm.ncr_helpdesk_agent;
    case TenantId.LancomPoslovnaInformatika:
      return SupportedForm.lancom_poslovna_informatika_helpdesk_agent;
    case TenantId.LancomDms:
      return SupportedForm.lancom_dms_helpdesk_agent;
    case TenantId.LancomTehnika:
      return SupportedForm.lancom_tehnika_helpdesk_agent;
    case TenantId.LancomInfrastructure:
      return SupportedForm.lancom_infrastructure_helpdesk_agent;
    case TenantId.SavaMkt:
      return SupportedForm.sava_mkt_helpdesk_agent;
    case TenantId.Projektip:
      return SupportedForm.projektip_helpdesk_agent;
    case TenantId.InterexportLocalIt:
      return SupportedForm.interexport_local_it_helpdesk_agent;
    case TenantId.InterexportMedicina:
      return SupportedForm.interexport_medicina_helpdesk_agent;
    case TenantId.InterexportRadiologija:
      return SupportedForm.interexport_radiologija_helpdesk_agent;
    case TenantId.InterexportKm:
      return SupportedForm.interexport_km_helpdesk_agent;
    case TenantId.Btc:
      return SupportedForm.btc_helpdesk_agent;
    case TenantId.ZZI:
      return SupportedForm.zzi_internalIT;
    case TenantId.GeneraliInv:
      return isEndUser ? SupportedForm.generaliInv_reply_enduser : SupportedForm.generaliInv_helpdesk_agent;
    case TenantId.Medilab:
      return SupportedForm.medilab_helpdesk_agent;
    default:
      return SupportedForm.rcg_helpdesk_agent;
  }
};

export const getKnowledgeBaseFormId = (tenantId: number): number => {
  switch (tenantId) {
    case TenantId.InterexportLocalIt:
      return SupportedForm.interexport_knowledge_base_localIT;
    case TenantId.InterexportKm:
      return SupportedForm.interexport_knowledge_base_localIT;
    case TenantId.InterexportMedicina:
      return SupportedForm.interexport_knowledge_base_localIT;
    case TenantId.HalcomRS:
    case TenantId.HalcomBA:
      return SupportedForm.halcom_ba_knowledgebase;
    case TenantId.HalcomCC:
      return SupportedForm.halcom_knowledgebase;
    case TenantId.Halcom:
      return SupportedForm.halcom_ba_knowledgebase;
    case TenantId.Rcg:
      return SupportedForm.rcg_knowledgebase;
    case TenantId.Medilab:
      return SupportedForm.medilab_knowledgebase;
    case TenantId.Kontron:
      return SupportedForm.kontron_knowledgebase;
    case TenantId.LancomPoslovnaInformatika:
      return SupportedForm.lancom_knowledgebase;
    case TenantId.LancomInfrastructure:
      return SupportedForm.lancom_knowledgebase;
    case TenantId.LancomDms:
      return SupportedForm.lancom_knowledgebase;
    case TenantId.LancomTehnika:
      return SupportedForm.lancom_knowledgebase;
    case TenantId.Projektip:
      return SupportedForm.projektip_knowledgebase;
    default:
      return SupportedForm.default_knowledge_base;
  }
};

export const getProblemFormId = (tenantId: number): number => {
  switch (tenantId) {
    default:
      return SupportedForm.problems_default;
  }
};

export const getReplyFormId = (tenant: RcgTenant): SupportedForm => {
  const tenantId = tenant.id;
  const isEndUser = tenant?.isEndUser;

  switch (tenantId) {
    case TenantId.Kontron:
      if (isEndUser) return SupportedForm.kontron_reply_end_user;
      return SupportedForm.kontron_reply_agent;
    case TenantId.InterexportLocalIt:
      if (isEndUser) return SupportedForm.interexport_local_it_reply_enduser;
      return SupportedForm.interexport_local_it_reply_agent;
    case TenantId.InterexportMedicina:
      if (isEndUser) return SupportedForm.interexport_medicina_reply_enduser;
      return SupportedForm.interexport_medicina_reply_agent;
    case TenantId.InterexportRadiologija:
      if (isEndUser) return SupportedForm.interexport_radiologija_reply_enduser;
      return SupportedForm.interexport_radiologija_reply_agent;
    case TenantId.LancomTehnika:
      return SupportedForm.lancom_tehnika_reply_agent;
    case TenantId.LancomPoslovnaInformatika:
      return SupportedForm.lancom_poslovna_informatika_reply_agent;
    case TenantId.LancomDms:
      return SupportedForm.lancom_dms_reply_agent;
    case TenantId.LancomInfrastructure:
      return SupportedForm.lancom_infrastructure_reply_agent;
    case TenantId.Btc:
      return SupportedForm.btc_reply_agent;
    case TenantId.HalcomRS:
    case TenantId.HalcomBA:
      return SupportedForm.halcom_ba_reply_agent;
    case TenantId.Halcom:
    case TenantId.HalcomCC:
      return SupportedForm.halcom_reply_end_user;
    case TenantId.Projektip:
      return SupportedForm.projektip_reply_agent;
    default:
      return SupportedForm.rcg_reply_agent;
  }
};

export const getProjectFormId = (tenantId: number, category: string): number => {
  switch (tenantId) {
    case TenantId.Rcg:
      return SupportedForm.rcg_project;
    case TenantId.Medilab:
      return SupportedForm.medilab_project;
    case TenantId.Kontron:
      return SupportedForm.kontron_project;
    case TenantId.LancomTehnika:
      return SupportedForm.lancom_tehnika_project;
    case TenantId.LancomPoslovnaInformatika:
      return SupportedForm.lancom_poslovna_informatika_project;
    case TenantId.LancomDms:
      return SupportedForm.lancom_dms_project;
    case TenantId.LancomInfrastructure:
      return SupportedForm.lancom_infrastructure_project;
    case TenantId.Projektip:
      if (category === 'sla-active' || category === 'sla-all') {
        return SupportedForm.projektip_sla;
      }
      if (category === 'purchase-order-active' || category === 'purchase-order-all') {
        return SupportedForm.projektip_purchase_order;
      }
      throw new Error('Form id za projekt ne obstaja');
    case TenantId.InterexportMedicina:
      return SupportedForm.interexport_medicina_project;
    case TenantId.InterexportRadiologija:
      return SupportedForm.interexport_radiologija_project;
    case TenantId.InterexportLocalIt:
      return SupportedForm.interexport_local_it_project;
    default:
      throw new Error('Form id za projekt ne obstaja');
  }
};

export const getInstalledEquipmentFormId = (tenantId: number): number => {
  switch (tenantId) {
    case TenantId.Ncr:
    case TenantId.NcrAlbania:
      return SupportedForm.ncr_device;
    default:
      return SupportedForm.admin_device;
  }
};
