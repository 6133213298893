import { Injectable, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogService, MessageService } from '@rcg/standalone';
import {
  AfterExecuteFormFieldActionInput,
  FieldAction,
  FieldComponentAction,
  FieldFormAction,
  FieldLocationAction,
} from '../models/field-action';
import { FormDialogService } from './form-dialog.service';

@Injectable({ providedIn: 'root' })
export class FieldsActionsService {
  dialogService = inject(DynamicDialogService);
  formService = inject(FormDialogService);
  messageService = inject(MessageService);

  async exececuteFieldAction(
    action: FieldAction,
    model: Record<string, unknown>,
    field: FormlyFieldConfig,
    fieldValue: unknown,
    form?: FormGroup,
    afterExecuteAction?: (input: AfterExecuteFormFieldActionInput) => void,
  ) {
    try {
      if (action.action === 'form') {
        const config = await (action as FieldFormAction).config(model, field, fieldValue);
        this.formService.openForm({
          formMode: config.formMode,
          formId: config.formId,
          dialogTitle: config.dialog.title,
          dialogIcon: config.dialog.icon,
          width: config.dialog.width,
          height: config.dialog.height,
          formrecordId: config.recordId,
          prefillData: config.prefillData,
          prefilledGqlVariables: config.prefilledGqlVariables,
          openFullscreen: config.openFullscreen,
          onSubmitSuccessAction: (result) => {
            if (
              afterExecuteAction &&
              typeof afterExecuteAction === 'function' &&
              action.afterExecuteAction &&
              typeof action.afterExecuteAction === 'function'
            ) {
              afterExecuteAction({
                actionResult: result,
                model: model,
                field: field,
                form: form,
              });
            }
          },
        });
        return;
      }

      if (action.action === 'component') {
        const config = await (action as FieldComponentAction).config(model, field, fieldValue);
        this.dialogService.openDialog({
          width: config.dialog.width,
          height: config.dialog.height,
          minWidth: config.dialog.minWidth,
          minHeight: config.dialog.minHeight,
          data: {
            component: config.component,
            inputs: config.inputs,
            title: config.dialog.title,
            icon: config.dialog.icon,
            ...config.data,
          },
        });
        return;
      }

      if (action.action === 'location') {
        const config = await (action as FieldLocationAction).config(model, field, fieldValue);
        if (config?.location?.lat && config?.location?.lng) {
          const url = `https://www.google.com/maps/search/?api=1&query=${config.location.lat},${config.location.lng}`;
          window?.open(url, '_blank');
        }
        return;
      }
    } catch (error) {
      this.messageService.showErrorSnackbar('Napaka', 'Napaka pri izvajanju akcije: ' + (error as Error | undefined)?.message ?? '');
    }
  }
}
