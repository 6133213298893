import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyForm, FormlyFormOptions } from '@ngx-formly/core';
import { FormConfig, FormSubmitedPayload } from '@rcg/core';
import { MessageService } from '@rcg/standalone';
import * as dot from 'dot-object';
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged, filter, from, mergeMap, of } from 'rxjs';
import { FormlyService } from '../../services/formly.service';
export const beforeSubmitPropertyName = '_rcg_beforeSubmit';

export type BeforeSubmitProp = {
  [key: string]: () => Promise<void> | void;
};

type InitalFormMutation = (model: Record<string, unknown>) => Promise<void> | Observable<Record<string, unknown> | undefined | null>;

enum FormStatus {
  loading,
  errored,
  editing,
  submitting,
  submitted,
}

@Component({
  selector: 'rcg-formly-form',
  templateUrl: './formly-form.component.html',
  styleUrls: ['./formly-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFormComponent implements OnDestroy, OnChanges, OnInit {
  @HostBinding('class.bootstrap-styled') private bootstrapStyled = true; //! Required for styling to work

  @Input() formConfig!: FormConfig;
  @Input() listenFieldsChanges?: string[];
  @Input() focusFirstFieldOnInit?: boolean;

  @Output() submitSuccess = new EventEmitter<unknown>();
  @Output() submitError = new EventEmitter<string>();
  @Output() fieldChange = new EventEmitter<{ key: string; value: unknown }>();
  @Output() formSubmited = new EventEmitter<FormSubmitedPayload>();
  @Output() customSubmit = new EventEmitter<FormSubmitedPayload>();

  @ViewChild('formComponent', { read: ElementRef })
  formComponent?: ElementRef<HTMLElement>;

  @ViewChild('formComponent')
  formComponentRef?: FormlyForm;

  constructor(private formlyService: FormlyService, private changeRef: ChangeDetectorRef, private messageService: MessageService) {}

  private formDataSubscription?: Subscription;
  private debugFormStateSubscription?: Subscription;

  private initalFormMutationAction = new BehaviorSubject<Record<string, unknown> | null>(null);
  private initalFormMutationAction$ = this.initalFormMutationAction.asObservable();
  private initalFormMutationSubscription?: Subscription;
  private get initalMutationFunc(): InitalFormMutation | null {
    if (this.formOptions?.formState?.initalFormMutation && typeof this.formOptions.formState.initalFormMutation === 'function') {
      return this.formOptions.formState.initalFormMutation;
    }
    return null;
  }

  //? Allow usage in HTML
  readonly FormStatus = FormStatus;

  status = FormStatus.loading;
  error: string | null = null;

  model: Record<string, unknown> | null = null;
  fields: FormlyFieldConfig[] = [];
  rootFieldClassName = '';
  form: UntypedFormGroup | null = null;
  formOptions: FormlyFormOptions | null = null;
  title: string | null = null;
  showDebugForm = false;
  logDebugForm = false;
  formButtons: {
    className: 'stretch' | 'start' | 'end' | 'center';
    submitButton?: { title?: string; style?: Record<string, string> | undefined };
  } = {
    className: 'stretch',
  };

  hideFormButtons = false;

  fieldsValueChangesSubscription: Subscription[] = [];

  ngOnInit(): void {
    // execute initalFormMutation
    this.initalFormMutationSubscription = this.initalFormMutationAction$
      .pipe(
        filter((m) => !!m?.id),
        mergeMap((m) => {
          const func = this.initalMutationFunc;
          if (!func) return of(undefined);
          return from(func(m!));
        }),
      )
      .subscribe({
        error: (err) => console.error('Error execute inital form mutation function', err?.message ?? err),
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formConfig'].currentValue && changes['formConfig'].previousValue !== changes['formConfig'].currentValue) {
      this.createForm(changes['formConfig'].currentValue);
    }
  }

  ngOnDestroy(): void {
    this.formDataSubscription?.unsubscribe();
    this.debugFormStateSubscription?.unsubscribe();
    this.initalFormMutationSubscription?.unsubscribe();
    this.unsubscribeFromFieldsValueChanges();
  }

  async submitForm() {
    if (this.status !== FormStatus.editing) return;

    this.status = FormStatus.submitting;
    this.changeRef.markForCheck();

    try {
      await this._submitForm();
    } finally {
      // @ts-expect-error ts(2367) only checks shallowly, _submitForm modifies the status. https://github.com/microsoft/TypeScript/issues/9998
      if (this.status !== FormStatus.submitted) this.status = FormStatus.editing;
      this.changeRef.markForCheck();
    }
  }

  private _submitted(result: unknown) {
    this.submitSuccess.emit(result);
    this.status = FormStatus.submitted;
    this.changeRef.markForCheck();

    setTimeout(() => {
      if (this.status !== FormStatus.submitted) return;

      this.status = FormStatus.editing;
      this.changeRef.markForCheck();
    }, 3000);
  }

  private async _submitForm() {
    if (!this.form || this.form.invalid || this.form.pristine) {
      return;
    }

    if (this.customSubmit.observed) {
      this.customSubmit.emit({ model: this.model!, formValues: this.form.value });
      this.form.markAsPristine();
      return;
    }

    // emit submited model and form payload
    this.formSubmited.emit({ model: this.model!, formValues: this.form.value });

    if (this.formOptions?.formState?.preventServerSubmit === true) {
      if (this.formOptions?.formState?.formPristineOnSubmit === true) {
        this.form.markAsPristine();
      }
      return;
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const beforeSubmit: BeforeSubmitProp = (this.form as any)?.[beforeSubmitPropertyName];
      if (beforeSubmit) {
        await Promise.all(Object.values(beforeSubmit).map((f) => f()));
      }
    } catch (error) {
      this.submitError.emit(`BeforeSubmit: ${error}`);
      return;
    }

    if (this.formConfig.formMode === 'update') {
      try {
        const result = await this.formlyService.updateForm(
          this.formConfig.formrecordId!,
          this.model ?? {},
          this.fields,
          this.formOptions!,
          this.form,
          this.formConfig.formId!,
        );
        this._submitted(result);
        this.updateModelWithSubmitResult(result, 'update');
        this.form.markAsPristine();
        this.changeRef.markForCheck();
      } catch (error) {
        this.messageService.showErrorSnackbar('Napaka update form record', error, 5);
        console.error('Error update form record', error);
        this.submitError.emit((error as object).toString());
      }
      return;
    }

    if (this.formConfig.formMode === 'insert') {
      try {
        this.form.markAsPristine();
        const result = await this.formlyService.insertForm(
          this.model ?? {},
          this.fields,
          this.formOptions!,
          this.form,
          this.formConfig.parentFormId,
          this.formConfig.formId,
        );

        this._submitted(result);
        this.updateModelWithSubmitResult(result, 'insert');
      } catch (error) {
        this.messageService.showErrorSnackbar('Napaka dodaj form record', error, 5);
        this.submitError.emit((error as object).toString());
        this.form.markAsDirty();
      }
    }
  }

  private createForm(formConfig: FormConfig) {
    this.error = null;
    if (!formConfig) {
      this.error = 'Napaka prikaži form. Form config input je null';
      return;
    }

    this.formDataSubscription?.unsubscribe();
    this.debugFormStateSubscription?.unsubscribe();

    this.status = FormStatus.loading;
    this.title = null;
    this.changeRef.markForCheck();

    this.formDataSubscription = this.formlyService.getFormDefinitionWithData(formConfig).subscribe({
      next: (data) => {
        this.formOptions = data.formFieldsDefinition.options;

        this.hideFormButtons = this.formOptions?.formState?.hideFormButtons ?? false;

        this.formButtons = {
          ...this.formButtons,
          className: this.formOptions?.formState?.formButtons?.className ?? 'stretch',
          submitButton: {
            title: this.formOptions?.formState?.formButtons?.submitButton?.title,
            style: this.formOptions?.formState?.formButtons?.submitButton?.style,
          },
        };
        this.fields = data.formFieldsDefinition.fields;
        this.rootFieldClassName = data.formFieldsDefinition.rootFieldClassName ?? '';

        this.model = data.model;

        this.form = new UntypedFormGroup({});
        this.title =
          formConfig.formTitle ?? formConfig.titleFromModelFieldName
            ? (this.model?.[`${formConfig.titleFromModelFieldName}`] as string | null)
            : null;

        this.setInitialFormState(this.form, this.formConfig, this.formOptions);

        this.registerFoFieldsChanges();

        this.status = FormStatus.editing;
        this.changeRef.markForCheck();

        if (this.logDebugForm) {
          this.debugFormState();
        }
      },
      error: (error) => {
        console.error('Error getting form data', error);
        this.error = error?.message ?? error.toString();
        this.status = FormStatus.errored;
        this.changeRef.markForCheck();
      },
    });
  }

  private debugFormState() {
    let previousValue: Record<string, unknown> = {};

    this.debugFormStateSubscription = this.form?.valueChanges
      .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
      .subscribe(() => {
        const currentValue = this.form?.value;
        const changedValues: Record<string, unknown> = {};
        const errors: Array<{ key: string; value: unknown; errorMessage: string }> = [];

        for (const key in currentValue) {
          // Check for changed values
          if (JSON.stringify(currentValue[key]) !== JSON.stringify(previousValue[key])) {
            changedValues[key] = currentValue[key];
          }

          // Check for errors
          const control = this.form?.get(key);

          if (control?.invalid && control.errors) {
            const errorMessages = Object.keys(control.errors).map(
              (errorKey) => `${errorKey}: ${JSON.stringify(control.errors![errorKey])}`,
            );

            errors.push({
              key: key,
              value: currentValue[key],
              errorMessage: errorMessages.join(', '),
            });
          }
        }

        const formStatus = this.form?.valid || errors.length === 0 ? 'Valid' : 'INVALID';
        // allow log for debug
        console.log(`Form - ${this.formConfig.formId} - ${formStatus}`, {
          changedValues,
          errors,
          form: currentValue,
          model: this.model,
        });

        previousValue = { ...currentValue };
      });
  }

  private setInitialFormState(form: UntypedFormGroup, formConfig: FormConfig, formOptions: FormlyFormOptions | null) {
    const initialFormState = formConfig.initialFormState ?? formOptions?.formState?.initialFormState;
    if (initialFormState) {
      switch (initialFormState) {
        case 'dirty':
          form!.markAsDirty();
          break;
        case 'pristine':
          form!.markAsPristine();
          break;
        default:
          break;
      }
      return;
    }
  }

  private registerFoFieldsChanges() {
    this.unsubscribeFromFieldsValueChanges();

    if (this.listenFieldsChanges && this.listenFieldsChanges.length > 0) {
      for (const fieldKey of this.listenFieldsChanges) {
        if (!this.form?.controls?.[fieldKey]) {
          this.form?.addControl(fieldKey, new FormControl());
        }
        const subscription = this.form?.controls[fieldKey]?.valueChanges?.subscribe((value) => {
          this.fieldChange.emit({ key: fieldKey, value });
        });
        if (subscription) {
          this.fieldsValueChangesSubscription.push(subscription);
        }
      }
    }
  }

  private unsubscribeFromFieldsValueChanges() {
    if (this.fieldsValueChangesSubscription.length > 0) {
      for (const subscription of this.fieldsValueChangesSubscription) {
        subscription?.unsubscribe();
      }
    }
    this.fieldsValueChangesSubscription = [];
  }

  formLoaded() {
    if (!this.formComponent) return;
    if (!this.formComponentRef) return;

    this.formComponentRef.field.className = this.rootFieldClassName;

    // trigger inital form mutation
    if (this.model?.id && this.initalMutationFunc) {
      this.initalFormMutationAction.next(Object.assign({}, this.model));
    }

    if ((this.form && this.formConfig.formMode === 'disabled') || this.formConfig.formMode === 'readOnly') {
      this.form!.disable();
    }

    if (this.focusFirstFieldOnInit) {
      const el = this.formComponent.nativeElement;
      const target = el.querySelector<HTMLElement>('input:not([type="file"])') ?? el;

      if (target && target instanceof HTMLInputElement && target.type === 'checkbox') {
        target.focus();
        return;
      }

      target.click();
      target.focus();
    }
  }

  private updateModelWithSubmitResult(submitResult: unknown | undefined, mode: 'update' | 'insert') {
    const submitResultPath: string | undefined =
      mode === 'insert' ? this.formOptions?.formState?.patchFormInsertResultPath : this.formOptions?.formState?.patchFormUpdateResultPath;

    if (
      submitResultPath &&
      this.model &&
      submitResult &&
      (Array.isArray(submitResult) || (typeof submitResult === 'object' && Object.keys(submitResult).length > 0))
    ) {
      const patchData = dot.pick(submitResultPath, submitResult);
      if (patchData && Object.keys(patchData).length > 0) {
        this.model = { ...this.model, ...patchData };
      }
    }
  }
}
