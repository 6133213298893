import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { OrderByDirection } from '@npm-libs/ng-templater';
import { RcgFieldType } from '@rcg/core';
import { tr } from '@rcg/intl';
import { firstValueFrom } from 'rxjs';

export type SortField = {
  label: string;
  field: string; // value key
  direction?: OrderByDirection;
};

export type SortFieldSettings = {
  fields: SortField[];
  sortByLabel?: boolean;
};

@Component({
  selector: 'rcg-sort-field',
  templateUrl: './sort-field.component.html',
  styleUrls: ['./sort-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortFieldComponent extends RcgFieldType implements OnInit, OnDestroy {
  @ViewChild('input', { static: false }) input!: ElementRef<HTMLInputElement>;

  constructor(private changeRef: ChangeDetectorRef) {
    super();
  }

  autocompleteOptions: SortField[] = [];
  selectedValues: SortField[] = [];

  removable = true;
  separatorKeysCodes: number[] = [13, 188]; // enter,comma
  addOnBlur = true;
  inputFormControl = new UntypedFormControl();
  settings: SortFieldSettings | undefined;

  private get isDisabledOrReadOnly() {
    return this.props.disabled === true || this.props.readonly === true;
  }

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  async ngOnInit() {
    this.settings = this.props?.['settings'] as SortFieldSettings | undefined;
    if (!this.settings?.fields?.length) {
      console.error('No settings for sort field');
      return;
    }

    const sortFields: SortField[] =
      (await this.translateLabels([...this.settings.fields])).map((f) => (f.direction ? f : { ...f, direction: 'ascending' })) ?? [];
    this.autocompleteOptions = this.settings.sortByLabel === true ? sortFields.sort((a, b) => a.label.localeCompare(b.label)) : sortFields;
    this.selectedValues = await this.getInitialValues();
    this.value = this.selectedValues;
    this.changeRef.markForCheck();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  remove(item: SortField) {
    if (this.isDisabledOrReadOnly) return;

    this.selectedValues = this.selectedValues.filter((o) => o.field !== item.field);
    this.formCtrl.setValue(this.selectedValues);
    this.formCtrl.markAsDirty();
  }

  add(event: MatChipInputEvent): void {
    const value = (event?.value || '').trim();
    if (!value) {
      return;
    }

    event.chipInput!.clear();
    this.clearInput();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.isDisabledOrReadOnly) return;
    const selected = event?.option?.value as SortField;

    // if empty or already selected clear input
    if (!selected || !selected?.field || this.alreadySelected(selected.field)) {
      this.clearInput();
      return;
    }

    // ok - add to selectedOptions values and clear input
    this.selectedValues = [...this.selectedValues, { ...selected }];
    this.formCtrl.setValue(this.selectedValues);
    this.clearInput();
    this.formCtrl.markAsDirty();
  }

  changeSort(item: SortField) {
    const sort: OrderByDirection = this.getNewSortValue(item.direction);
    this.selectedValues = this.selectedValues.map((s) =>
      s.field === item.field
        ? {
            ...item,
            direction: sort,
          }
        : s,
    );
    this.formCtrl.setValue(this.selectedValues);
    this.formControl.markAsDirty();
    this.changeRef.markForCheck();
  }

  private async getInitialValues(): Promise<SortField[]> {
    const values = ((this.model[this.field.key as string] as SortField[] | undefined) ?? []).filter((v) => !!v?.field);
    if (!values || values.length === 0) {
      return [];
    }
    const fields = this.settings!.fields;
    const fieldMap = new Map<string, SortField>();

    for (const field of fields) {
      fieldMap.set(field.field, field);
    }

    for (const v of values) {
      const field = fieldMap.get(v.field);
      if (field) {
        v.label = await firstValueFrom(tr(field.label));
      }
    }
    return values;
  }

  private clearInput(): void {
    this.input.nativeElement.value = '';
    this.inputFormControl.setValue(null);
  }

  private alreadySelected(field: string | number): boolean {
    return this.selectedValues.findIndex((s) => s.field === field) !== -1;
  }

  private getNewSortValue(sort: OrderByDirection | undefined): OrderByDirection {
    if (!sort) return 'ascending';
    return sort === 'ascending' ? 'descending' : 'ascending';
  }

  private async translateLabels(fields: SortField[]) {
    for (const field of fields) {
      field.label = await firstValueFrom(tr(field.label));
    }
    return fields;
  }
}
