import { computed, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from '@rcg/auth';
import { DescriptionSetting, SelectColor, SelectOption } from '@rcg/core';
import { GraphqlClientService } from '@rcg/graphql';
import { IntlService } from '@rcg/intl';
import { gql } from 'apollo-angular';
import * as dot from 'dot-object';
import { firstValueFrom, map, Observable } from 'rxjs';
import { FormDialogService } from '../../../services/form-dialog.service';
import { extractFieldData, getFieldValueFromPath, getNestedFieldData } from '../../../utils/field-path-utils';
import { SupportedForm } from '../../../utils/supported-forms-utils';
import { AcDescriptionSettings, ACSettings, AcTreeSettings, AddNewContactSettings } from '../models/ac-settings';

@Injectable()
export class AutocompleteService {
  private readonly gqlClient = inject(GraphqlClientService);
  private readonly auth = inject(AuthService);
  private readonly formDialogService = inject(FormDialogService);
  private readonly intl = inject(IntlService);

  private readonly _localeOriginal = toSignal(this.intl.locale$.pipe(takeUntilDestroyed()));
  private readonly locale = computed(() =>
    this._localeOriginal() && this._localeOriginal()!.includes('_') ? this._localeOriginal()!.replace('_', '-') : this._localeOriginal(),
  );

  getAutoCompleteTreeData(acSettings: AcTreeSettings, model: Record<string, unknown>, search?: string): Observable<SelectOption[]> {
    const searchTerm = search?.trim() ?? '';

    let variables = {
      search: `${acSettings.searchSettings?.prefix ?? ''}${searchTerm}${acSettings.searchSettings?.suffix ?? ''}`,
    };

    if (acSettings.gqlVariablesFromModel && Object.keys(acSettings.gqlVariablesFromModel).length > 0) {
      const modelVars: Record<string, unknown> = {};
      for (const [key, value] of Object.entries(acSettings.gqlVariablesFromModel)) {
        modelVars[key] = getNestedFieldData(model, value ?? null);
      }
      variables = {
        ...variables,
        ...modelVars,
      };
    }

    return (
      typeof acSettings.query === 'function'
        ? acSettings.query({ variables, model })
        : this.gqlClient.query<{ data?: unknown[] }>({
            query: gql(acSettings.query),
            variables: variables,
          })
    ).pipe(
      map((result) => {
        const treeData = this.processTreeNestedData(
          result?.data ?? ([] as unknown[]),
          acSettings.additionalDataFields,
          acSettings.descriptionSetting,
          acSettings,
          3,
        );
        return treeData;
      }),
    );
  }

  private processTreeNestedData(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[],
    additionalDataFields: string[] | undefined,
    descriptionSetting: AcDescriptionSettings,
    acSettings: AcTreeSettings,
    n: number,
  ): SelectOption[] {
    return data
      .map((item) => {
        const additionalData: Record<string, unknown> = {};

        if (additionalDataFields && additionalDataFields.length > 0) {
          for (const name of additionalDataFields) {
            additionalData[name] = item[name];
          }
        }

        const description = this.getDescription(descriptionSetting, item);

        let children: SelectOption[] = [];
        if (n > 0 && item.children && item.children.length > 0) {
          children = this.processTreeNestedData(item.children, additionalDataFields, descriptionSetting, acSettings, n - 1);
        }

        if (acSettings.searchValue?.value) {
          const dataValue = this.getDataValueByPath(acSettings, item);
          return {
            value: dataValue?.value,
            label: dataValue?.label ?? '',
            description: description,
            data: { ...additionalData, ...(dataValue?.data ?? {}) },
            disabled: dataValue?.disabled === true,
            children,
          } as SelectOption;
        }
        return { value: item.value, label: item.label, description: description, data: additionalData, children } as SelectOption;
      })
      .filter((item) => !!item?.label && !!item?.value);
  }

  getAutoCompleteData(
    settings: ACSettings,
    model: Record<string, unknown>,
    searchInput?: string,
    useFieldValueFromPath = false,
  ): Observable<SelectOption[]> {
    const search = searchInput?.trim() ?? '';

    let variables = {
      search: `${settings.searchSettings?.prefix ?? ''}${search}${settings.searchSettings?.suffix ?? ''}`,
    };

    if (settings.gqlVariables) {
      variables = {
        ...variables,
        ...settings.gqlVariables,
      };
    }

    if (settings.prefilledGqlVariables) {
      variables = {
        ...variables,
        ...settings.prefilledGqlVariables,
      };
    }

    if (settings.gqlVariablesFromModel && Object.keys(settings.gqlVariablesFromModel).length > 0) {
      const modelVars: Record<string, unknown> = {};
      for (const [key, path] of Object.entries(settings.gqlVariablesFromModel)) {
        modelVars[key] = useFieldValueFromPath ? getFieldValueFromPath(model, path ?? null) : getNestedFieldData(model, path ?? null);

        if (!modelVars[key] && variables[key as keyof typeof variables]) {
          // if prefilledGqlVariables exists and not gqlVariablesFromModel use prefilledGqlVariables gql value
          modelVars[key] = variables[key as keyof typeof variables];
        }
      }

      variables = {
        ...variables,
        ...modelVars,
      };
    }

    const sortByLabel = (a: { label?: string }, b: { label?: string }, desc: boolean): number => {
      const aa: string = a?.label?.toLowerCase()?.trim() ?? '';
      const bb: string = b?.label?.toLowerCase()?.trim() ?? '';
      return desc ? bb.localeCompare(aa) : aa.localeCompare(bb);
    };

    return (
      typeof settings.query === 'function'
        ? settings.query({ variables, model })
        : this.gqlClient.query<{ data?: Record<string, unknown>[] }>({
            query: gql(settings.query),
            variables: variables,
          })
    ).pipe(
      map((result) => {
        if (!result?.data) return [];

        const resultOptions: SelectOption[] = result.data.map((item) => this.searchValueToSelectOption(settings, item));

        if (!settings.sortByLabelAsc && !settings.sortByLabelDesc) {
          return resultOptions;
        }

        return resultOptions.sort((a, b) => sortByLabel(a, b, settings.sortByLabelDesc === true));
      }),
    );
  }

  initalValueToSelectOption(
    settings: ACSettings,
    fieldData: Record<string, unknown> | null | undefined,
    model: Record<string, unknown>,
    isJsonDataField?: boolean,
  ): SelectOption | null {
    if (!fieldData) {
      return null;
    }

    if (isJsonDataField) {
      // json data field

      if (!fieldData?.value || !fieldData?.label) {
        return null;
      }

      let data: Record<string, unknown> = {};

      if (settings.initalValueFromModel?.data) {
        const dataValue = this.getDataValue(settings.initalValueFromModel?.data, model);
        if (dataValue) {
          data = dataValue;
        }
      } else if (fieldData.data) {
        data = fieldData.data as Record<string, unknown>;
      }

      return { value: fieldData.value, label: fieldData.label, data: data } as SelectOption;
    }

    let value: string | number | undefined;
    let label = '';
    let data: Record<string, unknown> = {};

    const fieldDataAvailable = fieldData && Object.keys(fieldData).length > 0;
    const valueNamAndLabelsAvailable: boolean =
      !!settings.valueName && Array.isArray(settings.labelNames) && settings.labelNames.length > 0;

    if (settings.initalValueFromModel?.value) {
      const labelName = settings.initalValueFromModel?.label;
      const valueName = settings.initalValueFromModel?.value;

      if (labelName && valueName) {
        label = getNestedFieldData(model, labelName);
        value = getNestedFieldData(model, valueName);

        const dataValue = this.getDataValue(settings.initalValueFromModel?.data, model);
        if (dataValue) {
          data = dataValue;
        }
      }
    } else if (fieldDataAvailable && valueNamAndLabelsAvailable) {
      // legacy code in from settings
      label = (settings.labelNames ?? [])
        .map((d) => extractFieldData(fieldData, d))
        .filter((v) => !!v)
        .join('');
      value = fieldData![settings.valueName] as string | number;
    }

    if (!value || !label) return null;

    // set additional data
    if (fieldDataAvailable && settings.additionalDataFields && settings.additionalDataFields.length > 0) {
      for (const name of settings.additionalDataFields) {
        data[name] = fieldData[name];
      }
    }

    return {
      id: (fieldData?.id ?? null) as number | null,
      value,
      label,
      data,
      // multiselect additional props
      selectedValueName:
        fieldDataAvailable && settings.selectedLabel && (fieldData[settings.selectedLabel] as string | undefined)?.trim()
          ? (fieldData[settings.selectedLabel] as string).trim()
          : undefined,
    };
  }

  searchValueToSelectOption(settings: ACSettings, fieldData: Record<string, unknown> | null | undefined): SelectOption {
    let value: string | number | undefined;
    let label = '';
    let data: Record<string, unknown> = {};
    let disabled = false;

    const fieldDataAvailable = fieldData && Object.keys(fieldData).length > 0;
    const valueNamAndLabelsAvailable: boolean =
      !!settings.valueName && Array.isArray(settings.labelNames) && settings.labelNames.length > 0;

    if (settings.searchValue?.value) {
      const dataValue = this.getDataValueByPath(settings, fieldData);
      if (dataValue) {
        label = dataValue.label ?? '';
        value = dataValue.value;
        data = dataValue?.data ?? {};
        disabled = dataValue?.disabled === true;
      }
    } else if (fieldDataAvailable && valueNamAndLabelsAvailable) {
      // legacy code in from settings
      label = (settings.labelNames ?? [])
        .map((d) => extractFieldData(fieldData, d))
        .filter((v) => !!v)
        .join('');
      value = fieldData![settings.valueName] as string | number;
    }

    // set additional data
    if (fieldDataAvailable && settings.additionalDataFields && settings.additionalDataFields.length > 0) {
      for (const name of settings.additionalDataFields) {
        data[name] = fieldData[name];
      }
    }

    // select item color
    let color: SelectColor | undefined;
    if (settings.color) {
      color = {
        textColor: settings.color.textColor ? dot.pick(settings.color.textColor, fieldData) : undefined,
        backgroundColor: settings.color.backgroundColor ? dot.pick(settings.color.backgroundColor, fieldData) : undefined,
        fixedTextColor: settings.color.fixedTextColor,
        fixedBackgroundColor: settings.color.fixedBackgroundColor,
      };
    }

    // additional descriptions
    const description = this.getDescription(settings?.descriptionSetting, fieldData);

    return {
      id: (fieldData?.id ?? null) as number | null,
      value,
      label,
      color: color,
      description,
      data,
      disabled,
      // multiselect additional props
      selectedValueName:
        fieldDataAvailable && settings.selectedLabel && (fieldData[settings.selectedLabel] as string | undefined)?.trim()
          ? (fieldData[settings.selectedLabel] as string).trim()
          : undefined,
    };
  }

  async assignSelf(acSettings: ACSettings, model: Record<string, unknown>) {
    let variables = {
      userId: this.auth.user()?.id,
    };

    // gql vars from model
    if (acSettings.gqlVariablesFromModelAssignSelf && Object.keys(acSettings.gqlVariablesFromModelAssignSelf).length > 0) {
      const modelVars: Record<string, unknown> = {};
      for (const [key, value] of Object.entries(acSettings.gqlVariablesFromModelAssignSelf)) {
        modelVars[key] = getNestedFieldData(model, value ?? null);
      }

      variables = {
        ...variables,
        ...modelVars,
      };
    }

    return await firstValueFrom(
      this.gqlClient
        .query<{ data?: Record<string, unknown>[] }>({
          query: gql(acSettings.assignSelfQuery!),
          variables,
        })
        .pipe(
          map(
            (result) => result?.data?.map((item) => this.initalValueToSelectOption(acSettings, item, model)) as SelectOption[] | undefined,
          ),
        ),
    );
  }

  addNewContact(settings: AddNewContactSettings, model: Record<string, unknown>, onSubmitSuccess: (data: { id?: number }) => void): void {
    const prefillData: Record<string, unknown> = {};

    if (settings.addNewPrefillData && Object.keys(settings.addNewPrefillData).length > 0) {
      for (const [key, value] of Object.entries(settings.addNewPrefillData)) {
        const obj: Record<string, unknown> = {};
        if (value && typeof value === 'object') {
          for (const [k, val] of Object.entries(value)) {
            if (val && typeof val === 'string') {
              const pathVal = getFieldValueFromPath(model, val);
              if (pathVal) {
                obj[k] = pathVal;
              }
            }
          }
          if (Object.keys(obj).length > 0) {
            prefillData[key] = obj;
          }
        } else {
          const pathVal = getFieldValueFromPath(model, value as string | null | undefined);
          if (pathVal) {
            prefillData[key] = pathVal;
          }
        }
      }
    }

    this.formDialogService.openForm({
      formId: SupportedForm.contact,
      formMode: 'insert',
      dialogTitle: settings.addNewDialogTitle ?? 'Dodaj',
      onSubmitSuccessAction: ({ data }) => {
        onSubmitSuccess(data);
      },
      prefillData: Object.keys(prefillData).length > 0 ? prefillData : null,
    });
  }

  private getDescription(
    descriptionSetting: AcDescriptionSettings,
    fieldData: Record<string, unknown> | undefined | null,
  ): string | DescriptionSetting[] {
    if (!descriptionSetting || !fieldData || Object.keys(fieldData).length < 1) {
      return '';
    }

    let description = '';

    if (typeof descriptionSetting === 'function') {
      try {
        if (Array.isArray(descriptionSetting)) {
          return descriptionSetting({ value: fieldData, locale: this.locale() }) as DescriptionSetting[];
        }
        description = descriptionSetting({ value: fieldData, locale: this.locale() }) as string;
      } catch (error) {
        description = '';
      }
    } else {
      description = (descriptionSetting ?? [])
        .map((d) => extractFieldData(fieldData, d))
        .filter((v) => !!v)
        .join('');
    }
    return description;
  }

  private getDataValueByPath(settings: ACSettings | AcTreeSettings, fieldData: Record<string, unknown> | null | undefined) {
    if (!fieldData) return null;

    const labelPath = settings.searchValue?.label;
    const valuePath = settings.searchValue?.value;
    const disabledPath = settings.searchValue?.disabled;

    if (labelPath && valuePath) {
      const label = getNestedFieldData(fieldData, labelPath);
      const value = getNestedFieldData(fieldData, valuePath);
      const dataValue = this.getDataValue(settings.searchValue?.data, fieldData);
      const disabled = disabledPath ? getNestedFieldData(fieldData, disabledPath) === true : false;
      return { value, label, data: dataValue, disabled: disabled };
    }
    return null;
  }

  private getDataValue(
    dataPath: string | Record<string, unknown> | undefined,
    fieldData: Record<string, unknown> | undefined | null,
  ): Record<string, unknown> | null {
    if (!dataPath || !fieldData) return null;

    if (typeof dataPath === 'string') {
      const data = dot.pick(dataPath, fieldData);
      if (!data) return null;
      return typeof data === 'object' && !Array.isArray(data) ? data : { dataPath: data };
    }

    const result: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(dataPath)) {
      if (key && typeof value === 'string') {
        const pickedValue = dot.pick(value, fieldData);
        result[key] = pickedValue;
      }
    }
    return Object.keys(result).length > 0 ? result : null;
  }
}
