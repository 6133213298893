import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as Get from '@npm-libs/ng-getx';
import { DescriptionSetting, RcgFieldType, SelectOption } from '@rcg/core';
import { MessageService } from '@rcg/standalone';
import * as dot from 'dot-object';
import { Subscription, catchError, debounceTime, distinctUntilChanged, map, of, startWith, switchMap, tap } from 'rxjs';
import { FieldAction } from '../../../models/field-action';
import { FieldsActionsService } from '../../../services/fields-actions.service';
import { getNestedFieldData } from '../../../utils/field-path-utils';
import { AcTreeSettings } from '../models/ac-settings';
import { AutocompleteService } from '../services/ac.service';
import { AcDependentFieldsHelper } from '../utils/ac-utils';

@Get.NgAutoDispose
@Component({
  selector: 'rcg-autocomplete-tree-field',
  templateUrl: './autocomplete-tree-field.component.html',
  styleUrls: ['./autocomplete-tree-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AutocompleteService],
})
export class AutocompleteTreeFieldComponent extends RcgFieldType implements OnInit, OnDestroy {
  isLoading = false;
  errorMessage: string | null = null;
  optionsData: SelectOption[] = [];

  selectableGroup!: boolean;

  private autocompleteSubscription?: Subscription;
  private acSettings!: AcTreeSettings;

  private dependentFieldsHelper!: AcDependentFieldsHelper;

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  get showNoData(): boolean {
    const val = this.formCtrl?.value;
    return !this.isLoading && this.optionsData.length === 0 && val && typeof val === 'string' && val.length > 0;
  }
  private changeDetector = inject(ChangeDetectorRef);
  private acService = inject(AutocompleteService);
  private fieldsActionsService = inject(FieldsActionsService);
  private messageService = inject(MessageService);

  actions: FieldAction[] = [];

  ngOnInit(): void {
    try {
      this.acSettings = new AcTreeSettings(this.formState, this.field?.key as string, this.props?.['settings']);
    } catch (error) {
      this.errorMessage = 'Wrong autocomplete tree settings: ' + (error as Error | undefined)?.message ?? '';
      this.changeDetector.markForCheck();
      return;
    }

    this.actions = this.acSettings.actions;
    this.selectableGroup = this.acSettings.selectableGroup ?? false;

    // set initial value
    this.value = this.getInitalValue(this.model, this.acSettings);
    this.dependentFieldsHelper = new AcDependentFieldsHelper(this.acSettings);
    if (this.dependentFieldsHelper.IsclearOndepFieldsSet) {
      this.dependentFieldsHelper.subscribeToClearOnDependentFieldsChange(this.form, this.field.key!, (clearValue) => {
        if (clearValue) {
          this.formCtrl.value === '' ? this.formCtrl.setValue(null) : this.formCtrl.setValue('');
        }
        this.optionsData = [];
        this.changeDetector.markForCheck();
      });
    }

    this.subscribeToSearch();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.autocompleteSubscription?.unsubscribe();
    this.dependentFieldsHelper?.unsubscribe();
  }

  dataDisplayFn(item: { label?: string } | undefined) {
    return item && item?.label ? item.label : (null as unknown as string);
  }

  clear() {
    this.optionsData = [];
    this.value = null;
    this.formCtrl.markAsDirty();
  }

  private subscribeToSearch() {
    this.autocompleteSubscription = this.formCtrl.valueChanges
      .pipe(
        startWith(this.value),
        debounceTime(this.acSettings.searchSettings.searchDebounceTime),
        distinctUntilChanged(),
        tap(() => {
          this.errorMessage = null;
          this.optionsData = [];
          this.isLoading = true;
          this.changeDetector.markForCheck();
        }),
        map((value) => {
          if (value === null || value === undefined) return '';
          if (typeof value === 'string') return value;
          if (typeof value !== 'object' || !value) return '';
          if (!('label' in value) || typeof value.label !== 'string') return '';

          return null;
        }),
        switchMap((search) => {
          if (typeof search !== 'string' && !search) return of([]);
          if (search === '' && this.acSettings.ignoreEmptySearch) return of([]);

          // dont get data from server when not all dependent fields have values
          if (this.dependentFieldsHelper.IsclearOndepFieldsSet) {
            if (!this.dependentFieldsHelper.allowSearch(this.model)) {
              this.optionsData = [];
              this.changeDetector.markForCheck();
              return of([]);
            }
          }

          return this.acService.getAutoCompleteTreeData(this.acSettings, { ...this.model }, search).pipe(
            catchError((err) => {
              this.errorMessage = err?.message ?? err.toString() ?? 'Napaka';
              this.optionsData = [];
              this.isLoading = false;
              this.changeDetector.markForCheck();
              console.error('Error loading autocomplete tree data', err);
              return of([]);
            }),
          );
        }),
      )
      .subscribe({
        next: (data) => {
          this.optionsData = data;
          this.isLoading = false;
          this.changeDetector.markForCheck();
        },
      });
  }

  private getInitalValue(model: Record<string, unknown>, settings: AcTreeSettings): SelectOption | null {
    if (!settings.initalValueFromModel) return null;

    const labelName = settings.initalValueFromModel?.label;
    const valueName = settings.initalValueFromModel?.value;
    if (!labelName || !valueName) return null;

    const value = getNestedFieldData(model, valueName);
    if (!value) return null;

    const label = getNestedFieldData(model, labelName);
    if (!label) return null;

    let additionalData: Record<string, unknown> = {};
    const dataPath = settings.initalValueFromModel?.data;
    if (dataPath) {
      additionalData = dot.pick(dataPath, model);
    }
    return { label: label, value: value, data: additionalData };
  }

  descriptionsArray(item: DescriptionSetting[] | string | undefined): DescriptionSetting[] {
    return item as DescriptionSetting[];
  }

  isArray(item: DescriptionSetting[] | string | undefined): boolean {
    return Array.isArray(item);
  }

  isString(item: DescriptionSetting[] | string | undefined): boolean {
    return typeof item === 'string';
  }

  async executeAction(action: FieldAction) {
    await this.fieldsActionsService.exececuteFieldAction(action, this.model, this.field, this.formCtrl.value, this.form, (input) => {
      try {
        action.afterExecuteAction(input);
        this.formCtrl.markAsDirty();
        this.changeDetector.markForCheck();
      } catch (error) {
        this.messageService.showErrorSnackbar(
          'Error form field action after submit',
          (error as { message: string | undefined })?.message ?? 'Unknown error',
        );
      }
    });
  }
}
