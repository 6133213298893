import { Injectable } from '@angular/core';
import { AuthService, RcgTenant, RcgUser } from '@rcg/auth';
import { IPhoneCall, RcgListItem, ShortcutService } from '@rcg/core';
import { MessageService } from '@rcg/standalone';
import { Observable, from, switchMap } from 'rxjs';
import { OnActionResult } from '../models';
import {
  ActionsRequestInput,
  FormFieldActionsRequestInput,
  IAction,
  IFormActions,
  IHelpdeskActions,
  IPhoneActions,
} from '../models/actions';
import { FabActions } from './action-handlers/fab-actions';
import { FormFieldsActions } from './action-handlers/form-fields-actions';
import { HttpRequestActions } from './action-handlers/http-action';
import { MutationActions } from './action-handlers/mutation-actions';

export interface IOpenFormInput {
  formId: number;
  recordId: number;
  title: string;
  parentId?: number;
}

/* 
   Actions facade service - clients can call only this service for actions, not separated action handlers
*/

@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  constructor(
    private messageService: MessageService,
    private httpActions: HttpRequestActions,
    private mutationActions: MutationActions,
    private fabActions: FabActions,
    private formFieldsActions: FormFieldsActions,
    shortcutService: ShortcutService,
    authService: AuthService,
  ) {
    shortcutService.registerShortcut({
      category: 'ServiceDesk',
      id: 'addEvent',
      icon: 'add',
      nameTr: 'shortcut_servicedesk_add_event_name',
      descriptionTr: 'shortcut_servicedesk_add_event_description',
      preventDefault: true,
      onTrigger: async () => {
        const user = authService.user();
        const tenant = authService.tenant();

        if (!user || !tenant) {
          this.messageService.showErrorSnackbar('[AddEvent shortcut] User or tenant not found');
          return;
        }

        await this.addNewHelpdeskItem(user, tenant, null);
      },
    });
  }

  private __formActions?: IFormActions;
  public registerFormActions: (formActions: IFormActions) => void = (a) => (this.__formActions = a);
  private _formActions = new Promise<IFormActions>((res) => {
    if (this.__formActions) res(this.__formActions);
    else this.registerFormActions = res;
  });

  public get formActions() {
    return this._formActions;
  }

  private __phoneActions?: IPhoneActions;
  public registerPhoneActions: (PhoneActions: IPhoneActions) => void = (a) => (this.__phoneActions = a);
  private _phoneActions = new Promise<IPhoneActions>((res) => {
    if (this.__phoneActions) res(this.__phoneActions);
    else this.registerPhoneActions = res;
  });

  private __helpdeskActions?: IHelpdeskActions;
  public registerHelpdeskActions: (HelpdeskActions: IHelpdeskActions) => void = (a) => (this.__helpdeskActions = a);
  private _helpdeskActions = new Promise<IHelpdeskActions>((res) => {
    if (this.__helpdeskActions) res(this.__helpdeskActions);
    else this.registerHelpdeskActions = res;
  });

  async refreshFormActions() {
    (await this._formActions).refreshFormActions();
  }

  getFormActions(input: ActionsRequestInput): Observable<IAction[]> {
    return from(this._formActions).pipe(switchMap((fa) => fa.getFormActions(input)));
  }

  getFormFieldActions(input: FormFieldActionsRequestInput): Observable<IAction[]> {
    return this.formFieldsActions.getFormFieldActions(input);
  }

  private readonly _actionByNameRegistry: Record<string, ((action: IAction) => unknown | Promise<unknown>) | undefined> = {
    'add-helpdesk-item': async ({ data }) => (await this._helpdeskActions).addNewHelpdeskItem(data?.user, data?.tenant, data?.phoneCall),
    'link-phone-call': async ({ record, data }) => {
      if (!record || !data?.user) return;
      await (await this._phoneActions).linkPhoneCall(record as IPhoneCall, data.user, data.tenant);
    },
    'link-active-phone-call': () => null, // TODO: implement
    'add-knowledgebase': async ({ data }) => (await this._helpdeskActions).addNewKnowledgeBaseItem(data?.tenant),
    'add-problem': async ({ data }) => (await this._helpdeskActions).addNewProblem(data?.tenant),
    'add-installed-equipment': async ({ data }) => (await this._helpdeskActions).addInstalledEquipment(data?.tenant),
    'add-warehouse': async ({ data }) => (await this._helpdeskActions).addWarehouse(data?.tenant),
  };

  private readonly _actionByMethodRegistry: Record<
    string,
    ((action: IAction, onActionResult?: OnActionResult) => unknown | Promise<unknown>) | undefined
  > = {
    'form-dialog': async (action, onActionResult) => (await this._formActions).openFormDialog(action, (result) => onActionResult?.(result)),
    'confirm-mutation': (action) => this.mutationActions.executeConfirmMutation(action),
    mutation: (action) => this.mutationActions.executeMutation(action),
    'http-request': (action) => this.httpActions.executeHttpRequest(action),
  };

  async executeAction(action: IAction, onActionResult?: OnActionResult): Promise<void> {
    if (!action || !action.enabled === true || !action.visible === true || !action.name) {
      this.messageService.showErrorSnackbar(
        `Wrong action parameters. Action name: ${action?.name} Enabled: ${action?.enabled} Visible: ${action?.visible}`,
      );
      return;
    }

    try {
      // old or custom action handler
      if (!action.config || Object.keys(action.config).length === 0) {
        const actionByName = this._actionByNameRegistry[action.name];

        if (actionByName) {
          await actionByName(action);
          return;
        }
      }

      if (action.method) {
        const actionByMethod = this._actionByMethodRegistry[action.method];

        if (actionByMethod) {
          await actionByMethod(action, onActionResult);
          return;
        }
      }

      this.messageService.showErrorSnackbar(`Action not supported: ${action.name}/${action.method}`);
    } catch (error) {
      this.messageService.showErrorSnackbar(`Action error:`, (error as Error | undefined)?.message || error?.toString());
    }
  }

  /*
     Custom actions
  */

  getFabActions(
    tenant: RcgTenant,
    category: string,
    selectedHelpdeskItem: RcgListItem | null,
    isEndUser: boolean,
    moduleId: number,
  ): {
    alwaysExpanded: boolean;
    actions: IAction[];
  } {
    return this.fabActions.getFabActions(tenant, category, selectedHelpdeskItem, isEndUser, moduleId);
  }

  async addNewHelpdeskItem(user: RcgUser | undefined, tenant: RcgTenant | undefined, phoneCall: IPhoneCall | null) {
    return await (await this._helpdeskActions).addNewHelpdeskItem(user, tenant, phoneCall);
  }

  async openFormAction(input: IOpenFormInput, dialogId: string): Promise<void> {
    return await (await this._formActions).openFormAction(input, dialogId);
  }

  async makePhoneCall(phoneCall: IPhoneCall, user: RcgUser | undefined, tenant: RcgTenant | undefined) {
    await (await this._phoneActions).makePhoneCall(phoneCall, user, tenant);
  }
}
