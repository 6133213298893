import { gql } from 'apollo-angular';

export const debtorQuery = gql`
  query debtorQuery($organizationId: bigint!, $customerId: bigint, $search: String!, $limit: Int!, $offset: Int!) {
    data: finance_search_open_items_debtor(
      args: { search: $search }
      limit: $limit
      offset: $offset
      order_by: { document_due: asc }
      where: { _and: [{ organization_id: { _eq: $organizationId } }, { customer_id: { _eq: $customerId } }] }
    ) {
      id
      organization_id
      paid
      partner
      updated_at
      value
      organization {
        short_name
        id
        bankAccountsBalance {
          bank_name
        }
      }
      balance
      document_date
      document_due
      document_no
      created_at
    }
  }
`;

export const countDebtorQuery = gql`
  query countDebtorQuery($organizationId: bigint!, $customerId: bigint, $search: String!) {
    data: finance_search_open_items_debtor_aggregate(
      args: { search: $search }
      where: { _and: [{ organization_id: { _eq: $organizationId } }, { customer_id: { _eq: $customerId } }] }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const sumDebtorsBalancesQuery = gql`
  query debtorAggregatesQuery($organizationId: bigint!, $customerId: bigint, $search: String!, $currentDate: date!) {
    all_balances: finance_search_open_items_debtor_aggregate(
      args: { search: $search }
      where: { _and: [{ organization_id: { _eq: $organizationId } }, { customer_id: { _eq: $customerId } }] }
    ) {
      aggregate {
        sum {
          balance
        }
      }
    }
    due_balances: finance_search_open_items_debtor_aggregate(
      args: { search: $search }
      where: {
        _and: [
          { organization_id: { _eq: $organizationId } }
          { customer_id: { _eq: $customerId } }
          { document_due: { _lt: $currentDate } }
        ]
      }
    ) {
      aggregate {
        sum {
          balance
        }
      }
    }
  }
`;
