import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EditorEditMode, IEditorValue } from '@rcg/core';
import { replaceTextLineBreaks } from '@rcg/standalone';
import { EditorFormat } from '../fields/html-editor-field/format';

@Pipe({
  name: 'editorContent',
  pure: true,
})
export class EditorContentPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    value: IEditorValue,
    originalEmlHtml: string | undefined | null,
    format: EditorFormat,
    showsOriginalEml: boolean,
    editorMode: EditorEditMode,
  ): string | SafeHtml {
    if (showsOriginalEml && editorMode !== 'edit') {
      return this.sanitizer.bypassSecurityTrustHtml(originalEmlHtml ?? '');
    }

    if (format === 'html') {
      if (editorMode == 'edit') {
        return value?.html ?? '';
      }
      return this.sanitizer.bypassSecurityTrustHtml(value?.html ?? '');
    }

    if (format === 'text') {
      return `<pre>${replaceTextLineBreaks(value?.plain)}</pre>`;
    }

    console.error('Editor editContent pipe error - wrong inputs.', format, showsOriginalEml, value);
    return '';
  }
}
