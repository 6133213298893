import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInputEvent } from '@angular/material/chips';
import { gql } from '@apollo/client/core';
import * as Get from '@npm-libs/ng-getx';
import { ActionConfig, DataReferenceContext, ViewConfig, parseAction } from '@npm-libs/ng-templater';
import { RcgFieldType, RcgFormlyFieldProps } from '@rcg/core';
import { GraphqlClientService } from '@rcg/graphql';
import { isNonNullable } from '@rcg/utils';
import * as dot from 'dot-object';
import {
  Subscription,
  combineLatestWith,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeWith,
  of,
  skip,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { getNestedFieldData } from '../../utils';
import { AutocompleteService } from '../autocomplete/services/ac.service';

interface ChipsAutocompleteSettings {
  query: string;
  dataPath?: string;
  variables?: Record<string, unknown>;
  modelVariables?: Record<string, string>;
  searchVariable?: string;
  optionTemplate: ViewConfig<string, unknown>;
  asHint?: boolean;
  valueField?: string;
  prefix?: string;
  suffix?: string;
}

export interface ChipsSettings {
  initalValueFromModel?: {
    value?: string;
  };
  autocomplete?: ChipsAutocompleteSettings;
  chipTemplate: ViewConfig<string, unknown>;
  initialValueMapper?: (value: unknown[]) => unknown[] | Promise<unknown[]>;
  chipValueMapper?: (value: unknown) => unknown;
  chipClickAction?: ActionConfig;
}

@Get.NgAutoDispose
@Component({
  selector: 'rcg-field-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  providers: [AutocompleteService],
})
export class ChipsComponent
  extends RcgFieldType<unknown[] | undefined, RcgFormlyFieldProps<Record<string, unknown>, ChipsSettings>>
  implements OnInit, OnDestroy
{
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly gqlService = inject(GraphqlClientService);

  public readonly of = of;

  readonly separatorKeysCodes: number[] = [COMMA, ENTER, SEMICOLON];

  @ViewChild('chipGrid') chipGrid?: MatChipGrid;

  formControlValueChangesSub?: Subscription;

  errors?: ValidationErrors | null;

  private readonly acSettingsInR = new Get.Rx<ChipsAutocompleteSettings | undefined>(undefined);
  private readonly acSettingsR = this.acSettingsInR.pipe(
    map((settingsIn) => {
      if (!settingsIn) return undefined;

      const modelVars: Record<string, unknown> = {};
      for (const [key, path] of Object.entries(settingsIn.modelVariables ?? {})) {
        modelVars[key] = getNestedFieldData(this.model, path ?? null);
      }

      return {
        ...settingsIn,
        query: settingsIn.query ? gql(settingsIn.query) : undefined,
        getVariables: () => {
          return {
            ...settingsIn.variables,
            ...modelVars,
          };
        },
      };
    }),
  );

  public autocompleteLoading = false;

  private readonly autocompleteSearchR = ''.obs();
  public readonly searchAutocomplete = (value: string) => (this.autocompleteSearchR.data = value);

  private tapLoading<T>(loading: boolean) {
    return tap<T>(() => {
      this.autocompleteLoading = loading;
      this.changeDetectorRef.markForCheck();
    });
  }

  private readonly autocompleteOptionsR = this.autocompleteSearchR.pipe(
    distinctUntilChanged(),
    this.tapLoading(true),
    debounceTime(300),
    combineLatestWith(this.acSettingsR.value$.pipe(filter(isNonNullable))),
    switchMap(([search, acSettings]) => {
      if (!acSettings.query) {
        console.warn('No query in chips autocomplete settings:', this.props?.settings?.autocomplete);

        this.autocompleteLoading = false;
        this.changeDetectorRef.markForCheck();

        return of([]);
      }

      this.autocompleteLoading = true;
      this.changeDetectorRef.markForCheck();

      const data$ = this.gqlService
        .query({
          query: acSettings.query,
          variables: {
            ...(acSettings.searchVariable
              ? { [acSettings.searchVariable]: `${acSettings.prefix ?? ''}${search}${acSettings.suffix ?? ''}` }
              : {}),
            ...acSettings.getVariables(),
          },
        })
        .pipe(
          map((data) => getNestedFieldData(data, acSettings.dataPath ?? 'data')),
          map((data) => (Array.isArray(data) ? data : [data])),
        );

      return data$.pipe(
        delay(500),
        this.tapLoading(false),
        mergeWith(
          this.autocompleteSearchR.value$.pipe(
            distinctUntilChanged(),
            skip(1),
            take(1),
            map(() => []),
            this.tapLoading(true),
          ),
        ),
      );
    }),
  );

  readonly autocompleteOptions$ = this.autocompleteOptionsR.value$;

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  get errorKeys() {
    if (!this.errors) return [];
    return Object.keys(this.errors).filter((e) => this.errors?.[e]);
  }

  async ngOnInit() {
    this.ensureArray();
    this.acSettingsInR.data = this.props?.settings?.autocomplete;

    const initalValueFromModelValue = this.props?.settings?.initalValueFromModel?.value;
    const initialValueMapper = this.props?.settings?.initialValueMapper;

    if (initalValueFromModelValue) {
      this.value = dot.pick(initalValueFromModelValue, this.model) ?? [];
    } else if (initialValueMapper) {
      if (typeof initialValueMapper === 'function') {
        this.value = await initialValueMapper(this.value as unknown[]);
      } else {
        console.error('[chips field]', 'Initial value mapper is not a function:', initialValueMapper);
      }
    }

    this.formControlValueChangesSub = this.formCtrl.valueChanges.subscribe(() => {
      this.errors = this.formCtrl.errors;
      this.chipGrid!.errorState = (this.errors && Object.keys(this.errors).length > 0) ?? false;
    });
  }

  override ngOnDestroy(): void {
    this.formControlValueChangesSub?.unsubscribe();
  }

  ensureArray() {
    if (!Array.isArray(this.formCtrl.value)) this.formCtrl.setValue([]);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const v = (value || '').trim();

    if (v) {
      this.ensureArray();
      this.formCtrl.setValue([...this.formCtrl.value, v]);
      this.formCtrl.markAsDirty();
    }

    if (input) input.value = '';
  }

  remove(index: number): void {
    if (index >= 0) {
      this.ensureArray();

      const v = this.formCtrl.value as unknown[];
      this.formCtrl.setValue([...v.slice(0, index), ...v.slice(index + 1)]);
      this.formCtrl.markAsDirty();
    }
  }

  autocompleteSelected(event: MatAutocompleteSelectedEvent, input: HTMLInputElement) {
    this.ensureArray();
    if (this.props?.settings?.autocomplete?.asHint === true) {
      this.formCtrl.setValue([...this.formCtrl.value, event.option.value[this.props!.settings!.autocomplete!.valueField!.toString()]]);
    } else {
      this.formCtrl.setValue([...this.formCtrl.value, event.option.value]);
    }
    this.formCtrl.markAsDirty();

    input.value = '';
    this.searchAutocomplete('');
  }

  async chipClicked(item: unknown) {
    const actionConfig = this.props.settings?.chipClickAction;
    if (!actionConfig || typeof actionConfig !== 'object') return;

    const context: DataReferenceContext = {
      runtimeData$: of({}),
      data$: of({}),
      context$: of({ item }),
    };

    const action = parseAction(context, () => {}, actionConfig);
    await action();
  }
}
