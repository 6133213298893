import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnChanges, OnDestroy, OnInit, signal, SimpleChanges } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { GqlInput, InfinityListService, RcgListItem, RcgPipesModule } from '@rcg/core';
import { GraphqlClientService } from '@rcg/graphql';
import { IntlModule } from '@rcg/intl';
import { RcgVirtualListComponent } from '@rcg/standalone';
import { BehaviorSubject, EMPTY, firstValueFrom, Subscription, switchMap, tap } from 'rxjs';
import { debtorQuery, sumDebtorsBalancesQuery } from './gql';
import { IsDueDatePipe } from './pipes';

@Component({
  standalone: true,
  selector: 'rcg-accounts-receivable',
  templateUrl: './accounts-receivables.component.html',
  styleUrls: ['./accounts-receivables.component.scss'],
  imports: [CommonModule, RcgVirtualListComponent, MatCardModule, MatIconModule, IntlModule, IsDueDatePipe, RcgPipesModule],
  providers: [InfinityListService],
})
export class AccountsReceivablesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() organizationId!: number;
  @Input() customerId!: number;

  readonly infinityListService = inject(InfinityListService);
  private readonly graphQlClient: GraphqlClientService = inject(GraphqlClientService);
  private customerOrgParam = new BehaviorSubject<{ organizationId: number; customerId: number } | null>(null);
  private subscription: Subscription | null = null;
  private readonly destroyRef = inject(DestroyRef);

  balancesSumData = signal<
    | {
        allBalances: number | undefined;
        dueBalances: number | undefined;
      }
    | undefined
  >(undefined);

  ngOnInit(): void {
    this.subscription = this.customerOrgParam
      .pipe(
        tap(() => {
          this.balancesSumData.set(undefined);
          this.loadData(this.organizationId, this.customerId);
        }),
        switchMap((params) => {
          if (params) {
            return this.getSumData(params.organizationId, params.customerId);
          }
          return EMPTY;
        }),
      )
      .subscribe({
        next: (sum) => {
          this.balancesSumData.set(sum ?? undefined);
        },
        error: (err) => {
          console.error('Error fetching accounts receivable sum', err);
          this.balancesSumData.set(undefined);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const orgIdChanged =
      changes['organizationId'] &&
      changes['organizationId'].currentValue !== undefined &&
      changes['organizationId'].currentValue !== changes['organizationId'].previousValue;

    const customerIdChanged =
      changes['customerId'] &&
      changes['customerId'].currentValue !== undefined &&
      changes['customerId'].currentValue !== changes['customerId'].previousValue;

    if (orgIdChanged || customerIdChanged) {
      this.customerOrgParam.next({ organizationId: this.organizationId, customerId: this.customerId });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private loadData(organizationId: number, customerId: number): void {
    if (!organizationId || !customerId) {
      return;
    }

    const gql: GqlInput<RcgListItem> = {
      searchable: false,
      type: 'query',
      query: debtorQuery,
      variables: {
        organizationId: organizationId,
        customerId: customerId,
        offset: 0,
        limit: 800,
        search: '',
      },
    };

    this.infinityListService.loadData(gql, undefined, undefined);
    this.getSumData(organizationId, customerId);
  }

  private async getSumData(organizationId: number, customerId: number) {
    const result = await firstValueFrom(
      this.graphQlClient.query<{
        all_balances: { aggregate: { count: number; sum: { balance: number } } };
        due_balances: { aggregate: { sum: { balance: number } } };
      }>({
        query: sumDebtorsBalancesQuery,
        variables: {
          organizationId,
          customerId,
          currentDate: new Date().toISOString().split('T')[0],
          search: '',
        },
      }),
    );
    return {
      allBalances: result?.all_balances.aggregate.sum.balance,
      dueBalances: result?.due_balances.aggregate.sum.balance,
    };
  }
}
