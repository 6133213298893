import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Attachment,
  AttachmentsDialogConfig,
  EditorDialogConfig,
  EmlAttachment,
  IFormDialogConfig,
  IFormlyCustomFormDialogConfig,
} from '@rcg/core';
import { map, of, tap } from 'rxjs';
import { AttachmentsViewerDialogComponent } from '../containers/attachments-viewer-dialog/attachments-viewer-dialog.component';
import { EditorDialogComponent } from '../containers/editor-dialog/editor-dialog.component';
import { FormlyCustomFormDialogComponent } from '../containers/formly-custom-form-dialog/formly-custom-form-dialog.component';
import { FormlyFormDialogComponent } from '../containers/formly-form-dialog/formly-form-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class FormDialogService {
  constructor(public dialog: MatDialog) {}

  openedDialogIds: Record<string, string> = {};

  addOpenedDialogId(id: string) {
    if (!this.openedDialogIdExist(id)) {
      this.openedDialogIds[id] = id;
    }
  }

  removeOpenedDialogId(id: string) {
    if (this.openedDialogIdExist(id)) {
      delete this.openedDialogIds[id];
    }
  }

  openedDialogIdExist(id: string): boolean {
    return !!this.openedDialogIds[id];
  }

  openForm(formConfig: IFormDialogConfig) {
    formConfig.openFullscreen = window.innerWidth / window.innerHeight < 1 ? true : false;
    this.dialog.open(FormlyFormDialogComponent, {
      data: formConfig,
      autoFocus: false,
      height: formConfig?.height ?? '70%',
      width: formConfig?.width ?? '50%',
    });
  }

  openFormWithResult(formConfig: IFormDialogConfig, dialogId: string) {
    const dId = dialogId?.trim();
    if (dId) {
      if (this.openedDialogIdExist(dId)) {
        return of(undefined);
      }
      this.addOpenedDialogId(dId);
    }

    formConfig.openFullscreen = window.innerWidth / window.innerHeight < 1 ? true : false;

    return this.dialog
      .open(FormlyFormDialogComponent, {
        data: formConfig,
        autoFocus: false,
        height: formConfig?.height ?? '70%',
        width: formConfig?.width ?? '50%',
      })
      .afterClosed()
      .pipe(
        tap(() => {
          if (dId) {
            this.removeOpenedDialogId(dId);
          }
        }),
        map((result) => result),
      );
  }

  openCustomForm(formConfig: IFormlyCustomFormDialogConfig) {
    formConfig.openFullscreen = window.innerWidth / window.innerHeight < 1 ? true : false;
    this.dialog.open(FormlyCustomFormDialogComponent, {
      data: formConfig,
      autoFocus: false,
      height: formConfig?.height ?? '70%',
      width: formConfig?.width ?? '50%',
    });
  }

  openAttachmentsViewer<T extends Attachment | EmlAttachment>(viewerConfig: AttachmentsDialogConfig<T>) {
    viewerConfig.openFullscreen = window.innerWidth / window.innerHeight < 1 ? true : false;
    this.dialog.open(AttachmentsViewerDialogComponent, {
      data: viewerConfig,
      autoFocus: false,
      height: viewerConfig?.height ?? '80%',
      width: viewerConfig?.width ?? '60%',
    });
  }

  openEditor(viewerConfig: EditorDialogConfig) {
    viewerConfig.openFullscreen = window.innerWidth / window.innerHeight < 1 ? true : false;
    this.dialog.open(EditorDialogComponent, {
      data: viewerConfig,
      autoFocus: false,
      height: viewerConfig?.height ?? '80%',
      width: viewerConfig?.width ?? '80%',
    });
  }
}
