import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { RcgFieldType, SelectOption } from '@rcg/core';
import { MessageService } from '@rcg/standalone';
import { Subscription, catchError, debounceTime, distinctUntilChanged, of, skip, switchMap, tap } from 'rxjs';
import { FieldAction } from '../../../models';
import { FieldsActionsService } from '../../../services';
import { ACSettings } from '../models/ac-settings';
import { AutocompleteService } from '../services/ac.service';

@Component({
  selector: 'rcg-hint-field',
  templateUrl: './hint-field.component.html',
  styleUrls: ['./hint-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AutocompleteService],
})
export class HintFieldComponent extends RcgFieldType implements OnInit, OnDestroy {
  optionsData: SelectOption[] = [];
  isLoading = false;
  errorMessage: string | null = null;
  acSettings!: ACSettings;

  private hintSearchSubscription?: Subscription;
  private _nextFocusValueSub?: Subscription;

  get formCtrl() {
    return this.formControl;
  }

  actions: FieldAction[] = [];

  constructor(
    private acService: AutocompleteService,
    private changeDetector: ChangeDetectorRef,
    private fieldsActionsService: FieldsActionsService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit() {
    this.formCtrl.markForChangeCheck = () => this.changeDetector.markForCheck();

    this.hintSearchSubscription?.unsubscribe();

    try {
      this.acSettings = new ACSettings(this.formState, this.field.key as string, this.props?.['settings']);
    } catch (error) {
      this.errorMessage = 'Wrong autocomplete hint field settings: ' + (error as Error)?.message ?? '';
      this.changeDetector.markForCheck();
      return;
    }

    // set inital field value
    const key = this.field.key as string;
    const fieldData = this.model[key];
    this.value = fieldData ?? null;

    if (this.props?.readonly === true || this.props?.disabled === true) {
      return;
    }

    if (!this.acSettings.query) {
      console.error(`Query settings not set for hint field ${this.field?.key}`);
      this.errorMessage = 'Wrong hint field settings. No query settings.';
      this.changeDetector.markForCheck();
      return;
    }

    this.actions = this.acSettings.actions;

    this.subscribetoToSearch();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.hintSearchSubscription?.unsubscribe();
    this._nextFocusValueSub?.unsubscribe();
  }

  clear() {
    this.optionsData = [];
    this.value = null;
    this.formCtrl.markAsDirty();
    this.markFieldsForChangeCheck();
    this.prefillDependentFieldsOnSelectedValue(null);
  }

  onSelectedValue(selectedValue: MatAutocompleteSelectedEvent) {
    const selectOption: SelectOption | null | undefined = selectedValue.option.value;
    this.value = selectOption?.value; // set field value
    this.prefillDependentFieldsOnSelectedValue(selectOption);
  }

  dataDisplayFn(item: { label?: string } | string) {
    if (typeof item === 'string') return item;
    return item && item?.label ? item.label : (null as unknown as string);
  }

  private subscribetoToSearch() {
    this.hintSearchSubscription = this.formCtrl.valueChanges
      .pipe(
        skip(0),
        debounceTime(this.acSettings.searchSettings.searchDebounceTime),
        distinctUntilChanged(),
        tap(() => {
          this.errorMessage = null;
          this.isLoading = true;
          this.changeDetector.markForCheck();
          this.markFieldsForChangeCheck();
        }),
        switchMap((val) => {
          if (typeof val !== 'string') {
            return of([]);
          }
          return this.acService.getAutoCompleteData(this.acSettings, this.model, val, true).pipe(
            catchError((err) => {
              this.errorMessage = err?.message ?? err.toString() ?? 'Napaka';
              this.optionsData = [];
              this.isLoading = false;
              this.changeDetector.markForCheck();
              return of([]);
            }),
          );
        }),
      )
      .subscribe({
        next: (data) => {
          this.optionsData = data;
          this.isLoading = false;
          this.changeDetector.markForCheck();
        },
      });
  }

  private prefillDependentFieldsOnSelectedValue(selectOption: SelectOption | null | undefined) {
    if (!this.acSettings.prefillDependentFildsOnSelected) {
      return;
    }

    for (const [key, value] of Object.entries(this.acSettings.prefillDependentFildsOnSelected!)) {
      if (!this.form.controls[key]) continue;

      if (selectOption?.data && selectOption.data[value]) {
        this.form.get(key)?.setValue(selectOption.data[value]);
      } else {
        this.form.get(key)?.setValue(null);
      }
    }
  }

  private markFieldsForChangeCheck() {
    for (const c of Object.values(this.form.controls)) {
      try {
        c?.markForChangeCheck?.();
      } catch (_) {
        // Ignore
      }
    }
  }

  async executeAction(action: FieldAction) {
    await this.fieldsActionsService.exececuteFieldAction(action, this.model, this.field, this.formCtrl.value, this.form, (input) => {
      try {
        action.afterExecuteAction(input);
        this.formCtrl.markAsDirty();
        this.changeDetector.markForCheck();
      } catch (error) {
        this.messageService.showErrorSnackbar(
          'Error form field action after submit',
          (error as { message: string | undefined })?.message ?? 'Unknown error',
        );
      }
    });
  }
}
