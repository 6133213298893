import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import * as Get from '@npm-libs/ng-getx';
import { RcgFieldType } from '@rcg/core';
import { IntlService } from '@rcg/intl';
import { RRule, assembleRRuleString, fixPgRRule, parseRecurrenceRule, rRuleToRecord, recordToRRule } from '@rcg/standalone';
import { RecurrenceEditorChangeEventArgs, RecurrenceEditorModule } from '@syncfusion/ej2-angular-schedule';
import { distinctUntilChanged, map } from 'rxjs';

@Get.NgAutoDispose
@Component({
  selector: 'rcg-recurrence-editor',
  templateUrl: './recurrence-editor.component.html',
  styleUrls: ['./recurrence-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RecurrenceEditorModule],
})
export class RecurrenceEditorFieldComponent extends RcgFieldType implements OnInit {
  public readonly sfLocale$ = inject(IntlService).syncfusionLocale$;

  private rRuleValueR = new Get.Rx<RRule | null>(null);

  private strValueR = this.rRuleValueR.value$
    .pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      map((val) => {
        if (typeof val !== 'object' || !val || !('freq' in val)) return '';
        return assembleRRuleString(val as RRule);
      }),
    )
    .obs();

  public strValue$ = this.strValueR.value$;

  ngOnInit(): void {
    this.rRuleValueR.value$.subscribe((val) => {
      if (val === null || val === undefined) return;
      this.formControl.setValue(`(${rRuleToRecord(val, false)})`);
    });

    this.rRuleValueR.subscribeTo(
      this.formControl.valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        map((val) => {
          if (typeof val === 'string') {
            if (val.startsWith('(')) return recordToRRule(val, false);
            return parseRecurrenceRule(val);
          }

          if (typeof val !== 'object' || !val) return null;
          return fixPgRRule(val as RRule, 'UTC');
        }),
      ),
    );

    setTimeout(() => {
      this.formControl.setValue(this.formControl.value);
    }, 1);
  }

  onRecurrenceChange(args: RecurrenceEditorChangeEventArgs): void {
    this.rRuleValueR.data = args.value ? parseRecurrenceRule(args.value) : null;
    this.formControl.markAsDirty();
  }
}
