import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { RcgFieldType, RcgFormlyFieldProps } from '@rcg/core';
import { TimeErrorStateMatcher } from '../time-error-state-matcher';

interface TimeModel {
  value: string;
  label: string;
}

interface TimeInputDoubleSettings {
  defaultTime: string;
  interval: number;
  customTimes: number[];
  fromTimeKey?: string;
  toTimeKey?: string;
  defaultTimeDifferenceMinutes?: number;
  displaySeconds?: boolean;
  displayWorkingHours?: boolean;
  width?: string;
  labels?: {
    fromLabel?: string;
    toLabel?: string;
    workingHoursLabel?: string;
  };
}

@Component({
  selector: 'rcg-time-input-double',
  templateUrl: './time-input-double.component.html',
  styleUrls: ['./time-input-double.component.scss'],
})
export class TimeInputDoubleComponent
  extends RcgFieldType<unknown, RcgFormlyFieldProps<Record<string, unknown>, TimeInputDoubleSettings>>
  implements OnInit, OnDestroy
{
  @ViewChild('fromInput') fromInput!: ElementRef<HTMLInputElement>;
  @ViewChild('toInput') toInput!: ElementRef<HTMLInputElement>;

  defaultWidth!: string;

  autoOptions!: TimeInputDoubleSettings;

  fromFormControl = new UntypedFormControl();
  toFormControl = new UntypedFormControl();
  workingHoursFC = new UntypedFormControl();

  fromTimeErrMatcher = new TimeErrorStateMatcher();
  toTimeErrMatcher = new TimeErrorStateMatcher();

  timeDiffSec = 0;

  times?: TimeModel[] = [];
  fromOptions?: TimeModel[] = [];
  toOptions?: TimeModel[] = [];

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  timeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.testTimeString(control.value) ? null : { invalidTime: control.value };
    };
  }

  ngOnInit(): void {
    if (this.props.disabled) {
      this.fromFormControl.disable();
      this.toFormControl.disable();
      this.workingHoursFC.disable();
    }

    this.fromFormControl.addValidators([this.timeValidator()]);
    this.toFormControl.addValidators([this.timeValidator()]);

    this.autoOptions = this.props.settings!;

    this.defaultWidth = this.autoOptions.displaySeconds ? '90px' : '70px';
    if (this.autoOptions.width) {
      this.defaultWidth = this.autoOptions.width;
    }

    let startTime = '';
    let endTime = '';

    if (!this.autoOptions.fromTimeKey || !this.autoOptions.toTimeKey) {
      const timeArray = this.model[this.key as string];
      startTime = timeArray[0];
      endTime = timeArray[1];
    } else {
      startTime = this.model[this.autoOptions.fromTimeKey!];
      endTime = this.model[this.autoOptions.toTimeKey!];
    }

    if (!(this.testTimeString(startTime) && this.testTimeString(endTime))) {
      if (!this.autoOptions.defaultTime) {
        const d = new Date(Date.now());
        startTime = this.formatTime(d);
        endTime = startTime;
      } else {
        startTime = this.autoOptions.defaultTime;
        endTime = this.autoOptions.defaultTime;
      }

      if (this.autoOptions.defaultTimeDifferenceMinutes) {
        endTime = this.addToTime(endTime, this.autoOptions.defaultTimeDifferenceMinutes * 60);
      }
    }

    // this.value = [startTime, endTime];

    this.timeDiffSec = this.calculateTimeDifference(startTime, endTime);
    if (this.timeDiffSec < 0) {
      this.timeDiffSec = 0;
      endTime = startTime;
    }

    this.fromFormControl.setValue(startTime);
    this.toFormControl.setValue(endTime);

    if (!this.autoOptions.interval) {
      this.autoOptions.interval = 60;
    }

    let t = this.autoOptions.displaySeconds! ? '00:00:00' : '00:00';
    for (let i = 0; i < 1440 / this.autoOptions.interval; i++) {
      this.fromOptions!.push({ value: t, label: t });
      t = this.addToTime(t, this.autoOptions.interval * 60);
    }

    this.updateToTimes(startTime);

    this.updateHostFieldValue(false); // dont mark form as dirty on init
  }

  formatTwoDigits(n: number) {
    return n < 10 ? '0' + n : n;
  }

  formatTime(d: Date) {
    return this.autoOptions.displaySeconds
      ? `${this.formatTwoDigits(d.getHours())}:${this.formatTwoDigits(d.getMinutes())}:${this.formatTwoDigits(d.getSeconds())}`
      : `${this.formatTwoDigits(d.getHours())}:${this.formatTwoDigits(d.getMinutes())}`;
  }

  getWorkingHours() {
    const hh = Math.floor(this.timeDiffSec / 3600);
    const mm = Math.floor(this.timeDiffSec / 60) % 60;
    const ss = this.timeDiffSec % 60;
    return this.autoOptions.displaySeconds
      ? `${this.formatTwoDigits(hh)}:${this.formatTwoDigits(mm)}:${this.formatTwoDigits(ss)}`
      : `${this.formatTwoDigits(hh)}:${this.formatTwoDigits(mm)}`;
  }

  updateHostFieldValue(markAsDirty = true) {
    const fromTime = this.fromFormControl.value;
    const toTime = this.toFormControl.value;

    this.value = [fromTime, toTime];

    if (!this.autoOptions.fromTimeKey || !this.autoOptions.toTimeKey) {
      this.value = [fromTime, toTime];
    } else {
      this.form.get(this.autoOptions.fromTimeKey)?.setValue(fromTime);
      this.form.get(this.autoOptions.toTimeKey)?.setValue(toTime);
    }

    if (markAsDirty && !(this.props.disabled === true || this.props.readonly === true)) {
      this.formCtrl.markAsDirty();
    }
  }

  onFromKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event?.preventDefault();
      this.fromInput.nativeElement.blur();
    }

    const input = this.fromInput.nativeElement.value;
    if (this.testTimeString(input)) {
      this.updateFields(input);
    }
    this.updateHostFieldValue();
  }

  onToKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event?.preventDefault();
      this.toInput.nativeElement.blur();
    }

    if (!this.checkAndUpdateTimeDiffInput(this.toInput!.nativeElement.value)) {
      if (this.testTimeString(this.toInput!.nativeElement.value)) {
        const diff = this.calculateTimeDifference(this.fromFormControl.value, this.toFormControl.value);
        if (diff < 0) {
          this.fromFormControl.setValue(this.toFormControl.value);
          this.timeDiffSec = 0;
          this.updateToTimes(this.fromFormControl.value);
        } else {
          this.timeDiffSec = diff;
        }
      }
      this.updateHostFieldValue();
    }
  }

  onFromSelectChange(event: MatAutocompleteSelectedEvent) {
    this.updateFields(event.option.value);
    this.updateHostFieldValue();
  }

  onToSelectChange() {
    this.timeDiffSec = this.calculateTimeDifference(this.fromFormControl.value, this.toFormControl.value);
    this.updateHostFieldValue();
  }

  onTimeInputFocus(event: MouseEvent) {
    (event.target as HTMLInputElement).select();
  }

  updateFields(newFromValue: string) {
    const newTo = this.addToTime(newFromValue, this.timeDiffSec);
    this.timeDiffSec = this.calculateTimeDifference(newFromValue, newTo);
    this.toFormControl.setValue(newTo);
    this.updateToTimes(newFromValue);
  }

  testTimeString(time: string): boolean {
    if (!time) {
      return false;
    }
    if (this.autoOptions.displaySeconds!) {
      if (time.length != 8) {
        return false;
      }
      const reg = new RegExp('(?:[01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]');
      return reg.test(time);
    } else {
      if (time.length != 5) {
        return false;
      }
      const reg = new RegExp('(?:[01][0-9]|2[0-3]):[0-5][0-9]');
      return reg.test(time);
    }
  }

  checkAndUpdateTimeDiffInput(input: string): boolean {
    let diffSec = 0;
    input = input.replace(',', '.');
    if (input.slice(-1) === 'm') {
      const t = input.slice(0, -1);
      try {
        const diffMin = this.autoOptions.displaySeconds ? parseFloat(t) : parseInt(t);
        diffSec = Math.floor(diffMin * 60);
      } catch {
        return false;
      }
    } else if (input.slice(-3) === 'min') {
      const t = input.slice(0, -3);
      try {
        const diffMin = this.autoOptions.displaySeconds ? parseFloat(t) : parseInt(t);
        diffSec = Math.floor(diffMin * 60);
      } catch {
        return false;
      }
    } else if (input.slice(-1) === 'h') {
      const t = input.slice(0, -1);
      try {
        const diffH = parseFloat(t);
        const diffMin = this.autoOptions.displaySeconds ? diffH * 60 : Math.floor(diffH * 60);
        diffSec = Math.floor(diffMin * 60);
      } catch {
        return false;
      }
    } else if (this.autoOptions.displaySeconds && input.slice(-1) === 's') {
      const t = input.slice(0, -1);
      try {
        diffSec = parseInt(t);
      } catch {
        return false;
      }
    }

    if (diffSec > 0) {
      this.timeDiffSec = diffSec;
      const newTo = this.addToTime(this.fromInput.nativeElement.value, diffSec);
      this.toFormControl.setValue(newTo);
      this.updateHostFieldValue();
      return true;
    }
    return false;
  }

  // calculates difference between two hh:mm format times in seconds
  calculateTimeDifference(time1: string, time2: string) {
    if (!(this.testTimeString(time1) && this.testTimeString(time2))) {
      return 0;
    }
    const t1 = time1.split(':');
    const t2 = time2.split(':');
    if (this.autoOptions.displaySeconds!) {
      return (
        parseInt(t2[0]) * 3600 + parseInt(t2[1]) * 60 + parseInt(t2[2]) - (parseInt(t1[0]) * 3600 + parseInt(t1[1]) * 60 + parseInt(t1[2]))
      );
    } else {
      return parseInt(t2[0]) * 3600 + parseInt(t2[1]) * 60 - (parseInt(t1[0]) * 3600 + parseInt(t1[1]) * 60);
    }
  }

  addToTime(time: string, seconds: number) {
    const t = time.split(':');
    const newTimeSec = parseInt(t[0]) * 3600 + parseInt(t[1]) * 60 + (this.autoOptions.displaySeconds! ? parseInt(t[2]) : 0) + seconds;
    const hh = Math.floor(newTimeSec / 3600);
    const mm = Math.floor(newTimeSec / 60) % 60;
    const ss = newTimeSec % 60;

    let timeString = `${this.formatTwoDigits(hh)}:${this.formatTwoDigits(mm)}`;
    if (this.autoOptions.displaySeconds!) {
      timeString += `:${this.formatTwoDigits(ss)}`;
    }
    if (hh < 0) {
      return this.autoOptions.displaySeconds! ? '00:00:00' : '00:00';
    } else if (hh >= 24) {
      return this.autoOptions.displaySeconds! ? '23:59:59' : '23:59';
    } else {
      return timeString;
    }
  }

  // updates autocomplete options for second autocomplete (with correct time differences and labels)
  updateToTimes(initialTime: string) {
    const newOptions: TimeModel[] = [];
    newOptions.push({ value: initialTime, label: initialTime + ' (0 min)' });
    if (this.autoOptions.customTimes) {
      for (let i = 0; i < this.autoOptions.customTimes.length; i++) {
        const t = this.addToTime(initialTime, this.autoOptions.customTimes[i] * 60);
        const l =
          this.autoOptions.customTimes[i] < 60
            ? this.autoOptions.customTimes[i] + ' min'
            : (this.autoOptions.customTimes[i] / 60).toFixed(1) + ' h';
        newOptions.push({ value: t, label: `${t} (${l})` });
      }
    }
    let t = newOptions[newOptions.length - 1].value;
    let timeDiff = this.autoOptions.customTimes ? this.autoOptions.customTimes[this.autoOptions.customTimes.length - 1] : 0;
    const n = Math.floor(
      this.calculateTimeDifference(t, this.autoOptions.displaySeconds! ? '23:59:59' : '23:59') / 60 / this.autoOptions.interval,
    );
    for (let i = 0; i < n; i++) {
      t = this.addToTime(t, this.autoOptions.interval * 60);
      timeDiff += this.autoOptions.interval;
      const l = timeDiff < 60 ? timeDiff + ' min' : (timeDiff / 60).toFixed(1) + ' h';
      newOptions.push({ value: t, label: `${t} (${l})` });
    }
    this.toOptions = newOptions;
  }
}
