import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { gql } from '@apollo/client/core';
import { RcgFieldType, RcgFormlyFieldProps, SelectOption } from '@rcg/core';
import { GraphqlClientService } from '@rcg/graphql';
import { Subscription } from 'rxjs';
import { getFieldValueFromPath } from '../../utils/field-path-utils';

interface StatusFieldSettings {
  insertInitalMatrixId?: number | null;
  matrixIdPath?: string | null;
  matrixLabelPath?: string | null;
  query?: string;
  setType?: string;
  setStatusId?: string;
}

@Component({
  selector: 'rcg-status-field',
  templateUrl: './status-field.component.html',
  styleUrls: ['./status-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusFieldComponent
  extends RcgFieldType<unknown, RcgFormlyFieldProps<Record<string, unknown>, StatusFieldSettings>>
  implements OnInit, OnDestroy
{
  selectOptions: SelectOption[] = [];
  selectOptionsSubscription?: Subscription;
  error: string | null = null;
  matrixId!: number;
  matrixLabel: string | null = null;
  insertInitalMatrixId: number | undefined | null;

  constructor(private graphQlClient: GraphqlClientService, private changeRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.insertInitalMatrixId = this.props?.['settings']?.['insertInitalMatrixId'] ?? null;

    this.matrixId = getFieldValueFromPath(this.model, this.props?.['settings']?.['matrixIdPath']);

    if (this.props.readonly === true || this.props.disabled === true) {
      this.formCtrl.disable();

      this.matrixLabel = getFieldValueFromPath(this.model, this.props?.['settings']?.['matrixLabelPath']);

      // try set initial value and options from settings paths without getting data from server
      if (this.matrixId && this.matrixLabel) {
        const selectedValue: SelectOption = {
          value: this.matrixId,
          label: this.matrixLabel,
        };
        this.selectOptions = [selectedValue];
        this.value = this.matrixId;
        this.changeRef.markForCheck();
        return;
      }
    }

    if (!this.matrixId) {
      this.error = 'Napaka pridobivanja statusov. Status definition matrix id je null';
      this.changeRef.markForCheck();
      return;
    }

    this.loadSelectOptions(this.matrixId);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.selectOptionsSubscription?.unsubscribe();
  }

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  onChange(matSelect: MatSelectChange) {
    const value = matSelect.value;
    this.error = null;
    const seletOption = this.selectOptions.find((o) => o.value === value);
    if (!value) {
      this.setError('Selected option not exist');
      this.setValue(null);
      return;
    }

    this.setValue(seletOption?.value as number);
  }

  private loadSelectOptions(matrixId: number): void {
    try {
      const gqlQuery = this.props?.['settings']?.query;
      if (!gqlQuery) {
        this.error = 'Napaka pridobivanja statusov. Graphql query za statuse ni nastavljen v settings-ih';
        return;
      }

      this.selectOptionsSubscription = this.graphQlClient
        .query<{ data?: SelectOption[] }>({
          query: gql(gqlQuery),
          variables: { id: matrixId },
        })
        .subscribe((result) => {
          try {
            const data = result?.data ?? [];
            if (!data.length) {
              throw new Error(`Ni podatkov`);
            }
            this.selectOptions = data;
            if (
              this.model?.id === undefined && // insert mode
              this.insertInitalMatrixId &&
              typeof this.insertInitalMatrixId === 'number' &&
              this.selectOptions.findIndex((d) => d.value === this.insertInitalMatrixId) > -1
            ) {
              // set initial matrix id from settings if exists - for insert mode only
              this.setValue(this.insertInitalMatrixId);
            } else {
              this.setValue(matrixId);
            }
            this.changeRef.markForCheck();
          } catch (error) {
            this.setError(error);
          }
        });
    } catch (error) {
      this.setError(error);
    }
  }

  private setError(error: unknown) {
    this.selectOptions = [];
    this.error = (error as Error | undefined)?.message ?? error?.toString() ?? 'Statusi -  Neznana napaka';
    this.changeRef.markForCheck();
  }

  private setValue(matrixId: number | null | undefined) {
    this.value = matrixId ?? null;

    // status id
    const setStatusId = this.props?.['settings']?.setStatusId;
    if (setStatusId && typeof setStatusId === 'string') {
      const statusId = (this.selectOptions.find((d) => d.value == matrixId) as Record<string, unknown> | undefined)?.status_id;
      const statusIdField = this.form.get(setStatusId);
      if (!statusIdField) {
        console.warn('Status field setStatusId could not find field', setStatusId);
      }
      statusIdField?.setValue(statusId);
    }

    // type id
    const setType = this.props?.['settings']?.setType;
    if (setType && typeof setType === 'string') {
      const typeId = this.selectOptions.find((d) => d.value == matrixId)?.type_id;

      const typeField = this.form.get(setType);
      if (!typeField) {
        console.warn('Status field setType could not find field', setType);
      }

      typeField?.setValue(typeId);
    }
  }
}
