import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RcgFieldType } from '@rcg/core';
import moment from 'moment-timezone';
import 'moment/locale/sl';

@Component({
  selector: 'rcg-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'sl-SL' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DatePickerFieldComponent extends RcgFieldType implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    try {
      if (
        this.formState.query_variable &&
        !this.model?.[this.formState.query_variable] &&
        this.field?.props?.defaultDate &&
        typeof this.field.props.defaultDate === 'function'
      ) {
        const defaultDt = this.field.props.defaultDate();
        if (moment(defaultDt).isValid()) {
          this.value = moment(defaultDt).startOf('day').toDate();
        } else {
          console.error(`Invalid default date for ${this.field.key}`);
        }
        return;
      }

      if (this.value && typeof this.value === 'string') {
        this.value = new Date(this.value);
      }
    } catch (error) {
      console.error(`Datepicker - ${this.field.key} - init error`, error);
    }
  }

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  onDdateChange(_: string, event: MatDatepickerInputEvent<Date>) {
    const date: Date | moment.Moment | null = event.value;
    const dt = date && moment.isMoment(date) ? date.toDate() : date;
    this.value = dt;
  }
}
