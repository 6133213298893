import { RcgFieldType } from '@rcg/core';
import * as dot from 'dot-object';
import { Observable, Subscription, combineLatest, startWith } from 'rxjs';
import { ACSettings, AcTreeSettings } from '../models/ac-settings';

export class AcDependentFieldsHelper {
  acSettings!: ACSettings | AcTreeSettings;

  private clearOnDepentFieldsChangSubs?: Subscription;

  private clearOnDependentFieldsChangeInitialized = false;

  get IsclearOndepFieldsSet() {
    return (
      this.acSettings.clearOnDependentFieldsChange &&
      Array.isArray(this.acSettings.clearOnDependentFieldsChange) &&
      this.acSettings.clearOnDependentFieldsChange.length > 0
    );
  }

  constructor(acSettings: ACSettings | AcTreeSettings) {
    this.acSettings = acSettings;
  }

  subscribeToClearOnDependentFieldsChange(form: RcgFieldType['form'], fieldKey: string, onClearCallback: (clearAcField: boolean) => void) {
    const changes: Observable<{ value?: unknown }>[] = [];

    for (const c of this.acSettings.clearOnDependentFieldsChange!) {
      const formCtrl = form.get(c.split('.')[0]);
      if (formCtrl) {
        changes.push(formCtrl!.valueChanges.pipe(startWith(formCtrl.value)));
      }
    }

    if (changes.length === 0) return;

    this.clearOnDepentFieldsChangSubs?.unsubscribe();
    this.clearOnDepentFieldsChangSubs = combineLatest([...changes])
      .pipe()
      .subscribe({
        next: (data) => {
          if (!this.clearOnDependentFieldsChangeInitialized) {
            // on first change do nothing or it will clear dependent fields values, each dynamic created listener has startsWith
            this.clearOnDependentFieldsChangeInitialized = true;
            return;
          }
          if (data && data.length > 0) {
            const hasEmptyValue = !!data.find((data) => !data?.value); // support only for selectOptions fields that has selectOption value
            if (!hasEmptyValue && !form.get(fieldKey)?.value) {
              onClearCallback(true);
            } else {
              onClearCallback(false);
            }
          }
        },
      });
  }

  allowSearch(model: Record<string, unknown>): boolean {
    let allowSearch = true;
    for (const c of this.acSettings.clearOnDependentFieldsChange!) {
      if (
        this.acSettings.allowSearchOnEmptyDependentFields &&
        this.acSettings.allowSearchOnEmptyDependentFields.findIndex((v) => v === c) !== -1
      ) {
        continue;
      }

      if (!dot.pick(c, model)) {
        allowSearch = false;
        break;
      }
    }

    return allowSearch;
  }

  unsubscribe() {
    this.clearOnDepentFieldsChangSubs?.unsubscribe();
  }
}
